import React, { ChangeEvent, useCallback, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { capitalizeName } from "utils/capitalize";
import { WorkSchedule, WeekDaysSpanish, WorkDay } from "@mapsy/shared";
import { FormInput, ProjectedProps } from "interfaces";
import _ from "lodash";
import moment from "moment";
import { defaultWorkSchedule } from "constants/defaultUserValues";
import { theme } from "theme";

export const WorkScheduleInput: React.FC<ProjectedProps<WorkSchedule>> = ({
  handleChange,
  entity,
}) => {
  const { WorkScheduleForm } = useGetStartedForms();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [schedule, setSchedule] = useState<WorkSchedule>(
    entity || defaultWorkSchedule
  );

  const debounceHandleChange = useCallback(_.debounce(handleChange, 250), []);

  const propertyChanged = (
    day: string,
    propertyName: string,
    value: any,
    c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const subEnt = { [propertyName]: value };

    if (["endTime", "startTime"].includes(propertyName)) {
      subEnt[propertyName] = moment(value, "HH").local().format();
    }

    setSchedule((prev_entity: WorkSchedule) => {
      const updatedSchedule = {
        ...prev_entity,
        [day]: {
          ...prev_entity[day as keyof WorkSchedule],
          ...subEnt,
        },
      };
      // debounced function to prevent excess of re rendering
      debounceHandleChange("workSchedule", updatedSchedule);
      return updatedSchedule;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          color: COLORS.BLUE_1,
          textAlign: "center",
          mb: 2,
        }}
      >
        Horarios de trabajo
      </Typography>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          justifyItems: "center",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              md: "repeat(3, 190px)",
              xs: "70px 100px 100px",
            },
            gap: 4,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              gridColumn: "2 / span 1",
            }}
          >
            De:
          </Typography>
          <Typography
            variant="body2"
            sx={{
              gridColumn: "3 / span 1",
            }}
          >
            A:
          </Typography>
        </Box>
        {Object.entries(WeekDaysSpanish).map(([day, value]) => (
          <Box
            key={day}
            sx={{
              position: "relative",
              display: "grid",
              gridTemplateColumns: {
                md: "repeat(3, 190px)",
                xs: "70px 100px 100px",
              },
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {isMobile ? (
                <Typography variant="body2">
                  {capitalizeName(value.slice(0, 2))}
                </Typography>
              ) : (
                <Typography variant="body2">{capitalizeName(value)}</Typography>
              )}
              <InputField
                backgroundMode="transparent"
                handleChange={(...props) => propertyChanged(day, ...props)}
                value={
                  (schedule[day as keyof WorkSchedule].workable as boolean) ||
                  false
                }
                {...WorkScheduleForm[0]}
              />
            </Box>

            {WorkScheduleForm.slice(1).map(
              ({ propertyName, ...input }: FormInput, i: number) => {
                const daySchedule: WorkDay =
                  schedule[day as keyof WorkSchedule];

                const value = moment(
                  daySchedule[propertyName as keyof WorkDay] as string
                ).get("hour");

                return (
                  <InputField
                    disabled={!daySchedule.workable}
                    key={`${propertyName + i}-${day}`}
                    backgroundMode="transparent"
                    handleChange={(...props) => propertyChanged(day, ...props)}
                    name={`${day}-${propertyName}`}
                    propertyName={propertyName}
                    value={value || ""}
                    {...input}
                  />
                );
              }
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
