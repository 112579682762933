import { Box, CircularProgress, Container, Typography } from "@mui/material";

import COLORS from "../constants/colors";
import { SuggestedPsychologistsProps } from "interfaces";
import { Link } from "react-router-dom";

export const TherapistSearchLayout = ({
  title,
  subtitle,
  loading,
  children,
}: SuggestedPsychologistsProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        paddingY: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: "1rem",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: subtitle ? "initial" : COLORS.BLUE_1 }}
        >
          {title}
          {subtitle && (
            <span
              style={{
                color: COLORS.BLUE_1,
              }}
            >
              /{subtitle}
            </span>
          )}
        </Typography>
        {children}
        {loading && <CircularProgress sx={{ margin: "auto" }} />}
      </Container>
    </Box>
  );
};
