import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Container, Typography } from "@mui/material";

import { Meet } from "../components/organisms/Meet";
import { BannerGrid } from "components/atoms/BannerGrid";
import COLORS from "constants/colors";

export const Join = () => {
  return (
    <Box>
      <Meet
        title="¡Únete a nuestra comunidad!"
        button={{ label: "Registrarme", href: "/users/signup?type=therapist" }}
        paragraphs={[
          {
            children:
              "Conecta con pacientes, amplía tu práctica y simplifica la gestión de citas.",
            sx: {},
          },
        ]}
      />
      <Box
        component="section"
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.2)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          height: "440px",
          textAlign: "center",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            mt: 12,
            mb: 12,
          }}
        >
          <Grid2>
            <Box>
              <Typography variant="h4">
                ¿Cómo usar Mapsy como terapeuta?
              </Typography>
            </Box>
            <BannerGrid
              items={[
                {
                  subtitle: "Regístrate",
                },
                { subtitle: "Revisa tu calendario de citas" },
                {
                  subtitle: "Ponte en contacto con tus usuarios",
                },
              ]}
              boxStyle={{
                backgroundColor: COLORS.BLUE_2,
                p: 2,
                color: "white",
                borderRadius: "30px",
                height: "151px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
              }}
              itemBottomMargin={4}
              showItemNumber
              sx={{ mt: 6 }}
            />
          </Grid2>
        </Container>
      </Box>
    </Box>
  );
};
