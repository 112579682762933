import { ReactNode, useEffect, useMemo } from "react";
import { debounce } from "utils/debounce";

export interface Props {
  children: ReactNode;
  stateToObserve?: any;
  helperToSubmitOnChange: (...args: any) => Promise<any>;
  submitParams: any;
  delay?: number;
  hasChanged?: boolean;
}

const SUBMIT_DEBOUNCE_DELAY = 3000;

/**
 * HOC (provider) that executes a debounced promise after the observed state changes. Debounce interval is 3000ms by default.
 *
 * It can be used to autosaved a form when values changes as long as they are stored in a state.
 * @returns The react node passed as children.
 */
export const SubmitOnChangeProvider: React.FC<Props> = ({
  children,
  stateToObserve,
  helperToSubmitOnChange,
  submitParams,
  delay = SUBMIT_DEBOUNCE_DELAY,
}) => {
  const debounceSubmit = useMemo(
    () => debounce(delay, helperToSubmitOnChange),
    [helperToSubmitOnChange]
  );

  useEffect(() => {
    if (stateToObserve === undefined) {
      return;
    }
    let timerId = debounceSubmit(submitParams);

    return () => clearTimeout(timerId);
  }, [stateToObserve, submitParams, debounceSubmit]);

  return <>{children}</>;
};
