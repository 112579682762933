import { StylesEnum } from '@mapsy/shared'
import { Box, Typography } from '@mui/material'
import { CustomButton } from 'components/atoms/Button'
import COLORS from 'constants/colors'
import { GetStartedProps } from 'interfaces'
import { Link } from 'react-router-dom'

export const PreTherapists: React.FC<GetStartedProps> = ({
  changePage,
  disableSubmit
}) => {
  return (<Box sx={{
    height: "400px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    maxWidth: "680px"
  }}>
    <Typography sx={{ color: COLORS.BLUE_1 }}>
      Para continuar, necesitamos algunos detalles sobre tu práctica profesional.<br/>
      Si deseas agregar o modificar tu información, ¡no te preocupes!<br/>
      Podrás agregar esta información fácilmente en la configuración de tu perfil una 
      vez que hayas completado tu registro inicial.
    </Typography>
    <CustomButton
      customStyle={StylesEnum.primary}
      children={"Continuar"}
      disableRipple={true}
      disabled={disableSubmit}
      sx={{
        borderRadius: "14px",
        maxWidth: "207px",
        alignSelf: "center",
        marginTop: 8
      }}
      onClick={() => changePage({}, false)}
    />
    <Link to="/therapist/my_profile" style={{
      color: "rgb(0, 0, 0, 0.5)",
      marginTop: "1rem"
    }} >Omitir</Link>
  </Box>)
}