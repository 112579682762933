import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { selectReasonState } from "../features/reason/reasonSice";
import { useAppSelector } from "../hooks";
import { TherapistsList } from "../components/organisms/TherapistsList";
import { TherapistSearchLayout } from "../layouts/TherapistSearchLayout";
import { LoadingEnum } from "@mapsy/shared";

export interface SuggestedPsychologistsPageProps {
  showAllTherapists?: boolean;
}
const SuggestedPsychologists: React.FC<SuggestedPsychologistsPageProps> = ({
  showAllTherapists,
}) => {
  const nav = useNavigate();
  const { consultingActions, therapistsLoading } =
    useAppSelector(selectReasonState);

  useEffect(() => {
    const { predictedTopicId } = consultingActions;

    if (!predictedTopicId && !showAllTherapists) {
      nav("/reason");
    }
  }, [consultingActions, showAllTherapists]);

  return (
    <TherapistSearchLayout
      title="Psicólogos"
      loading={therapistsLoading === LoadingEnum.pending}
    >
      <TherapistsList showAllTherapists={showAllTherapists} />
    </TherapistSearchLayout>
  );
};

export default SuggestedPsychologists;
