import { APINames } from "@mapsy/shared";
import BaseAPI from "./BaseEndpointAPI";

class EmailAPI extends BaseAPI {
  constructor() {
    super(APINames.EMAIL);
  }

  suggestion(params: { email: string; name: string; phoneNumber: string }) {
    const queryString = new URLSearchParams(params).toString();
    return `${this.baseURL}/suggestion?${queryString}`;
  }
}

const api = new EmailAPI();

export default api;
