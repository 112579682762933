import { useCallback } from "react";
import { useSelector } from "react-redux";

import { useAxios } from "./useAxios";
import endpointGenerator from "classes/endpointGenerator";
import { selectSessionState } from "features/session/session.slice";
import { CreateAnalytics } from "interfaces/analytics.interface";
import { Navigator } from "interfaces/networkInformation.interface";

export const useAnalytics = () => {
  const { postData } = useAxios();
  const { profileInfo, sourceInfo } = useSelector(selectSessionState);

  const createAnalytic = useCallback(
    ({ action, componentName, data, user = profileInfo }: CreateAnalytics) => {
      const endpoint = endpointGenerator.AnalyticsAPI.baseURL;
      const { userAgent, language, platform } = navigator;
      const { connection } = navigator as Navigator;

      const source = {
        ...sourceInfo,
        userAgent,
        language,
        platform,
        size: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        connection: connection?.effectiveType,
      };

      postData(endpoint, {
        action,
        componentName,
        data,
        source,
        user,
      });
    },
    [profileInfo, sourceInfo]
  );

  return {
    createAnalytic,
  };
};
