export enum AppointmentStatus {
  Pending,
  Confirmed,
  Done,
  Canceled,
}

export enum AppointmentStatusSpanish {
  Pendiente,
  Confirmada,
  Realizada,
  Cancelada,
}
