import React from "react";
import { IconsProps } from "interfaces";

export const ImageIcon: React.FC<IconsProps> = ({
  width = 82,
  height = 82,
  fillColor = "#02587B",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8398 24.6943H14.8154C15.3394 24.6943 15.8419 24.4862 16.2124 24.1157C16.5828 23.7452 16.791 23.2427 16.791 22.7187C16.791 22.1948 16.5828 21.6923 16.2124 21.3218C15.8419 20.9513 15.3394 20.7432 14.8154 20.7432H12.8398V26.6699" stroke="black" strokeWidth="1.58045" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.88864 20.7432V24.6943C8.88864 25.2182 8.6805 25.7207 8.31001 26.0912C7.93952 26.4617 7.43702 26.6699 6.91307 26.6699C6.38912 26.6699 5.88662 26.4617 5.51613 26.0912C5.14564 25.7207 4.9375 25.2182 4.9375 24.6943" stroke="black" strokeWidth="1.58045" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.2131 23.9535H24.6948V25.4104C24.4601 25.7903 24.1335 26.1049 23.745 26.3251C23.3565 26.5452 22.9188 26.6638 22.4723 26.6699C20.9659 26.6699 19.7559 25.3487 19.7559 23.7065C19.7559 22.0643 20.9659 20.7432 22.4723 20.7432C23.0048 20.7422 23.5237 20.9109 23.9539 21.2247" stroke="black" strokeWidth="1.58045" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.92578 15.8046V4.93895C5.92578 4.67698 6.02985 4.42573 6.2151 4.24049C6.40034 4.05524 6.65159 3.95117 6.91357 3.95117H18.767L25.6815 10.8656V15.8046" stroke="black" strokeWidth="1.58045" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.7676 3.95117V10.8656H25.6821" stroke="black" strokeWidth="1.58045" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};



