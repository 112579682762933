import { APINames } from "@mapsy/shared";
import BaseAPI from "./BaseEndpointAPI";
import { GetCommentsByTherapistsIdQuery } from "@mapsy/shared";

class CommentAPI extends BaseAPI {
  constructor() {
    super(APINames.COMMENT);
  }

  byTherapist(therapistId: string, query: GetCommentsByTherapistsIdQuery) {
    const queryString = new URLSearchParams(query as any).toString();
    return `${this.baseURL}/therapist/${therapistId}?${queryString}`;
  }
}

const api = new CommentAPI();

export default api;
