import { TextareaAutosize, TextareaAutosizeProps } from "@mui/material";
import React from "react";
import COLORS from "../../constants/colors";

interface Props extends TextareaAutosizeProps {}
export const CustomTextarea: React.FC<Props> = ({ style, ...rest }) => {
  return (
    <TextareaAutosize
      style={{
        backgroundColor: COLORS.WHITE,
        borderWidth: "1px",
        borderColor: COLORS.BLUE_1,
        borderRadius: "20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontFamily: "Poppins",
        fontSize: "1.25rem",
        color: "rgba(0, 0, 0, 0.5)",
        width: "calc(100% - 2rem)",
        maxWidth: "md",
        padding: "1rem",
        resize: "none",
        ...style,
      }}
      minRows={4}
      {...rest}
    />
  );
};
