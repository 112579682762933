import moment from "moment";
import { LoggerLevelColorEnum, LoggerLevelEnum } from "@mapsy/shared";
import { env } from "../env";

class CustomLogger {
  timestampFormat: string;
  constructor(timestampFormat?: string) {
    this.timestampFormat = timestampFormat || "DD-MM-YYYY HH:mm:ssZ";
    console.log(`Logger status: ${env.REACT_APP_LOGGER}`);
  }

  log(message: string, ...params: any[]) {
    if (env.REACT_APP_LOGGER === "all" || env.REACT_APP_LOGGER === "info")
      this.print(message, LoggerLevelEnum.info, params);
  }

  warn(message: string, ...params: any[]) {
    if (env.REACT_APP_LOGGER === "all" || env.REACT_APP_LOGGER === "error")
      this.print(message, LoggerLevelEnum.warn, params);
  }

  error(message: string, ...params: any[]) {
    if (env.REACT_APP_LOGGER === "all" || env.REACT_APP_LOGGER === "warn")
      this.print(message, LoggerLevelEnum.error, params);
  }

  private print(message: string, level: LoggerLevelEnum, params: any[]) {
    if (!message) {
      message = "Logger has been called without any message";
    }

    console.log(
      `%c[${moment().format(this.timestampFormat)}] %c[${LoggerLevelEnum[
        level
      ].toLocaleUpperCase()}]: %c${message} ${
        params.length > 0 ? this.parseParamsToJSON(params) : ""
      }`,
      "color: green",
      `color: ${LoggerLevelColorEnum[level]}`,
      "color: turquoise"
    );
  }

  private parseParamsToJSON(params: any[]) {
    try {
      return JSON.stringify(params);
    } catch (e) {
      return `${e} parsing ${params}`;
    }
  }
}

const logger = new CustomLogger();
export default logger;
