import React, { ChangeEvent, useCallback, useState } from "react";
import _ from "lodash";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { ProvidedService, ProvidedServiceTypes } from "@mapsy/shared";
import { ProjectedProps, FormInput } from "interfaces";

const defaultEntry: Partial<ProvidedService> = {
  serviceType: ProvidedServiceTypes.Individual,
  price: 500,
  currency: "MXN",
};
export const ProvidedServicesInput: React.FC<
  ProjectedProps<ProvidedService[]>
> = ({ handleChange, entity, inputs }) => {
  const { ServicesForm } = useGetStartedForms(); //el ServicesForm se podría omitir porque ya viene en los props del ProvidedServicesInput como inputs. Lo mismo para el resto de componentes del signup
  const [services, setServices] = useState<Partial<ProvidedService>[]>(
    entity?.length ? entity : [{ ...defaultEntry }]
  );
  const [focusedElement, setFocusedElement] = useState("");
  const debouncedChange = useCallback(_.debounce(handleChange, 250), [
    handleChange,
  ]);
  const propertyChanged = useCallback(
    (
      index: number,
      propertyName: string,
      value: any,
      c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setFocusedElement(c.target.name);
      setServices((_exp: Partial<ProvidedService>[]) => {
        const newExp = [..._exp];
        newExp[index] = {
          ..._.omit(newExp[index], "_id"),
          [propertyName]: value,
        };

        debouncedChange("providedServices", newExp);
        return newExp;
      });
    },
    [debouncedChange]
  );

  const handleAddRemove = useCallback(
    (index: number) => {
      setServices((prev: Partial<ProvidedService>[]) => {
        const newEntry: Partial<ProvidedService> = {
          ...{ ...defaultEntry },
        };

        let result;
        if (index === 0) {
          result = [...prev, newEntry];
        } else {
          result = prev.slice(); // Create a shallow copy of the array
          result.splice(index, 1); // Remove the element at index i
        }

        debouncedChange("providedServices", result);
        return result;
      });
    },
    [debouncedChange]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          color: COLORS.BLUE_1,
          textAlign: "left",
          mb: 2,
        }}
      >
        Especifica los servicios que brindas y el costo
      </Typography>
      {services.map((srv: Partial<ProvidedService>, i: number) => {
        return (
          <Box
            key={`service-grade-${i}`}
            sx={{
              position: "relative",
              display: "grid",
              gridTemplateColumns: "1fr 1fr auto",
              alignItems: "center",
              gap: { md: 4, xs: 1 },
            }}
          >
            {ServicesForm.map((props: FormInput) => (
              <InputField
                key={`service-grade-${i}-${props.propertyName}-${(srv[props.propertyName as keyof ProvidedService] as string) || ""}`}
                backgroundMode="transparent"
                autoFocus={focusedElement === `${i}.${props.propertyName}`}
                name={`${i}.${props.propertyName}`}
                value={
                  (srv[
                    props.propertyName as keyof ProvidedService
                  ] as string) ?? ""
                }
                handleChange={(...props) => propertyChanged(i, ...props)}
                {...props}
              />
            ))}

            <IconButton
              disableRipple
              aria-label="add"
              size="large"
              sx={{
                width: "35px",
                height: "35px",
                backgroundColor: i === 0 ? COLORS.BLUE_1 : COLORS.GREY,
                color: "white",
              }}
              onClick={() => handleAddRemove(i)}
            >
              {i === 0 ? <AddIcon /> : <RemoveIcon />}
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};
