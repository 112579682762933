import { WorkDay } from ".";
import { Modality } from "../enum";
import { Currency, Theories } from "../enum";
import {
  AccountStatus,
  CommonUserInfo,
  ImageURL,
  Timestamps,
  UpdateUser,
} from "./shared.inteface";

export type Therapist = {
  _id: string;
  stars: 0 | 1 | 2 | 3 | 4 | 5;
} & Omit<
  CreateTherapist,
  "locations" | "schoolGrades" | "professionalExperience" | "accountStatus"
> &
  Partial<Pick<CreateTherapist, "password">> &
  Partial<Pick<CreateTherapist, "phone">> & {
    locations: Location[];
    schoolGrades: SchoolGrade[];
    professionalExperience: ProfessionalExperience[];
    accountStatus: AccountStatus;
  } & Timestamps &
  ImageURL;

export interface SchoolGrade {
  level?: SchoolGradeLevel;
  institution: string;
  year: number | string;
  major: string;
}

export interface ProfessionalExperience {
  institution: string;
  startYear: number;
  endYear: number;
  position: string;
}

export enum SchoolGradeLevel {
  Lic = "Lic",
  Master = "Master",
  Phd = "Phd",
  PosPhd = "PosPhd",
  Specialist = "Specialist",
  Certification = "Certification",
}

export enum ProvidedServiceTypes {
  Individual,
  Couple,
  Family,
  Groupal,
}

export enum ProvidedServiceTypesSpanish {
  "Individual",
  "De Pareja",
  "Familiar",
  "Grupal",
}

export interface ProvidedService {
  _id: string;
  serviceType: ProvidedServiceTypes;
  price: number;
  currency: keyof typeof Currency;
}

export interface Address {
  street: string;
  externalNo: string;
  internalNo?: string;
  cp: string;
  community: string;
  city: string;
  state: string;
  country: string;
}
export interface Location extends Address {
  _id: string;
  name: string;
  workSchedule: WorkSchedule;
  providedServices: ProvidedService[];
  modality: Modality;
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type DefaultLocation = DeepPartial<Location>

export interface WorkSchedule {
  monday: WorkDay;
  tuesday: WorkDay;
  wednesday: WorkDay;
  thursday: WorkDay;
  friday: WorkDay;
  saturday: WorkDay;
  sunday: WorkDay;
}

export type CreateTherapist = CommonUserInfo & {
  professionalId: string;
  theory: Theories;
  credentials: string;
  verifiedPassword?: string;
  topicsId: string[];
  locations: DefaultLocation[];
  schoolGrades: Partial<SchoolGrade>[];
  professionalExperience: Partial<ProfessionalExperience>[];
  accountStatus?: Partial<AccountStatus>;
};

export interface UpdateTherapist extends UpdateUser<Therapist> { }

export enum WeekDaysSpanish {
  monday = "lunes",
  tuesday = "martes",
  wednesday = "miércoles",
  thursday = "jueves",
  friday = "viernes",
  saturday = "sábado",
  sunday = "domingo",
}
