import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  selectSessionState,
  getProfileInfo,
  readLocalToken,
  startSession,
  deleteSession,
} from "features/session/session.slice";
import { useAppDispatch } from "hooks";
import { useAuth } from "hooks/useAuthAPI";

interface Props {
  children: ReactNode;
}

export const SessionProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { getLogout } = useAuth();
  const { token, profileInfo, hasTokenExpired, isLoggedIn } =
    useSelector(selectSessionState);

  useEffect(() => {
    if (!token) {
      dispatch(readLocalToken());
      return;
    }

    if (token && !profileInfo) {
      dispatch(startSession({ access_token: token }));
      dispatch(getProfileInfo(token));
    }
  }, [token, profileInfo]);

  useEffect(() => {
    if (hasTokenExpired) {
      dispatch(deleteSession());
      getLogout();
    }
  }, [hasTokenExpired]);

  useEffect(() => {
    if (!isLoggedIn || !profileInfo?.id) {
      return;
    }
  }, [isLoggedIn, profileInfo]);

  return <>{children}</>;
};
