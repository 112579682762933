import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { Location, ModalitySpanish, ProvidedServiceTypesSpanish } from "@mapsy/shared";
import COLORS from "constants/colors";

interface TabPanelProps {
  children?: ReactNode;
  location: Location;
  index: number;
  value: number;
}

export const TherapistLocationTab: React.FC<TabPanelProps> = ({
  children,
  location,
  index,
  value,
}) => {
  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Box key={`Box-loc-${location._id}`}>
        <Box component={"span"} sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}>
          Consulta{" "}
          {location.modality !== undefined
            ? ModalitySpanish[location.modality]
            : "No configurada"}
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{ color: COLORS.BLUE_1, fontWeight: 500, fontSize: "1rem" }}
          >
            Servicios en este consultorio:{" "}
          </Typography>
          {location.providedServices.map((service, idx) => (
            <Box
              key={`service-${idx}`}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "1rem", color: COLORS.BLUE_1 }}>
                Terapia {ProvidedServiceTypesSpanish[service.serviceType]}
              </Typography>
              <Typography sx={{ fontSize: "1rem" }}>
                ${service.price}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "1rem",
            }}
          >
            <Box
              component={"span"}
              sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
            >
              Ubicación:{" "}
            </Box>{" "}
            {location.community}, {location.city}, {location.state}
          </Typography>
        </Box>
      </Box>
      {children}
    </Box>
  );
};
