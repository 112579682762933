import { APINames, GetAllQuery, Therapist } from "@mapsy/shared";
import BaseAPI from "./BaseEndpointAPI";
import {
  GetAllTherapistByTopicParent,
  GetAllTherapistByTopicSiblings,
} from "features/reason/reasonSice";

class TherapistAPI extends BaseAPI {
  constructor() {
    super(APINames.THERAPIST);
  }

  changePasswordById(id: string) {
    return `${this.baseURL}/change-password/${id}`;
  }

  filtered(params: Record<string, any>) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}/filtered?${queryString}`;
  }

  parentTopic(params: GetAllTherapistByTopicParent) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}/parent-topic?${queryString}`;
  }

  topicSibligs({ params, topicId }: GetAllTherapistByTopicSiblings) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}/topic-siblings/${topicId}?${queryString}`;
  }

  topicSibligsFiltered({ params, topicId }: GetAllTherapistByTopicSiblings) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}/topic-siblings/filtered/${topicId}?${queryString}`;
  }
}

const api = new TherapistAPI();

export default api;
