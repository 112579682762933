import React, { ChangeEvent, useState } from "react";
import moment from "moment";
import _ from "lodash";

import countryInfo from "../../../data/countries_info.json";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { GetStartedProps } from "interfaces";
import { UserForm } from "../form/UserForm";
import { defaultCommonValues } from "constants/defaultUserValues";

export const PersonalInfo: React.FC<GetStartedProps> = ({
  userInfo,
  changePage,
  showBackButton = false,
}) => {
  const { Common } = useGetStartedForms();
  const [entity, setEntity] = useState({ ...defaultCommonValues, ...userInfo });
  
  const propertyChanged = (
    propertyName: string,
    value: any,
    _c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let _value = value;
    const _entity = { ...entity };

    if (propertyName === "birthDate") {
      _value = moment(value).valueOf();
    } else if (propertyName === "phone.dial_code") {
      const code = countryInfo.find(({ dial_code }) => dial_code === value);
      _.set(_entity, "phone.country", code?.name || "Mexico");
    } else if (typeof value === "string") {
      _value = value.trimEnd();
    }

    _.set(_entity, propertyName, _value);

    setEntity((prev_entity) => {
      return {
        ...prev_entity,
        ..._entity,
      };
    });
  };

  return (
    <UserForm
      handleSubmit={() => changePage(entity, false)}
      handleBack={() => changePage(entity, true)}
      inputs={Common}
      entity={entity}
      isLoading={false}
      onPropertyChanged={propertyChanged}
      showBackButton={showBackButton}
    />
  );
};
