import { APINames } from "@mapsy/shared";
import BaseAPI from "./BaseEndpointAPI";

class AnalyticsAPI extends BaseAPI {
  constructor() {
    super(APINames.ANALYTICS);
  }
}

const api = new AnalyticsAPI();

export default api;
