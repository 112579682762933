import React from "react";
import { IconsProps } from "interfaces";

export const SuccessIcon: React.FC<IconsProps> = ({
  width = 82,
  height = 82,
  fillColor = "#02587B",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1464_1767)">
        <path
          d="M41 0.5C18.6325 0.5 0.5 18.6325 0.5 41C0.5 63.3675 18.6325 81.5 41 81.5C63.3674 81.5 81.5 63.3675 81.5 41C81.5 18.6325 63.3674 0.5 41 0.5ZM41 9.30004C58.5074 9.30004 72.695 23.497 72.695 41C72.695 58.5031 58.5074 72.695 41 72.695C23.4925 72.695 9.30497 58.5031 9.30497 41C9.30504 23.497 23.4925 9.30004 41 9.30004ZM56.5187 21.7684L33.0453 45.2468L25.4318 37.6332L17.9616 45.0985L25.5752 52.712L33.0947 60.2316L40.56 52.7614L64.0383 29.288L56.5187 21.7684Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1464_1767">
          <rect
            width="81"
            height="81"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
