import { RootState } from "store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Location,
  PhoneNumber,
  ProfessionalExperience,
  SchoolGrade,
  Therapist,
} from "@mapsy/shared";
import {
  defaultSchoolGrade,
  defaultProfessionalExperience,
  MAX_TOPICS_ID,
  defaultLocation,
} from "constants/defaultUserValues";

export interface RegistrationState {
  therapist?: Therapist;
  currentStep: number;
}
export type ObjectProps = "phone";

export type NestedPropsInObject = keyof PhoneNumber;

export type ArrayProps =
  | "schoolGrades"
  | "professionalExperience"
  | "locations";

export type NestedPropsInArray =
  | keyof SchoolGrade
  | keyof ProfessionalExperience
  | keyof Location;

const initialState: RegistrationState = {
  currentStep: 0,
};

const therapistSlice = createSlice({
  name: "therapist",
  initialState,
  reducers: {
    setTherapist(state, action: PayloadAction<Therapist>) {
      state.therapist = action.payload as Therapist;
    },
    changeBasicInfo(
      state,
      action: PayloadAction<{ propertyName: keyof Therapist; value: any }>
    ) {
      const { propertyName, value } = action.payload;
      state.therapist = {
        ...state.therapist,
        [propertyName]: value,
      } as Therapist;
    },
    updateNestedProp(
      state,
      action: PayloadAction<{
        propertyName: ObjectProps;
        nestedProp: NestedPropsInObject;
        value: any;
      }>
    ) {
      const { propertyName, nestedProp, value } = action.payload;

      if (!state.therapist) {
        return;
      }

      let firstLevel = state.therapist[propertyName];
      if (firstLevel) {
        firstLevel[nestedProp] = value;
        return;
      }
      state.therapist[propertyName] = {
        country: "Mexico",
        dial_code: "+52",
        number: value,
      };
    },
    updatePropInNestedArray(
      state,
      action: PayloadAction<{
        propertyName: ArrayProps;
        index: number;
        nestedProp: NestedPropsInArray;
        value: any;
      }>
    ) {
      const { propertyName, index, nestedProp, value } = action.payload;

      if (!state.therapist) {
        return;
      }

      let firstLevel =
        state.therapist[propertyName as keyof typeof state.therapist];
      let secondLevel: Record<string, any> = firstLevel
        ? firstLevel[index as keyof typeof firstLevel]
        : {};
      if (!firstLevel) {
        return;
      }

      secondLevel[nestedProp] = value;
    },
    removeIndexFromPropArray(
      state,
      action: PayloadAction<{
        propertyName: keyof Omit<Therapist, "phone">;
        index: number;
      }>
    ) {
      const { propertyName, index } = action.payload;

      let firstLevel = state.therapist?.[propertyName];

      if (!firstLevel || !Array.isArray(firstLevel)) {
        return;
      }

      const newArray = firstLevel.splice(index, 1);

      firstLevel = newArray;
    },
    pushNewSchoolGrade(state) {
      if (!state.therapist) {
        return;
      }
      state.therapist.schoolGrades.push(defaultSchoolGrade);
    },
    pushNewProfessionalExperience(state) {
      if (!state.therapist) {
        return;
      }
      state.therapist.professionalExperience.push(
        defaultProfessionalExperience
      );
    },
    updateTopics(state, action: PayloadAction<{ topicsId: string[] }>) {
      const { topicsId } = action.payload;
      if (topicsId.length > MAX_TOPICS_ID || !state.therapist) {
        return;
      }
      state.therapist.topicsId = topicsId;
    },
    addNewLocation(state) {
      if (!state.therapist) {
        return;
      }
      const length = state.therapist.locations.length;

      const location = defaultLocation(length + 1) as Location;

      state.therapist.locations.push(location);
    },
    removeLocation(state, action: PayloadAction<{ index: number }>) {
      if (!state.therapist) {
        return;
      }
      const { index } = action.payload;

      state.therapist.locations.splice(index, 1);
    },
    addBasicInfo(state, action) {
      state.therapist = {
        ...state.therapist,
        ...action.payload,
      };
    },
    addEducation(state, action) {
      state.therapist = {
        ...state.therapist,
        ...action.payload,
      };
    },
    addProfessionalExperience(state, action) {
      state.therapist = {
        ...state.therapist,
        ...action.payload,
      };
    },
    addLocations(state, action) {
      state.therapist = {
        ...state.therapist,
        ...action.payload,
      };
    },
    nextStep(state) {
      state.currentStep += 1; // Move to the next step in the sign-up process
    },
    previousStep(state) {
      if (state.currentStep > 0) state.currentStep -= 1; // Move back to the previous step
    },
    resetSignUp(state) {
      return initialState; // Reset the sign-up state
    },
  },
});

export const {
  addEducation,
  addProfessionalExperience,
  nextStep,
  previousStep,
  resetSignUp,
  setTherapist,
  changeBasicInfo,
  updatePropInNestedArray,
  updateNestedProp,
  removeIndexFromPropArray,
  pushNewSchoolGrade,
  pushNewProfessionalExperience,
  updateTopics,
  addNewLocation,
  removeLocation,
} = therapistSlice.actions;

export const selectTherapistState = (state: RootState) => state.therapistSlice;
// Reducer
export default therapistSlice.reducer;
