import { APINames } from "@mapsy/shared";
import BaseAPI from "./BaseEndpointAPI";

class CatalogsAPI extends BaseAPI {
  constructor() {
    super(APINames.CATALOGS);
  }

  get byTherapist() {
    return `${this.baseURL}/therapist`;
  }
}

const api = new CatalogsAPI();

export default api;
