import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { LinearProgress, Typography } from "@mui/material";

import { Action, Patient, UserType } from "@mapsy/shared";

import { Entity, HandleSubmitParams } from "interfaces";
import { MyProfileLayout } from "layouts/MyProfileLayout";
import { ProfileSection } from "components/molecules/profile/ProfileSection";
import { selectSessionState } from "features/session/session.slice";
import { SubmitOnChangeProvider } from "providers/SubmitOnChangeProvider";
import { useAuth } from "hooks/useAuthAPI";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { isMoment } from "moment";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { useValidation } from "providers/FormProvider";
import { useBeforeUnload } from "hooks/useBeforeUnload";

export const UPDATE_DELAY = 2000;

export const MyProfilePatient: React.FC = () => {
  const { createAnalytic } = useAnalytics();
  const { setShowErrors, formValid } = useValidation();
  const [userData, setUserData] = useState<Patient>();
  const [updatedFields, setUpdatedFields] = useState<Partial<Patient>>();
  const { profileInfo } = useSelector(selectSessionState);
  const { getUserInfo, isLoading, updateUserInfo, errorMsg } = useAuth();
  const { Common } = useGetStartedForms();
  const inputs = useMemo(
    () =>
      Common.filter(({ propertyName }) => propertyName !== "phone.dial_code"),
    [Common]
  );
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);
  useBeforeUnload({ when: !hasSucceeded && Boolean(updatedFields)});

  const submitParams: HandleSubmitParams = useMemo(
    () => ({
      values: updatedFields as Entity,
    }),
    [inputs, updatedFields]
  );

  const handleSubmit = useCallback(
    async ({ values }: HandleSubmitParams) => {
      if (!profileInfo?.id) {
        return;
      }

      if (!formValid) {
        setShowErrors(true);
        return;
      }
      setIsUpdateLoading(true);

      const id = await updateUserInfo(
        { _id: profileInfo?.id, fieldsToUpdate: values },
        UserType.Patient
      );
      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.MY_PROFILE_PATIENT,
        data: { values },
      });
      if (id) {
        await fetchUserInfo(profileInfo.id, profileInfo.type);
        setIsUpdateLoading(false);
        setHasSucceeded(true);
        setUpdatedFields(undefined);
      }
    },
    [profileInfo, formValid]
  );

  const fetchUserInfo = useCallback(
    async (id: string, userType: UserType) => {
      const data = await getUserInfo(id, userType);
      setUserData(data);
    },
    [profileInfo]
  );

  useEffect(() => {
    if (!profileInfo?.id || !profileInfo.type) {
      return;
    }
    fetchUserInfo(profileInfo.id, profileInfo.type);
  }, [profileInfo]);

  useEffect(() => {
    if (!updatedFields) {
      return;
    }
    setHasSucceeded(false);
  }, [updatedFields]);

  const handleChange = useCallback(
    (propertyName: string, value: any, context: any) => {
      if (hasSucceeded) {
        setHasSucceeded(false);
      }

      if (propertyName === "birthDate" && isMoment(value)) {
        value = value.valueOf();
      }

      setUserData(
        (_prevUserData) =>
          ({
            ..._prevUserData,
            [propertyName]: value,
          }) as Patient
      );

      setUpdatedFields((_prevUpdatedFields) => ({
        ..._prevUpdatedFields,
        [propertyName]: value,
      }));
    },
    [hasSucceeded]
  );

  if (isLoading && !userData) {
    return <LinearProgress />;
  }

  if (!userData) {
    return <Typography>Datos de paciente no disponibles.</Typography>;
  }

  return (
    <SubmitOnChangeProvider
      stateToObserve={updatedFields}
      helperToSubmitOnChange={handleSubmit}
      submitParams={submitParams}
    >
      <MyProfileLayout
        isForm
        isBackButtonLoading={isUpdateLoading}
        hasSucceeded={hasSucceeded}
        setHasSucceeded={setHasSucceeded}
        errorMsg={errorMsg}
      >
        <ProfileSection
          title="Mi perfil"
          inputs={inputs}
          userData={userData}
          onPropertyChanged={handleChange}
          includeProfilePic
          includePasswordChange
          userType={UserType.Patient}
        />
      </MyProfileLayout>
    </SubmitOnChangeProvider>
  );
};
