import React, {
  useMemo,
  ChangeEvent,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  SwitchProps,
  TextField,
  Theme,
  InputAdornment,
} from "@mui/material";
import {
  DatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import moment from "moment";
import COLORS from "constants/colors";
import { debounce as debounceChange } from "utils/debounce";

import { InputType } from "@mapsy/shared";
import {
  FormInput,
  SelectInputMetadata,
  ProjectedInputMetadata,
  AutocompleteInputMetadata,
  TextInputMetadata,
  DateInputMetadata,
  AutoCompletePropsOverride,
  InputFieldProps,
  Entity,
  ValidationProps,
  TextareaInputMetadata,
} from "interfaces";
import { MapsySwitch } from "./MapsySwitch";
import _ from "lodash";
import { useValidation } from "providers/FormProvider";
import { CustomTextarea } from "./Textarea";

const SharedSx = {
  fontSize: "1.1rem",
  ".MuiInputBase-input": {
    borderRadius: "8px !important",
    paddingY: "0.6rem",
    paddingX: "1.5rem !important",
  },
  ".Mui-error .MuiInputBase-input": {
    border: "solid 2px #FF0707 !important",
  },
  ".MuiFormLabel-root": {
    color: `${COLORS.BLUE_1} !important`,
  },
};

const customTransparentBgSx: SxProps<Theme> = {
  borderRadius: "10px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid",
    borderColor: COLORS.BLUE_1,
    borderRadius: "8px",
  },
  ".MuiAutocomplete-inputRoot": {
    paddingX: "0 !important",
    paddingY: "3px",
  },
};

const customWhiteBgSx: SxProps<Theme> = {
  color: "rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: "10px",
  },
  ".MuiInputBase-input": {
    backgroundColor: COLORS.PURE_WHITE,
    borderRadius: "10px !important",
  },
  ".MuiFormHelperText-root": {
    color: COLORS.GREY,
  },
};

const noBorderBgSx: SxProps<Theme> = {
  border: "none",
  ".Mui-focused": {
    ".MuiInputBase-input": {
      borderBottom: `solid 1px ${COLORS.PURE_WHITE}`,
    },
  },
  ".Mui-error .MuiInputBase-input": {
    borderBottom: "solid 1px #FF0707 !important",
  },
  ".MuiInputBase-input": {
    transition: "all 0.2s ease",
    backgroundColor: "transparent",
    border: "none",
    borderBottom: `solid 1px ${COLORS.BLUE_1}`,
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

const DELAYED_TIME = 150;

export const InputField: React.FC<FormInput & Partial<InputFieldProps>> = ({
  propertyName,
  inputType = InputType.Text,
  handleChange,
  value,
  sx,
  required = false,
  fullWidth = true,
  autoFocus = false,
  disabled = false,
  backgroundMode = "transparent",
  validation,
  debounce,
  helperText,
  setValidity,
  ...props
}) => {
  const [innerValue, setInnerValue] = useState(value);
  const [innerHelperText, setInnerHelperText] = useState<string>();
  const [error, setError] = useState<boolean>(false);

  const { validate, errors, showErrors } = useValidation();

  const styles: SxProps<Theme> = useMemo(() => {
    if (backgroundMode === "white") {
      return { ...SharedSx, ...customWhiteBgSx, ...sx };
    }

    if (backgroundMode === "no-border") {
      return { ...SharedSx, ...noBorderBgSx, ...sx };
    }

    return { ...SharedSx, ...customTransparentBgSx, ...sx };
  }, [sx, backgroundMode]);

  const debounceHandleChange = useMemo(
    () => debounceChange(DELAYED_TIME, handleChange),
    [handleChange]
  );

  const validateInputNow = useCallback(
    (value: Entity, validation?: ValidationProps) => {
      if (!validation) return;
      validate(
        value,
        propertyName,
        props.name || propertyName,
        validation,
        inputType
      );
    },
    [inputType, props.name, propertyName, validate]
  );

  const debounceValidation = useCallback(_.debounce(validateInputNow), []);

  useEffect(() => {
    if (!showErrors) {
      setInnerHelperText(helperText);
      return;
    }
    const text = errors[props.name || propertyName] || helperText;
    setInnerHelperText(text);
    setError(Boolean(errors[props.name || propertyName]));
  }, [errors, helperText, showErrors]);

  useEffect(() => {
    if (value === innerValue) {
      return;
    }
    setInnerValue(value);
  }, [value]);

  const innerHandleChange = useCallback(
    (propertyName: string, value: any, context: any, debounce = false) => {
      setInnerValue(value);

      if (context?.validationError) {
        setInnerHelperText(
          /Date/.test(context.validationError)
            ? "Fecha inválida"
            : "Entrada inválida"
        );
        setError(true);
        return;
      } else if (context?.validationError === null) {
        setInnerHelperText(helperText || undefined);
        setError(false);
      }

      debounceValidation(value, validation);

      if (debounce) {
        debounceHandleChange(propertyName, value, context);
      } else {
        handleChange?.(propertyName, value, context);
      }
    },
    [validation, debounceHandleChange, handleChange]
  );

  if (inputType === InputType.Select) {
    const {
      label,
      menuItems = [],
      selectInputProps,
      placeholder = "",
      name = "",
    } = props as SelectInputMetadata;
    return (
      <FormControl fullWidth={fullWidth} required={required} error={error}>
        <InputLabel id={`label-select-${label}-${propertyName}`}>
          {label}
        </InputLabel>
        <Select
          sx={styles}
          fullWidth
          displayEmpty
          margin="dense"
          autoFocus={autoFocus}
          required={required}
          placeholder={placeholder}
          labelId={`label-select-${label}-${propertyName}`}
          label={label}
          onChange={(e) =>
            innerHandleChange(propertyName, e.target.value, e, debounce ?? true)
          }
          name={props.name || propertyName}
          value={innerValue}
          data-testid={`input-select-${propertyName}`}
          disabled={disabled}
          variant={selectInputProps?.variant || "outlined"}
        >
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
          {menuItems?.length > 0 ? (
            menuItems.map(({ label, value }, i: number) => (
              <MenuItem
                value={value}
                key={`${i}-menu-item-${value}-${name || propertyName}`}
              >
                {label}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={""}>
              Necesitas al menos un(a) {label} para continuar
            </MenuItem>
          )}
        </Select>
        {innerHelperText && <FormHelperText id="my-helper-text">{innerHelperText}</FormHelperText>}
      </FormControl>
    );
  }

  if (inputType === InputType.Date) {
    const { datePickerProps = {} } = props as DateInputMetadata;
    return (
      <FormControl fullWidth={fullWidth} required={required} error={error}>
        <DatePicker
          {...datePickerProps}
          {...props}
          sx={styles}
          autoFocus={autoFocus}
          name={props.name || propertyName}
          disabled={disabled}
          value={moment(innerValue as string | number)}
          onChange={(
            v: any,
            c: PickerChangeHandlerContext<DateValidationError>
          ) => innerHandleChange(propertyName, v, c, debounce ?? true)}
          data-testid={`input-date-${propertyName}`}
        />
        {innerHelperText && (
          <FormHelperText id={`helper-text-${propertyName}`}>
            {innerHelperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  if (inputType === InputType.Projected && handleChange) {
    const { ChildElement, inputs, sxProps } = props as ProjectedInputMetadata;

    if (!ChildElement) {
      return <>Not Valid: {propertyName}</>;
    }

    return (
      <>
        <ChildElement
          handleChange={handleChange}
          inputs={inputs}
          sxBoxProps={sxProps}
          entity={innerValue}
        />
      </>
    );
  }

  if (inputType === InputType.Textarea) {
    const { textareaProps = {}, label } = props as TextareaInputMetadata;
    return (
      <>
        <FormControl fullWidth={fullWidth} required={required}>
          {label && (
            <label
              id={`label-textarea-${label}-${propertyName}`}
              style={{
                color: COLORS.BLUE_1,
                fontSize: "1rem",
                padding: "14px 16px",
              }}
            >
              {label}
            </label>
          )}
          <CustomTextarea
            className={error ? "invalid-input" : undefined}
            value={innerValue?.toString()}
            onChange={(e) =>
              innerHandleChange(propertyName, e.target.value.trimStart(), e, debounce)
            }
            autoFocus={autoFocus}
            data-testid={`input-text-${propertyName}`}
            disabled={disabled}
            placeholder={props.placeholder}
            {...textareaProps}
          />
          {innerHelperText && (
            <FormHelperText id={`helper-text-${propertyName}`}>
              {innerHelperText}
            </FormHelperText>
          )}
        </FormControl>
      </>
    );
  }

  if (inputType === InputType.Autocomplete) {
    const {
      options = [],
      autocompleteProps = { multiple: false, disablePortal: false },
      maxSelectedOptions = Infinity,
    } = props as AutocompleteInputMetadata;
    const {
      multiple = false,
      disablePortal = false,
      ...restProps
    } = autocompleteProps as AutoCompletePropsOverride;
    return (
      <FormControl fullWidth={fullWidth} required={required} error={error}>
        <Autocomplete
          autoComplete
          fullWidth={fullWidth}
          size="medium"
          sx={styles}
          multiple={multiple}
          disabled={disabled}
          filterSelectedOptions={multiple}
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            options: any | null,
            reason: AutocompleteChangeReason,
            details?: AutocompleteChangeDetails<any> | undefined
          ) => {
            const value = multiple
              ? options.map((o: any) => o.value)
              : options?.value;

            if (
              multiple &&
              value &&
              Array.isArray(value) &&
              maxSelectedOptions &&
              value.length > maxSelectedOptions
            ) {
              return;
            }

            innerHandleChange(
              propertyName,
              value,
              {
                reason,
                details,
                event,
              },
              debounce ?? true
            );
          }}
          disablePortal={disablePortal}
          {...restProps}
          id={props.name || propertyName}
          options={[{ label: "", value: "" }, ...options]}
          value={
            multiple && Array.isArray(innerValue)
              ? options.filter((option) =>
                  innerValue.includes(option.value.toString())
                )
              : options.find(
                  (option) => option.value.toString() === innerValue?.toString()
                ) || null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name={props.name || propertyName}
              label={props.label}
              placeholder={props.placeholder}
              error={error}
              helperText={innerHelperText}
            />
          )}
        />
      </FormControl>
    );
  }

  if (inputType === InputType.Switch) {
    return (
      <MapsySwitch
        {...(props as SwitchProps)}
        disableRipple
        sx={{
          ...styles,
          boxShadow: "none",
        }}
        name={props.name || propertyName}
        checked={innerValue as boolean}
        onChange={(e: ChangeEvent<HTMLInputElement>, checked) =>
          innerHandleChange(propertyName, checked, e, debounce)
        }
        disabled={disabled}
      />
    );
  }

  const { textFieldProps = {} } = props as TextInputMetadata;

  const inputProps = new RegExp(/price|cost/g).test(propertyName)
    ? {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }
    : undefined;
  return (
    <FormControl fullWidth={fullWidth} required={required}>
      <TextField
        variant="outlined"
        {...textFieldProps}
        FormHelperTextProps={{
          sx: {
            color: backgroundMode === "white" ? "white !important" : undefined,
          },
        }}
        className={error ? "invalid-input" : undefined}
        error={error}
        helperText={innerHelperText}
        sx={styles}
        id={props.name || propertyName}
        placeholder={props.placeholder}
        name={props.name || propertyName}
        value={innerValue}
        autoFocus={autoFocus}
        onChange={(e) =>
          innerHandleChange(propertyName, e.target.value.trimStart(), e, debounce)
        }
        label={props.label}
        disabled={disabled}
        data-testid={`input-text-${propertyName}`}
        InputProps={inputProps}
      />
    </FormControl>
  );
};
