import React, { useMemo } from "react";
import { v4 as uuid } from "uuid";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Box, SxProps } from "@mui/material";
import COLORS from "../../constants/colors";
import { Theme } from "@emotion/react";

interface Props {
  stars: number;
  sxBox?: SxProps<Theme>;
}
const MAX_STARS = 5;
export const Stars: React.FC<Props> = ({ stars, sxBox }) => {
  const gottenStarsArray = useMemo<{ id: string }[]>(
    () =>
      Array.from({ length: Math.floor(stars) }, (val, i) => ({
        id: `${uuid()}-${i}-gottenStar`,
      })),
    [stars]
  );
  const remStarsArray = useMemo<{ id: string }[]>(
    () =>
      Array.from({ length: MAX_STARS - Math.floor(stars) }, (val, i) => ({
        id: `${uuid()}-${i}-remStar`,
      })),
    [stars]
  );
  return (
    <Box sx={{ display: "flex", justifyContent: "space-around", ...sxBox }}>
      {gottenStarsArray.map((star) => (
        <StarIcon
          key={`${star.id}-gotten`}
          sx={{ fontSize: 16, color: COLORS.YELLOW }}
        />
      ))}
      {remStarsArray.map((star) => (
        <StarBorderIcon
          key={`${star.id}-rem`}
          sx={{ fontSize: 16, color: COLORS.YELLOW }}
        />
      ))}
    </Box>
  );
};
