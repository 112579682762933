import { Box, CircularProgress } from '@mui/material'

export const LoadingOverlay: React.FC<{ show: boolean }> = ({ show }) => {
  return (
    <>
      <Box className="overlay" sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#eee',
        borderRadius: "10px",
        zIndex: 10,
        opacity: 0.5,
        display: show ? 'block' : 'none'
      }} />
      <Box className="loading-modal" sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        zIndex: 20,
        display: show ? 'block' : 'none'
      }}>
        <CircularProgress thickness={1} size={200} />
      </Box>
    </>
  )
}