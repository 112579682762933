import { Grid, Typography } from "@mui/material";
import { TitledLayout } from "layouts/TitledLayout";
import { UserType, StylesEnum } from "@mapsy/shared";
import { CustomButton } from "../atoms/Button";

interface Props {
  setUserType: (type: UserType) => void;
  isWelcome: boolean;
}
export const SelectUserType: React.FC<Props> = ({
  setUserType,
  isWelcome = true,
}) => {
  return (
    <TitledLayout containerSx={{ mb: 8, gap: { xs: 4, md: 12 } }}>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        {isWelcome ? (
          <>
            <Typography sx={{ fontWeight: 500, fontSize: "1.5rem" }}>
              ¡Te damos la bienvenida!
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "1.5rem" }}>
              Por favor selecciona el tipo de usuario con el que deseas
              ingresar:
            </Typography>
          </>
        ) : (
          <Typography sx={{ fontWeight: 500, fontSize: "1.5rem" }}>
            Por favor selecciona el tipo de usuario de tu cuenta:
          </Typography>
        )}
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <CustomButton
          customStyle={StylesEnum.primary}
          disableRipple={true}
          sx={{
            padding: "10px 12px",
            borderRadius: "10px",
            width: "200px",
          }}
          onClick={() => setUserType(UserType.Patient)}
        >
          Paciente
        </CustomButton>
        <CustomButton
          customStyle={StylesEnum.primary}
          disableRipple={true}
          sx={{
            padding: "10px 12px",
            borderRadius: "10px",
            width: "200px",
          }}
          onClick={() => setUserType(UserType.Therapist)}
        >
          Terapeuta
        </CustomButton>
      </Grid>
    </TitledLayout>
  );
};
