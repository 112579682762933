import React, { ReactNode, useMemo, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  Container,
  CircularProgress,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import COLORS from "../../constants/colors";
import { CustomButton } from "../atoms/Button";
import { useNavigate } from "react-router-dom";
import { Action, LoadingEnum, StylesEnum, UserType } from "@mapsy/shared";
import {
  deleteSession,
  selectSessionState,
} from "features/session/session.slice";
import { useSelector } from "react-redux";
import { SessionPopover } from "components/atoms/SessionPopover";
import { MenuOption, MenuOptions } from "interfaces/navbar.interface";
import { useAppDispatch } from "hooks";
import { useAuth } from "hooks/useAuthAPI";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { NotificationsBell } from "components/molecules/NotificationsBell";

const LoggedInButton: React.FC<{
  isLoggedIn: boolean;
  popoverMenu: MenuOptions;
  sessionLoading: LoadingEnum;
}> = ({ isLoggedIn, popoverMenu, sessionLoading }) => {
  const nav = useNavigate();

  if (sessionLoading === LoadingEnum.pending) {
    return <CircularProgress />;
  }

  if (isLoggedIn) {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <NotificationsBell />
        <SessionPopover popoverMenu={popoverMenu} />
      </Box>
    );
  }

  return (
    <CustomButton
      customStyle={StylesEnum.primary}
      children={"Iniciar Sesión"}
      disableRipple={true}
      size="small"
      sx={{
        padding: "10px 12px",
        borderRadius: "10px",
      }}
      onClick={() => nav("/users/signin")}
    />
  );
};

const NavbarWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const nav = useNavigate();
  return (
    <AppBar position="static" sx={{ backgroundColor: COLORS.BLUE_1, py: 1 }}>
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{
            justifyContent: { md: "space-around", xs: "flex-end" },
            gap: { md: 1, lg: 2 },
          }}
        >
          <Typography
            fontWeight={600}
            sx={{
              display: { xs: "none", md: "inherit" },
              ":hover": { cursor: "pointer" },
              fontWeight: 500,
              fontSize: { md: "2rem", lg: "2.4rem" },
            }}
            onClick={() => nav("/")}
          >
            Mapsy
          </Typography>
          {children}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export function Navbar() {
  const dispatch = useAppDispatch();
  const { createAnalytic } = useAnalytics();
  const { getLogout } = useAuth();

  const PUBLIC_NAVBAR_OPTS: MenuOptions = [
    { label: "Búsqueda Por IA", href: "/reason" },
    { label: "Quiénes Somos", href: "/about-us" },
    /* { label: "Blog", href: "/" }, // @TODO Implementar hasta MPV2 */
    { label: "Únete Como Psicólogo", href: "/join" },
  ];
  const THERAPISTS_NAVBAR_OPTS: MenuOptions = [
    { label: "Mi agenda", href: "/therapist/my_calendar" },
  ];

  const SHARED_POPOVER_MENU: MenuOptions = [
    {
      label: "Mi perfil",
      href: "/my_profile",
    },
    {
      label: "Cerrar sesión",
      onClick: () => {
        dispatch(deleteSession());
        getLogout();
      },
      sx: {
        color: COLORS.TEXT_RED,
      },
    },
  ];
  const PATIENT_POPOVER_MENU: MenuOptions = [
    {
      label: "Mis citas",
      href: "/patient/my_appointments",
    },
  ];
  const THERAPIST_POPOVER_MENU: MenuOptions = [
    {
      label: "Mis consultorios",
      href: "/therapist/my_locations",
    },
  ];
  const ADMIN_POPOVER_MENU: MenuOptions = [];

  const [anchorElNav, setAnchorElNav] = useState(null);
  const theme = useTheme();
  const nav = useNavigate();
  const { isLoggedIn, profileInfo, isLoading } =
    useSelector(selectSessionState);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (menuOption?: MenuOption) => {
    setAnchorElNav(null);
    if (menuOption) {
      createAnalytic({
        action: Action.CLICK,
        componentName: COMPONENTS.NAVBAR,
        data: {
          ...menuOption,
        },
      });
    }
  };

  const menuOptions: MenuOptions = useMemo(() => {
    if (isLoggedIn && profileInfo?.type === UserType.Therapist) {
      return THERAPISTS_NAVBAR_OPTS;
    }

    if (isLoggedIn && profileInfo?.type === UserType.Patient) {
      return PUBLIC_NAVBAR_OPTS.slice(0, 2); //3 cuando el blog esté disponible
    }

    return PUBLIC_NAVBAR_OPTS;
  }, [isLoggedIn, profileInfo]);

  const popoverMenu: MenuOptions = useMemo(() => {
    if (!profileInfo?.type || profileInfo?.type === UserType.None) {
      return [];
    }

    const basePathByUserType: Record<UserType, string> = {
      [UserType.Admin]: "/admin",
      [UserType.Patient]: "/patient",
      [UserType.Therapist]: "/therapist",
      [UserType.None]: "",
    };

    const sharedMenuWithBasePath = SHARED_POPOVER_MENU.map(
      ({ href, ...rest }) => ({
        href: href
          ? `${basePathByUserType[profileInfo.type]}${href}`
          : undefined,
        ...rest,
      })
    );

    return [
      ...{
        [UserType.Admin]: ADMIN_POPOVER_MENU,
        [UserType.Therapist]: THERAPIST_POPOVER_MENU,
        [UserType.Patient]: PATIENT_POPOVER_MENU,
      }[profileInfo.type],
      ...sharedMenuWithBasePath,
    ];
  }, [profileInfo]);

  if (isMobile) {
    return (
      <NavbarWrapper>
        {isLoggedIn && <NotificationsBell />}
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElNav)}
          onClose={() => handleCloseNavMenu()}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {menuOptions.map(({ label, href }, i) => (
            <MenuItem
              key={`${label}-${i}`}
              onClick={() => {
                handleCloseNavMenu({ label, href });
                href && nav(href);
              }}
            >
              <Typography textAlign="center">{label}</Typography>
            </MenuItem>
          ))}
          {isLoggedIn ? (
            popoverMenu.map(({ label, href, onClick }, i) => (
              <MenuItem
                key={`${label}-${i}`}
                onClick={() => {
                  handleCloseNavMenu({ label, href });
                  onClick?.();
                  href && nav(href);
                }}
              >
                <Typography textAlign="center">{label}</Typography>
              </MenuItem>
            ))
          ) : (
            <MenuItem onClick={() => nav("/users/signin")}>
              <Typography textAlign="center">Iniciar Sesión</Typography>
            </MenuItem>
          )}
        </Menu>
      </NavbarWrapper>
    );
  }

  return (
    <NavbarWrapper>
      {menuOptions.map(({ label, href }, i) => (
        <Button
          key={`${label}-${i}`}
          onClick={() => {
            handleCloseNavMenu({ label, href });
            href && nav(href);
          }}
          disableRipple={true}
          size={"large"}
          sx={{
            my: 2,
            color: "white",
            display: "block",
            textTransform: "none",
            fontSize: { md: "1.3rem", lg: "1.5rem" },
            fontWeight: 500,
            whiteSpace: { lg: "nowrap" },
          }}
        >
          {label}
        </Button>
      ))}
      <LoggedInButton
        isLoggedIn={isLoggedIn}
        popoverMenu={popoverMenu}
        sessionLoading={isLoading}
      />
    </NavbarWrapper>
  );
}
