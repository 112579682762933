import moment from 'moment';

export function timeAgo(isoString: string) {
  const now = moment();
  const past = moment(isoString);
  const seconds = now.diff(past, 'seconds');

  if (seconds < 60) {
      return seconds <= 1 ? "hace un momento" : `hace ${seconds} sec`;
  }

  const minutes = now.diff(past, 'minutes');
  if (minutes < 60) {
      return minutes === 1 ? "hace un min" : `hace ${minutes}m`;
  }

  const hours = now.diff(past, 'hours');
  if (hours < 24) {
      return hours === 1 ? "hace una hora" : `hace ${hours}h`;
  }

  const days = now.diff(past, 'days');
  if (days < 7) {
      return days === 1 ? "hace un día" : `hace ${days}d`;
  }

  const weeks = now.diff(past, 'weeks');
  if (weeks < 4) {
      return weeks === 1 ? "hace una semana" : `hace ${weeks}sem`;
  }

  const months = now.diff(past, 'months');
  if (months < 12) {
      return months === 1 ? "hace un mes" : `hace ${months} meses`;
  }

  const years = now.diff(past, 'years');
  return years === 1 ? "hace un año" : `${years} años`;
}