/**
 * Function to delay repetition of an action until delay has passed. Function passed as param (func) is blocked until time out is ended. If func is not blocked, it runs immediately .
 * @param delay miliseconds to debounce function
 * @param func optional function that runs immediately but user has to wait until repetition.
 * @returns a function to be triggered after time out
 */
export function delayRepetition<T extends (...args: any[]) => any>(
  delay: number,
  func?: T
) {
  let timerId: ReturnType<typeof setTimeout>;
  let isActionBlocked = false;

  return function (this: any, ...args: Parameters<T>) {
    const context = this;

    clearTimeout(timerId);

    if (!isActionBlocked) {
      func?.apply(context, args);
      isActionBlocked = true;
    }

    timerId = setTimeout(() => {
      isActionBlocked = false;
    }, delay);
  };
}

/**
 * Function to delay execution until delay has passed
 * @param delay miliseconds to debounce function
 * @param func optional function
 * @returns a function to be triggered after time out
 */
export function debounce<T extends (...args: any[]) => any>(
  delay: number,
  func?: T
) {
  let timerId: ReturnType<typeof setTimeout>;

  /**
   * Debounce function to be executed after a delay.
   * @param this context
   * @param args Arguments for the function
   * @returns timer id
   */
  return function (this: any, ...args: Parameters<T>) {
    const context = this;

    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func?.apply(context, args);
    }, delay);

    return timerId;
  };
}
