import { APINames } from "@mapsy/shared";

class BaseAPI {
  public baseAPIName = "api";
  public endpoint = "/";
  constructor(apiName: APINames) {
    this.endpoint = `/${this.baseAPIName}/${apiName}`;
  }

  get baseURL() {
    return this.endpoint;
  }

  urlById(id: string) {
    return `${this.endpoint}/${id}`;
  }

  addParams(params: Record<string, any>) {
    const queryString = new URLSearchParams(params).toString();
    return `${this.baseURL}?${queryString}`;
  }
}

export default BaseAPI;
