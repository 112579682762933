import { LoadingEnum, OrderEnum } from "@mapsy/shared";
import {
  getPlacesList,
  selectCatalogState,
} from "features/catalogs/catalogs.slice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";

export const usePlaces = () => {
  const dispatch = useAppDispatch();
  const {
    places: { list: placesList, loading: placesLoading },
  } = useAppSelector(selectCatalogState);

  useEffect(() => {
    if (placesLoading !== LoadingEnum.idle) {
      return;
    }

    dispatch(getPlacesList({ order: OrderEnum.DESC, orderBy: "value" }));
  }, [placesLoading]);

  return {
    placesList,
    placesLoading,
  };
};
