import { APINames } from "@mapsy/shared";
import BaseAPI from "./BaseEndpointAPI";

class NotificationAPI extends BaseAPI {
  constructor() {
    super(APINames.NOTIFICATION);
  }

  getNotificationByUser(id: string, page: number, size: number) {
    return `${this.baseURL}?userId=${id}&page=${page}&limit=${size}`
  }

  seenById(id: string, userId: string) {
    return `${this.baseURL}/seen/${id}?userId=${userId}`
  }
}

const api = new NotificationAPI();

export default api;
