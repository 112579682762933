export class SearchParamsToObject {
  object: Record<string, any> = {};
  searchParams: URLSearchParams;

  constructor(search: URLSearchParams) {
    this.object = Object.fromEntries(search);
    this.searchParams = search
  }

  get(key: string): any | undefined {
    return this.object[key];
  }

  has(key: string): boolean {
    if (this.object[key]) {
      return true;
    }
    return false;
  }

  toParamsString() {
    return this.searchParams.toString();
  }

  getRedirectToWithParams(): string {
    const str = this.searchParams.toString();

    if (!/redirectTo=/g.test(str)) {
      return "";
    }

    const [_, ...params] = str.split("redirectTo=");
    return params.map(decodeURIComponent).join("");
  }
}
