import _ from "lodash";
import { ChangeEvent, useCallback, useState } from "react";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { Entity, GetStartedProps } from "interfaces";
import { UserForm } from "../form/UserForm";
import { defaultLocation } from "constants/defaultUserValues";

export const Location: React.FC<GetStartedProps> = ({
  userInfo: { locations },
  changePage,
  showBackButton,
}) => {
  const { LocationForm } = useGetStartedForms();
  const [locationInfo, setLocationInfo] = useState<Entity>(
    locations?.length ? locations[0] : defaultLocation(1)
  );

  const propertyChanged = useCallback(
    (
      propertyName: string,
      value: any,
      c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setLocationInfo((prevLocationInfo: Entity) => ({
        ...prevLocationInfo,
        [propertyName]: value,
      }));
    },
    []
  );

  return (
    <UserForm
      handleSubmit={() =>
        changePage({ locations: [_.omit(locationInfo, "_id")] }, false)
      }
      handleBack={() =>
        changePage({ locations: [_.omit(locationInfo, "_id")] }, true)
      }
      inputs={LocationForm}
      entity={locationInfo}
      isLoading={false}
      onPropertyChanged={propertyChanged}
      showBackButton={showBackButton}
    />
  );
};
