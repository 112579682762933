import { Box, Container, Typography } from "@mui/material";
import COLORS from "../../constants/colors";
import { BannerGrid } from "../atoms/BannerGrid";

export const How = () => {
  return (
    <Box sx={{ backgroundColor: COLORS.YELLOW }} component="section">
      <Container
        maxWidth="lg"
        sx={{ textAlign: "center", pt: 4, pb: { md: 6 } }}
      >
        <Box component="div">
          <Typography variant="h4" sx={{ fontSize: "2rem", fontWeight: 500 }}>
            ¿Cómo buscar un psicólogo en Mapsy?
          </Typography>
        </Box>
        <BannerGrid
          items={[
            {
              title: "Regístrate",
              subtitle: "Crea una cuenta en la plataforma de Mapsy",
            },
            {
              title: "Cuéntanos...",
              subtitle: "Acerca de tus objetivos en terapia",
            },
            {
              title: "¡Y listo!",
              subtitle: "Escoge al profesional que mejor se adapte a ti",
            },
          ]}
          itemBottomMargin={4}
          showItemNumber
          sx={{
            pt: 4,
            alignItems: "center",
            justifyContent: "center",
            columnGap: 3,
          }}
          boxStyle={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
          }}
        />
      </Container>
    </Box>
  );
};
