import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Typography } from "@mui/material";
import { TherapistSearchLayout } from "../layouts/TherapistSearchLayout";
import TherapistAPI from "../classes/endpointGenerator/TherapistAPI";
import { useAxios } from "hooks/useAxios";
import { TherapistCard } from "components/molecules/TherapistCard";
import COLORS from "constants/colors";
import { capitalizeName } from "utils/capitalize";
import { Therapist } from "@mapsy/shared";

const TherapistPage = () => {
  const nav = useNavigate();
  const { therapistId } = useParams();
  const [loading, setLoading] = useState(true);
  const [therapist, setTherapist] = useState<Therapist>();
  const [subtitle, setSubtitle] = useState("");

  const {
    isLoading: axiosLoading,
    getData,
    errorMsg,
  } = useAxios({
    loadingDefault: true,
  });

  useEffect(() => {
    setLoading(axiosLoading);
  }, [axiosLoading]);

  useEffect(() => {
    if (!therapistId) {
      return;
    }

    const fetchTherapist = async () => {
      const t = await getData(TherapistAPI.urlById(therapistId));
      setTherapist(t);
    };

    fetchTherapist();
  }, [therapistId, getData]);

  useEffect(() => {
    if (!therapist) {
      return;
    }

    const { firstName, middleName, lastName } = therapist;

    setLoading(false);
    setSubtitle(capitalizeName([firstName, middleName, lastName]));
  }, [therapist]);

  if (!therapist && !loading) {
    return (
      <TherapistSearchLayout title="Psicólogos" loading={loading} subtitle="">
        <Typography variant="body2">
          Este terapeuta no se encuentra disponible. Intenta buscando otro.
        </Typography>
        <Typography
          onClick={() => nav(-1)}
          sx={{
            color: COLORS.BLUE_1,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Volver
        </Typography>
      </TherapistSearchLayout>
    );
  }

  return (
    <TherapistSearchLayout
      title="Psicólogos"
      subtitle={subtitle}
      loading={loading}
    >
      {errorMsg && (
        <Typography variant="body2">No se pudo obtener el terapeuta</Typography>
      )}
      {therapist && <TherapistCard isSingleView therapist={therapist} />}
    </TherapistSearchLayout>
  );
};

export default TherapistPage;
