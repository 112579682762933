import { useEffect } from "react";
import { LoadingEnum, OrderEnum, UserType } from "@mapsy/shared";

import { useAppDispatch, useAppSelector } from "hooks";
import { MyProfilePatient } from "components/organisms/MyProfilePatient";
import { MyProfileTherapist } from "components/organisms/MyProfileTherapist";
import { getTopicList, selectReasonState } from "features/reason/reasonSice";
import { ValidationProvider } from "providers/FormProvider";

interface TherapistProps {
  userType: UserType.Therapist;
  showLocations?: boolean;
}
interface PatientProps {
  userType: UserType.Patient;
  showLocations?: boolean;
}

export const MyProfile: React.FC<TherapistProps | PatientProps> = ({
  userType,
  showLocations,
}) => {
  const dispatch = useAppDispatch();
  const { topicsLoading } = useAppSelector(selectReasonState);

  useEffect(() => {
    if (topicsLoading !== LoadingEnum.idle) {
      return;
    }

    dispatch(getTopicList({ order: OrderEnum.ASC, orderBy: "longNames.es_name" }));
  }, []);

  if (userType === UserType.Patient) {
    return (
      <ValidationProvider>
        <MyProfilePatient />
      </ValidationProvider>
    );
  }

  if (userType === UserType.Therapist) {
    return (
      <ValidationProvider>
        <MyProfileTherapist showLocations={showLocations} />
      </ValidationProvider>
    );
  }

  return <></>;
};

export default MyProfile;
