import { ErrorsObj, InputType } from "@mapsy/shared";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { Entity, Form, ValidationProps } from "interfaces";
import _ from "lodash";
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
  FormEvent,
  useId,
} from "react";
import { areInputsValid, validateInput } from "utils/areInputsValid";
import { mergeErrObjects } from "utils/object.utils";

interface ValidationContextProps {
  formValid: boolean;
  getErrorByInputName: (name: string) => string;
  validate: (
    value: Entity,
    propertyName: string,
    inputName: string,
    validation: ValidationProps,
    inputType?: InputType
  ) => boolean;
  validateEntity: (entity: Entity, form: Form) => void;
  errors: ErrorsObj;
  showErrors: boolean;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  formId?: string;
}

const defaultValues = {
  formValid: true,
  getErrorByInputName: (_name: string) => "",
  validate: (..._args: any) => true,
  validateEntity: _.noop,
  errors: {},
  showErrors: true,
  setShowErrors: _.noop,
};

const ValidationContext = createContext<ValidationContextProps | undefined>(
  undefined
);

interface ValidationProviderProps {
  showErrorsDefault?: boolean;
  handleSubmit?: () => Promise<boolean | undefined>; //if passed, children is nested within a form
  children: ReactNode;
}

export const ValidationProvider: React.FC<ValidationProviderProps> = ({
  handleSubmit,
  showErrorsDefault = false,
  children,
}) => {
  const formId = useId();
  const [formValid, setFormValid] = useState(false);
  const [errors, setErrors] = useState<ErrorsObj>({});
  const [showErrors, setShowErrors] = useState(showErrorsDefault);
  const [succeededSubmit, setSucceededSubmit] = useState(false);
  useBeforeUnload({ when: handleSubmit ? !succeededSubmit : false });

  useEffect(() => {
    setFormValid(_.isEmpty(errors));
  }, [errors]);

  const getErrorByInputName = useCallback(
    (name: string) => _.get(errors, name, ""),
    [errors]
  );

  const validateEntity = useCallback((entity: Entity, form: Form) => {
    areInputsValid({
      inputs: form,
      values: entity,
      setErrors: (err: ErrorsObj) => {
        setErrors((prev: ErrorsObj) => mergeErrObjects(prev, err));
      },
    });
  }, []);

  const validate = useCallback(
    (
      value: Entity,
      propertyName: string,
      inputName: string,
      validation: ValidationProps,
      inputType?: InputType
    ) => {
      const err: ErrorsObj = {};
      const valid = validateInput(
        value,
        propertyName,
        validation,
        err,
        inputType
      );
      setErrors((prev: ErrorsObj) => {
        const newErr = valid
          ? _.omit(prev, inputName)
          : {
              ...prev,
              [inputName]: err[propertyName],
            };
        return {
          ...newErr,
        };
      });

      return valid;
    },
    []
  );

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      if (!formValid) {
        setShowErrors(true);
        return;
      }

      const submitResponse = await handleSubmit?.();
      setSucceededSubmit(submitResponse ?? false);
    },
    [formValid, handleSubmit]
  );

  if (handleSubmit) {
    return (
      <ValidationContext.Provider
        value={{
          formValid,
          errors,
          getErrorByInputName,
          validate,
          validateEntity,
          showErrors,
          setShowErrors,
          formId,
        }}
      >
        <form action="" onSubmit={onSubmit} id={formId}>
          {children}
        </form>
      </ValidationContext.Provider>
    );
  }

  return (
    <ValidationContext.Provider
      value={{
        formValid,
        errors,
        getErrorByInputName,
        validate,
        validateEntity,
        showErrors,
        setShowErrors,
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidation = (): ValidationContextProps =>
  useContext(ValidationContext) || defaultValues;
