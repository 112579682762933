import { Box } from "@mui/material";
import { LegalDocumentFactory } from "components/organisms/LegalDocumentFactory";
import { LegalDocumentsTypes } from "enums/legaldocuments.enum";
import { LegalDocument } from "interfaces/legaldocuments.interface";
import React, { useCallback, useEffect, useMemo, useRef } from "react";

interface Props {
  legalDocumentType: LegalDocumentsTypes;
}

const PRIVACY_POLICY: LegalDocument = {
  title: "Aviso de privacidad integral",
  sections: [
    {
      subtitle: "Introducción",
      paragraphs: [
        {
          text: "En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante, la “Ley”) y su Reglamento, se presenta el Aviso de Privacidad Integral (en adelante, el “Aviso”) que está dirigido al (a los) Usuario (s) (en lo sucesivo, el “Titular”) de los servicios que brinda MAPSY (en adelante, indistintamente “Mapsy” o “Responsable”) por medio de una plataforma digital (la “Plataforma”), accesible por dispositivos móviles a través de aplicaciones informáticas, por computadoras de escritorio y a través de navegadores de información por medio del dominio de internet: mapsy.com.mx  en los Estados Unidos Mexicanos (“México”).",
        },
        {
          text: "La información recabada (en lo sucesivo, los “Datos Personales”) podrá ser recopilada al momento en que el Titular consienta el presente Aviso.",
        },
        {
          text: "Asimismo, se le informa al Titular que todos los Datos Personales serán administrados de conformidad con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad contenidos en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.",
        },
        {
          text: "En ese tenor, el Titular será en todo momento responsable de los Datos Personales que brinde a la Plataforma.",
        },
      ],
    },
    {
      subtitle: "Definiciones",
      paragraphs: [
        {
          text: "Adicionalmente a los términos definidos a lo largo del presente Aviso, los términos que aparezcan con la primera letra en mayúscula, dentro del presente Aviso, tendrán los significados que a continuación se les asignan:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="A">{children}</ol>,
            bullets: [
              {
                label: "Aviso",
                body: "Se refiere al presente Aviso de Privacidad Integral, ya sea expedido en documento físico, electrónico o en cualquier otro formato generado por el Responsable que es puesto a disposición del Titular, previo al tratamiento de sus datos personales a fin de cumplir con el principio de información.",
              },
              {
                label: "Base de datos",
                body: "Es el conjunto ordenado de datos personales referentes a una persona identificada o identificable.",
              },
              {
                label: "Cómputo en la Nube",
                body: "Modelo de provisión externa de servicios de cómputo bajo demanda, que implica el suministro de infraestructura, plataforma o software, que se distribuyen de modo flexible, mediante procedimientos de virtualización, en recursos compartidos dinámicamente.",
              },
              {
                label: "Cookies",
                body: "Archivo de datos que se almacena en el disco duro del equipo de cómputo o del dispositivo de comunicaciones electrónicas de un Usuario al navegar en un sitio de internet específico, el cual permite intercambiar información de estado entre dicho sitio y el navegador del Usuario. La información de estado puede revelar medios de identificación de sesión, autenticación o preferencias del Usuario, así como cualquier dato almacenado por el navegador respecto al sitio de internet.",
              },
              {
                label: "Datos personales",
                body: "Cualquier información concerniente a una persona física identificada o identificable.",
              },
              {
                label: "Datos personales sensibles",
                body: "Aquellos Datos Personales que afecten a la esfera más íntima de su Titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquéllos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas o preferencia sexual.",
              },
              {
                label: "Derechos “ARCO”",
                body: "Los derechos que tiene el Titular frente al Responsable, con relación a sus Datos Personales, que incluyen lo siguiente: el derecho de acceder a sus Datos Personales y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o cancelarlos cuando considere que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al Tratamiento de los mismos para fines específicos. Al respecto, se abundará en la sección XI del presente Aviso.",
              },
              {
                label: "Disociación",
                body: "Es el procedimiento mediante el cual los Datos Personales no pueden asociarse al Titular ni permitir, por su estructura, contenido o grado de desagregación, la identificación del mismo.",
              },
              {
                label: "Encargado",
                body: "La persona física o moral que sola o conjuntamente con otras trate Datos Personales por cuenta del Responsable.",
              },
              {
                label: "Lineamientos",
                body: "Lineamientos del Aviso de Privacidad publicados en el Diario Oficial de la Federación el 17 de enero de 2013.",
              },
              {
                label: "Reglamento",
                body: "El Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.",
              },
              {
                label: "Responsable",
                body: "Persona moral de carácter privado que lleva a cabo el tratamiento de Datos Personales, a saber Mapsy.",
              },
              {
                label: "Servicio",
                body: "Se refiere al servicio de acceso a la Plataforma que brinda Mapsy con la finalidad de que el Titular pueda acceder y registrarse como terapeuta y/o como consumidor.",
              },
              {
                label: "Tercero",
                body: "La persona física o moral, nacional o extranjera, distinta del titular o del Responsable de los Datos Personales.",
              },
              {
                label: "Titular",
                body: "La persona física a quien corresponden los Datos Personales.",
              },
              {
                label: "Tratamiento",
                body: "La obtención, uso, divulgación o almacenamiento de Datos Personales, por cualquier medio. El uso abarca cualquier acción de acceso, manejo, aprovechamiento, transferencia o disposición de los Datos Personales.",
              },
              {
                label: "Transferencia",
                body: "Toda comunicación de Datos Personales realizada a persona distinta del Responsable o Encargado del Tratamiento.",
              },
              {
                label: "Web Beacons",
                body: "Imagen visible u oculta insertada dentro de un sitio web o correo electrónico, que se utiliza para monitorear el comportamiento del usuario en estos medios. A través de éstos se puede obtener información como la dirección IP de origen, navegador utilizado, sistema operativo, momento en que se accedió a la página.",
              },
            ],
          },
        },
      ],
    },
    {
      subtitle: "Responsable de la información",
      paragraphs: [
        {
          text: "Para efectos del Aviso, así como para todos los efectos legales a que haya lugar, el Responsable será Mapsy con domicilio en Puccini 60 interior 4, Col. Vallejo, Alcaldía Gustavo A. Madero, C.P. 07870, Ciudad de México, quien llevará a cabo el Tratamiento de los Datos Personales.",
        },
        {
          text: "El Responsable podrá auxiliarse de uno o más Encargados, que actuarán a nombre y por cuenta del Responsable, para brindar un Tratamiento más eficiente a los Datos Personales. Los Encargados serán informados del debido tratamiento que deberán brindar a los Datos Personales así como del presente Aviso.",
        },
        {
          text: "El Responsable también podrá efectuar el Tratamiento de los Datos Personales utilizando el sistema de Cómputo en la Nube, por lo que el proveedor de tales servicios estará obligado a suscribir el Aviso y cumplir con los mecanismos de protección y las medidas de seguridad destinadas a proteger la información contenida en los Datos Personales, de conformidad con la Ley, el Reglamento y demás disposiciones legales aplicables.",
        },
      ],
    },
    {
      subtitle: "Información que se recabará al subscribir el aviso",
      paragraphs: [
        {
          text: "Este Aviso será puesto a disposición del Titular al momento en que el Usuario utilice la Plataforma de Mapsy. El Titular proporcionará la presente información, misma que se considera indispensable para estar en posibilidades de prestar el Servicio, al momento de contratar los Servicios de Mapsy y suscribir el presente Aviso, así como al momento de modificar la información contenida en su cuenta. Los Datos Personales que pueden ser recopilados serán los siguientes:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="A">{children}</ol>,
            bullets: [
              {
                body: "SI SE REGISTRA COMO CONSUMIDOR(CONSULTANTE O PACIENTE):",
                sublist: {
                  ParentComponent: ({ children }) => <ol>{children}</ol>,
                  bullets: [
                    {
                      body: "Datos de identificación;",
                    },
                    {
                      body: "Datos de contacto; y",
                    },
                    {
                      body: "Datos de historial clínico",
                    },
                  ],
                },
              },
              {
                body: "SI SE REGISTRA COMO TERAPEUTA:",
                sublist: {
                  ParentComponent: ({ children }) => <ol>{children}</ol>,
                  bullets: [
                    {
                      body: "Datos de identificación, incluyendo su imagen personal;",
                    },
                    {
                      body: "Datos laborales;",
                    },
                    {
                      body: "Datos de contacto;",
                    },
                    {
                      body: "Domicilio del establecimiento en el cual se prestarán los servicios terapéuticos;",
                    },
                    {
                      body: "Datos académicos;",
                    },
                    {
                      body: "Cualquier información que usted voluntariamente suba a su perfil, en el entendido que dicha información será tratada para los fines aquí informados, y podrá ser visible para cualquier persona que acceda a nuestro sitio web y/o haga uso de nuestros Servicios.",
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          text: "Asimismo, se le informa al Titular que, en caso de no contar con la información antes mencionada, el Responsable no estará en posibilidad de brindarle el Servicio y el uso de la Plataforma.",
        },
        {
          text: "Le informamos que en nuestra página de Internet y aplicación móvil utilizamos Cookies, Web Beacons y otras tecnologías a través de las cuales es posible monitorear su comportamiento como Usuario de Internet, brindarle un mejor servicio y experiencia de Usuario al navegar en nuestra página/plataforma, así como ofrecerle nuevos productos y servicios basados en sus preferencias.",
        },
        {
          text: "Los Datos Personales que obtenemos de estas tecnologías de rastreo son los siguientes: (i) horario de navegación; (ii) tiempo de navegación en nuestra página de Internet; (iii) secciones consultadas; y, (iv) páginas de Internet accedidas previo a la nuestra.",
        },
      ],
    },
    {
      subtitle: "Datos personales financieros",
      paragraphs: [
        {
          text: "El Responsable declara que los cobros se podrán realizar por medio de un procesador de pagos. Por lo tanto, Mapsy direccionará la información correspondiente al medio de pago que hayas designado directamente el Procesador de pagos. Los Procesadores de pagos captarán y procesarán de forma privada y restringida, los datos personales financieros o bancarios del Responsable que sean exclusivamente necesarios para realizar el cobro del Servicio por conducto de tarjeta bancaria o medios electrónicos de pago. Actualmente, Mapsy no almacena, ni mantiene ninguna clase de datos financieros, toda vez que utiliza los servicios de aceptación de pagos electrónicos brindados por los Agregadores Bancarios:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="A">{children}</ol>,
            bullets: [
              {
                body: "Stripe Payments México, S. de R.L. de C.V.",
              },
            ],
          },
        },
        {
          text: "Los Agregadores Bancarios aquí mencionados podrán ser modificados en cualquier momento por el Responsable. ",
        },
      ],
    },
    {
      subtitle: "Datos personales sensibles",
      paragraphs: [
        {
          text: "Se consideran Datos Personales Sensibles aquellos Datos Personales que afecten a la esfera más íntima de su Titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.",
        },
        {
          text: "En virtud de lo anterior, Mapsy tratará datos personales sensibles relativo a su historial médico que son necesarios para prestar nuestros Servicios.",
        },
      ],
    },
    {
      subtitle: "Información que se recabará al utilizar el servicio",
      paragraphs: [
        {
          text: "Una vez que el Titular haya solicitado la prestación del Servicio y que haya aceptado el presente Aviso, el Responsable podrá recopilar Datos Personales distintos a los que se proporcionaron durante la creación y/o modificación de la cuenta. Los siguientes Datos Personales podrán ser recopilados por distintos medios cuando el Titular utilice el Servicio:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="A">{children}</ol>,
            bullets: [
              {
                body: "Datos de identificación, incluyendo su imagen personal y el domicilio del establecimiento en el cual se prestarán los servicios terapéuticos;",
              },
              {
                body: "Datos de contacto;",
              },
              {
                body: "Datos laborales;",
              },
              {
                body: "Datos académicos;",
              },
              {
                body: "Datos patrimoniales; y",
              },
              {
                body: "Cualquier información que usted voluntariamente suba a su perfil, en el entendido de que dicha información será tratada para los fines aquí informados, y podrá ser visible para cualquier persona que acceda a nuestro sitio web y/o haga uso de nuestros Servicios.",
              },
            ],
          },
        },
      ],
    },
    {
      subtitle: "Finalidades primarias e indispensables del tratamiento",
      paragraphs: [
        {
          text: "Los Datos Personales que recopile el Responsable, ya sea de forma personal, de forma directa o de forma indirecta, serán utilizados para las siguientes finalidades primarias e indispensables:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="A">{children}</ol>,
            bullets: [
              {
                body: "Utilizar correctamente la Plataforma;",
              },
              {
                body: "Creación y gestión de su cuenta;",
              },
              {
                body: "Compartir la información incluida en su cuenta con los Terapeutas;",
              },
              {
                body: "Habilitar las consultas en línea;",
              },
              {
                body: "Copiar y guardar en su cuenta los datos relativos a sus citas anteriores y futuras;",
              },
              {
                body: "Informar al Titular respecto a cualquier inconveniente durante la prestación de los Servicios;",
              },
              {
                body: "Preguntas públicas dirigidas a los Terapeutas;",
              },
              {
                body: "Fines de identificación del Titular para la correcta prestación de los Servicios; y",
              },
              {
                body: "Para contacto con el Titular en caso de que haya existido alguna vulneración de seguridad ocurrida en cualquier fase del Tratamiento.",
              },
            ],
          },
        },
        {
          text: "El cumplimiento de las finalidades primarias del Tratamiento es necesario para la existencia, mantenimiento y cumplimiento de la relación jurídica entre el Responsable y el Titular.",
        },
      ],
    },
    {
      subtitle: "Finalidades secundarias del tratamiento",
      paragraphs: [
        {
          text: "Los Datos Personales que recopile el Responsable, ya sea de forma personal, de forma directa o de forma indirecta, serán utilizados para las siguientes finalidades secundarias:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="A">{children}</ol>,
            bullets: [
              {
                body: "Fines estadísticos, analíticos y de información para el Responsable.",
              },
              {
                body: "Para fines estadísticos y promocionales, mercadotécnicos, publicitarios y de investigación de mercado.",
              },
              {
                body: "Para contacto con el Usuario de manera esporádica y/o eventual, con el objeto de proporcionar información relacionada a los productos y/o servicios que brinda el Responsable, encuestas, así como otros productos con fines mercadotécnicos publicitarios o de prospección comercial.",
              },
              {
                body: "Para fines de marketing y analíticos para el desarrollo de nuevas funcionalidades.",
              },
            ],
          },
        },
        {
          text: "En caso de que no desee que sus Datos Personales sean tratados para alguna o todas las finalidades secundarias, desde este momento Usted nos puede comunicar lo anterior por conducto de los medios previstos en la Cláusula “XIV. De Contacto “.",
        },
      ],
    },
    {
      subtitle: "Transferencias",
      paragraphs: [
        {
          text: "Mapsy podrá transferir sus Datos Personales a las sociedades que forman parte del grupo societario de Mapsy, esto es, a las empresas matrices, controladoras, subsidiarias y filiales del Responsable.",
        },
        {
          text: "Con fundamento en el artículo 37 de la Ley, le informamos que no se requerirá recabar su consentimiento para efectuar Transferencias a sociedades controladoras, subsidiarias o afiliadas, o a una sociedad matriz o a cualquier sociedad del mismo grupo de Mapsy, toda vez que dichas empresas se rigen por los mismos estándares en materia de privacidad.",
        },
        {
          text: "De igual forma, Mapsy podrá transferir sus Datos Personales a los Terapeutas, cada vez que realice una cita y cada vez que decida compartir información con ellos.",
        },
        {
          text: "Si se registra en la Plataforma como Terapeuta, al bloquear una cita con un Consumidor, Mapsy transferirá sus datos personales a dicho Consumidor mediante el envío de un correo electrónico.",
        },
        {
          text: "En caso de que no desee que sus Datos Personales sean transferidos, desde este momento Usted nos puede comunicar lo anterior por conducto de los medios previstos en la Sección de Contacto, con el propósito de revocar su consentimiento respecto de cualquier Transferencia.",
        },
      ],
    },
    {
      subtitle: "Limitación al uso o divulgación de sus datos personales",
      paragraphs: [
        {
          text: "El Titular puede indicar al Responsable que desea limitar o dejar de recibir mensajes publicitarios, sean por teléfono fijo o teléfono celular, así como por correo electrónico.",
        },
        {
          text: "Asimismo, el Titular podrá limitar la finalidad del Tratamiento. Para ello, el Titular deberá enviar una solicitud para deshabilitar las finalidades secundarias, así como las finalidades que no son indispensables, los mensajes y/o los correos publicitarios a cualquiera de los medios de contacto señalados en la sección de Contacto.",
        },
        {
          text: "Para tal efecto, el Titular deberá enviar una solicitud con, al menos, los siguientes requisitos:",
          sublist: {
            ParentComponent: ({ children }) => <ol>{children}</ol>,
            bullets: [
              { body: "El nombre completo del Titular;" },
              {
                body: "Domicilio u otro medio para comunicarle la respuesta a su solicitud;",
              },
              {
                body: "Los documentos oficiales que acrediten la identidad del Titular; y",
              },
              {
                body: "Una descripción clara y precisa de si desea limitar o dejar de recibir mensajes publicitarios, sean por teléfono fijo o teléfono celular, así como por correo electrónico.",
              },
            ],
          },
        },
        {
          text: "Por otro lado, también podrá limitar o dejar de recibir mensajes publicitarios a través de realizar la inscripción del Titular en el Registro Público de Consumidores previsto en la Ley Federal de Protección al Consumidor, hoy Registro Público para Evitar la Publicidad y en el Registro Público de Usuarios (REUS) conforme a la Ley de Protección y Defensa al Usuario de Servicios Financieros, en términos del artículo 111 del Reglamento.",
        },
        {
          text: "Una vez recibida la solicitud, el Responsable contará con un plazo de 20 (veinte) días para responder al Titular de los Datos Personales, misma que será enviada a través del medio de contacto señalado por el Titular o, en su defecto al correo electrónico que se encuentre registrado.",
        },
        {
          text: "Por lo que hace al Registro Público para Evitar la Publicidad, 1 (un) día después de realizada la inscripción, ésta surtirá efectos a los 30 (treinta) días naturales siguientes a la fecha de su inscripción y, en cuanto al Registro Público de Usuarios comenzará a surtir efectos a partir de los 45 (cuarenta y cinco) días posteriores de haberse registrado y cuenta con una vigencia de 2 (dos) años, pasado este tiempo se dará de baja automáticamente.",
        },
      ],
    },
    {
      subtitle: "Revocación del consentimiento",
      paragraphs: [
        {
          text: "El Titular tiene derecho a revocar el Consentimiento que ha otorgado para la realización del Tratamiento, ya sea con relación a uno o a todos sus Datos Personales. Para tal efecto, el Titular deberá enviar una solicitud con, al menos, los siguientes requisitos:",
          sublist: {
            ParentComponent: ({ children }) => <ol>{children}</ol>,
            bullets: [
              { body: "El nombre completo del Titular;" },
              {
                body: "Domicilio u otro medio para comunicarle la respuesta a su solicitud;",
              },
              {
                body: "Los documentos oficiales que acrediten la identidad del Titular; y",
              },
              {
                body: "Una descripción clara y precisa de los Datos Personales respecto de los cuales ejercitará los derechos que les confiere la Ley; y",
              },
              {
                body: "Cualquier elemento o documento que facilite la localización de los Datos Personales de que se traten.",
              },
            ],
          },
        },
        {
          text: "Una vez recibida la solicitud, el Responsable contará con un plazo de 20 (veinte) días para responder al Titular de los Datos Personales, misma que será enviada a través del medio de contacto señalado por el Titular o, en su defecto al correo electrónico que se encuentre registrado.",
        },
        {
          text: "La revocación del consentimiento relacionada con Datos Personales que sean indispensables para que el Responsable le entregue un producto y/o le brinde un servicio al Titular implicará que el Responsable no estará obligado al cumplimiento respectivo, por estar impedido para ello.",
        },
      ],
    },
    {
      subtitle: "Ejercicio de derechos ARCO",
      paragraphs: [
        {
          text: "Los Derechos “ARCO” son un acrónimo que agrupa cuatro derechos que el Titular puede ejercer, en relación con sus Datos Personales:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="a">{children}</ol>,
            bullets: [
              {
                body: "Acceder a los Datos Personales que están sujetos a Tratamiento; es decir, solicitar al Responsable que le permita al Titular conocer qué datos tiene en su posesión.",
              },
              {
                body: "Rectificar cualquiera de los Datos Personales, sujetos a Tratamiento y en posesión del Responsable, que fueran inexactos, incompletos, erróneos y/o imprecisos.",
              },
              {
                body: "Cancelar cualquiera de los Datos Personales, en posesión del Responsable, que legítimamente considere que no están siendo utilizados para cumplir con alguna de las finalidades del Tratamiento y/o que están siendo usados en contravención de la Ley, el Reglamento o de cualquier otra disposición legal aplicable.",
              },
              {
                body: "Oponerse al Tratamiento que efectúa el Responsable, con causa justificada, siempre que el mismo pueda ocasionarle algún daño y/o perjuicio, o que es contrario a la Ley, el Reglamento o cualquier otra disposición legal aplicable.y",
              },
            ],
          },
        },
        {
          text: "Para tal efecto, el Titular deberá enviar una solicitud con, al menos, los siguientes requisitos:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="a">{children}</ol>,
            bullets: [
              { body: "El nombre completo del Titular;" },
              {
                body: "Domicilio u otro medio para comunicarle la respuesta a su solicitud;",
              },
              {
                body: "Los documentos oficiales que acrediten la identidad del Titular; y",
              },
              {
                body: "Una descripción clara y precisa de los Datos Personales respecto de los cuales ejercitará los derechos que les confiere la Ley;",
              },
              {
                body: "Cualquier elemento o documento que facilite la localización de los Datos Personales de que se traten; y",
              },
              {
                body: "La modalidad por la cual desea obtener respuesta, esto es, en documentos electrónicos, en copias simples o por cualquier medio.",
              },
            ],
          },
        },
        {
          text: "En caso de que el Titular realice una solicitud de rectificación, el Titular también deberá indicar las modificaciones a realizarse y aportar la documentación que sustente su petición.",
        },
        {
          text: "En el supuesto de que resulte procedente la solicitud, se comunicará la respuesta dentro de los 15 (quince) días siguientes a la fecha en se recibió la solicitud. Este plazo podrá ser ampliado previo aviso por una sola vez por un periodo igual, siempre y cuando sea justificada la ampliación.",
        },
        {
          text: "El Responsable podrá negar el acceso a los datos personales o realizar la rectificación o cancelación o conceder la oposición al Tratamiento de los mismos, en los siguientes supuestos:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="a">{children}</ol>,
            bullets: [
              {
                body: "Cuando el solicitante no sea el Titular de los Datos Personales, o el representante legal no esté debidamente acreditado para ello;",
              },
              {
                body: "Cuando en su base de datos, no se encuentren los Datos Personales del solicitante;",
              },
              {
                body: "Cuando se lesionen los derechos de un tercero;",
              },
              {
                body: "Cuando exista un impedimento legal, o la resolución de una autoridad competente, que restrinja el acceso a los datos personales, o no permita la rectificación, cancelación u oposición de los mismos; y",
              },
              {
                body: "Cuando la rectificación, cancelación u oposición haya sido previamente realizada.",
              },
            ],
          },
        },
        {
          text: "La negativa podrá ser parcial, en cuyo caso el Responsable efectuará el acceso, rectificación, cancelación u oposición requerida por el Titular.",
        },
        {
          text: "Sin perjuicio de lo anterior, Usted también tendrá derecho de solicitar a Mapsy que le remita un Formulario de Derechos ARCO que sea de fácil llenado, para efecto de ejercitar cualquiera de las prerrogativas aquí previstas.",
        },
      ],
    },
    {
      subtitle: "Notificación de cambios al aviso",
      paragraphs: [
        {
          text: "El Responsable podrá hacer modificaciones al presente Aviso, siempre y cuando se respeten las disposiciones legales aplicables. Cuando se realicen modificaciones al Aviso, el Responsable pondrá a disposición del Titular la información sobre las modificaciones, así como el nuevo Aviso de Privacidad mediante los siguientes medios y procedimientos:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="a">{children}</ol>,
            bullets: [
              {
                body: "A través de un correo electrónico a la cuenta que ha registrado el Usuario;",
              },
              {
                body: "Publicándolo en el portal web de Mapsy, o vía electrónica.",
              },
            ],
          },
        },
        {
          text: "El Responsable se reserva el derecho de cambiar y actualizar el presente Aviso, el Titular será responsable de mantener su cuenta de correo electrónico actualizada y de cerciorarse de que las notificaciones sean recibidas en un buzón que sea revisado constantemente. En caso de no recibir respuesta por parte del Titular en un plazo de setenta y dos (72) horas, se entenderá que acepta de conformidad con los cambios que pueda sufrir el presente Aviso.",
        },
        {
          text: "Le recomendamos verificar periódicamente la fecha de última modificación del presente documento.",
        },
      ],
    },
    {
      subtitle: "Contacto",
      paragraphs: [
        {
          text: "Si usted desea obtener más información, resolver una duda, ejercitar sus Derechos “ARCO” y/o aclarar cualquier cuestión sobre el ejercicio de cualquier derecho sobre sus Datos Personales, puede comunicarse o acudir directamente a la dirección señalada del Responsable. A continuación, le proporcionamos los datos de contacto:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="a">{children}</ol>,
            bullets: [
              {
                body: "Domicilio: Puccini 60 interior 4, Col. Vallejo, Alcaldía Gustavo A. Madero, C.P. 07870, Ciudad de México.",
              },
              {
                body: "Correo electrónico: support@mapsy.com.mx",
              },
            ],
          },
        },
      ],
    },
    {
      subtitle: "Política de cookies",
      paragraphs: [
        {
          text: "Mapsy utiliza varias tecnologías para mejorar la eficiencia de su sitio web, incluyendo su experiencia de navegación. Entre estas tecnologías se incluye el uso de cookies de terceros.  Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar.",
        },
        {
          text: "Esta información permite identificar y guardar sus preferencias personales para brindarle una mejor experiencia de navegación.  Le recordamos que usted puede deshabilitar o ajustar el uso de cookies siguiendo los procedimientos del navegador de Internet que utiliza.",
        },
      ],
    },
  ],
  lastModification: 1720558970000,
};

const TERMS_AND_CONDITIONS: LegalDocument = {
  title:
    "Términos y Condiciones para el acceso y uso de la plataforma de Mapsy",
  sections: [
    {
      subtitle: "Aceptación y alcance",
      paragraphs: [
        {
          text: "El presente documento establece los términos y condiciones generales (los “Términos”) que regirán entre Mapsy (en adelante, la “Compañía” o “Mapsy”) y, por otra parte, la persona que haya creado una Cuenta de Usuario en la plataforma digital e informática de la Compañía y haya aceptado expresamente este Contrato (en lo sucesivo, el “Usuario” y en conjunto con la Compañía, serán denominados como las “Partes”). Los presentes Términos regulan el uso de la plataforma digital e informática (la “Plataforma”) cuya titularidad corresponde a la Compañía, así como al acceso y uso del sitio de internet localizado en el dominio: mapsy.com.mx (el “Sitio Web”), su correspondiente aplicación móvil para teléfonos inteligentes (la “Aplicación Móvil”) y cualesquier portales, desarrollos y/o programas adicionales o auxiliares que, directa o indirectamente utilice Mapsy para prestar sus servicios (referidos todos ellos conjuntamente con la Plataforma, el Sitio Web y la Aplicación Móvil como los “Medios Digitales”) y establecen las disposiciones y estipulaciones que regirán al Servicio a celebrarse entre las Partes.  Este Contrato también podrá ser referido, indistintamente, como “los presentes Términos”, el “presente documento” o el “presente instrumento”.",
        },
        {
          text: "El Usuario manifiesta y declara expresamente que, previamente al uso de los Servicios, ha aceptado expresamente todas y cada una de las disposiciones contenidas y previstas en los presentes Términos, lo cual se verificará con la aceptación que realizará el Usuario al consentir expresamente este instrumento mediante firma electrónica, a través del llenado de casillas o botones de aceptación electrónicos habilitados en los Medios Digitales o bien, por medio de aplicaciones que permitan la impresión de una firma facsimilar, según lo anterior se encuentra reconocido en el artículo 89 y sucesivos del Código de Comercio, en correlación con el artículo 1803 fracción II del Código Civil Federal.",
        },
        {
          text: "Si el Usuario está contratando los Servicios desde otro país, éste se obliga expresamente a cumplir con la legislación aplicable en México.",
        },
        {
          text: "El uso de los Medios Digitales se entenderá como manifestación plena e indubitable de la voluntad y aceptación con los presentes Términos. Si el Usuario no está de acuerdo con los términos de uso y las políticas de privacidad de la Compañía, no deberá utilizar o solicitar los Servicios, ni hacer uso de los Medios Digitales.",
        },
        {
          text: "Para utilizar los Medios Digitales el Usuario deberá de contar con capacidad suficiente para contratar. Si un menor de edad necesita la asistencia de un Terapeuta, un adulto con la debida facultad legal deberá contratar nuestros Servicios en nombre del menor.",
        },
      ],
    },
    {
      subtitle: "Definiciones",
      paragraphs: [
        {
          text: "Para mejor claridad de lo expresado en el presente instrumento, utilizaremos términos definidos. Para efectos de este Contrato, los términos que aparezcan definidos en mayúsculas y que no hayan sido definidos de forma distinta en diversas secciones del presente documento, tendrán el significado que a continuación se les atribuye:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="A">{children}</ol>,
            bullets: [
              {
                label: "Consumidores",
                body: "Se refiere a las personas físicas que solicitan por conducto de los Medios Digitales los Servicios Terapéuticos que ofrecen los Terapeutas.",
              },
              {
                label: "Cuenta de Usuario",
                body: "Se refiere a la cuenta personal que deberá crear el Usuario en los Medios Digitales, para los propósitos especificados en el presente instrumento.",
              },
              {
                label: "México",
                body: "Se refiere a los Estados Unidos Mexicanos.",
              },
              {
                label: "Normativa Aplicable",
                body: "Se refiere a las leyes, reglamentos, decretos, acuerdos, circulares, lineamientos, disposiciones generales, y en general, a todas las normas vigentes relativas que resulten aplicables en el territorio de México.",
              },
              {
                label: "Servicios",
                body: "Se refiere al servicio de acceso a los Medios Digitales que brinda Mapsy con la finalidad de que los Consumidores contacten a los Terapeutas para que estos últimos le brinden los Servicios Terapéuticos.",
              },
              {
                label: "Terapeuta",
                body: "Se refiere al profesional en psicología que aparece en la Plataforma. Dichos profesionales pueden trabajar de forma independiente o para un Consultorio, pero en ningún caso están contratados ni trabajan para la Compañía.",
              },
            ],
          },
        },
      ],
    },
    {
      subtitle: "Registro en los medios digitales",
      paragraphs: [
        {
          text: "El Usuario declara que conoce el proceso de registro dentro de los Medios Digitales. El Usuario es el único responsable de la autenticidad de la cuenta asociada al acceso vinculado.",
        },
        {
          text: "Cuando se registre, el Usuario acepta proveer información exacta, veraz, completa y actualizada que se requiera para completar el proceso de registro, pudiendo la Compañía realizar controles que crea convenientes para verificar la autenticidad de la misma. La información de registro, el nombre de Usuario asignado o seleccionado, así como las contraseñas o claves de acceso son de responsabilidad exclusiva del Usuario.",
        },
        {
          text: "La Compañía se reserva el derecho a verificar su identidad o cualquier información facilitada por el Usuario y a suspender temporalmente o permanente su acceso a los Servicios o su cuenta si identificamos información falsa, engañosa o no autorizada o en el uso de los Servicios.",
        },
        {
          text: "La Compañía no se responsabiliza por cualquier daño como resultado de la pérdida o mal uso de la contraseña o clave de acceso por parte de terceros. El Usuario es el único responsable por ello.",
        },
        {
          text: "El registro del Usuario, así como el uso de los Medios Digitales con las credenciales otorgadas, es personal y no se puede transferir por ningún motivo a terceras personas.  En caso de olvido de la contraseña o de cualquier otra circunstancia que suponga un riesgo de acceso y/o utilización por parte de terceros no autorizados, el Usuario lo comunicará inmediatamente al Centro de Atención y Soporte de la Compañía, a fin de que ésta proceda inmediatamente al bloqueo y sustitución de la contraseña.  En todo caso, cualesquier operaciones realizadas antes de dicha comunicación se reputarán efectuadas por el Usuario.",
        },
      ],
    },
    {
      subtitle: "Del uso de los medio digitales",
      paragraphs: [
        {
          text: "El Usuario se obliga a: i) No utilizar bots y/o extractores de contenido para acceder, recopilar datos o interactuar con la Plataforma; ii) no eludir la seguridad que la Plataforma tiene instalada para su correcto funcionamiento; iii) no descifrar, descompilar, desmontar ni realizar ingeniería inversa de ningún software o hardware mientras se use la Plataforma; y iv) no tomar medidas que pueda dañar o afectar negativamente el rendimiento o el buen funcionamiento de la Plataforma; v) comunicarse de forma respetuosa  con los otros Usuarios con los que se pueda comunicar y con el centro de atención a Usuarios al utilizar la Plataforma; vi) no utilizar la Plataforma para la publicación, distribución, promoción o facilitación de materiales o contenido ilícito, discriminatorio y/o de carácter pornográfico; y vii) no utilizar la Plataforma para la publicación, tratamiento o divulgación de datos personales sensibles que puedan afectar la esfera íntima de cualquier individuo, que puedan originar discriminación o representar un riesgo grave para este.",
        },
        {
          text: "Queda terminantemente prohibido, y el Usuario se compromete a no modificar, manipular, transformar, copiar, distribuir, transmitir, emitir, mostrar, usar, reproducir, publicar, registrar, divulgar, extraer, imitar, crear aplicaciones o conceptos derivados, descompilar, descodificar, hacer ingeniería inversa, transferir, transmitir, alquilar, licenciar, sublicenciar, o llevar a cabo cualquier otra acción sobre el Sitio Web titularidad de Mapsy o de sus licenciantes, o sobre cualquier derecho sobre los mismos, salvo con autorización expresa y por escrito de Mapsy. En cualquier caso, se prohíbe eludir, suprimir o manipular el copyright, huellas digitales, marcas de agua y demás dispositivos técnicos de protección o datos identificativos de los derechos de Mapsy o de nuestros licenciantes sobre el Sitio Web, la Plataforma, sus contenidos o servicios.",
        },
      ],
    },
    {
      subtitle: "Contratación del servicio para usuarios",
      paragraphs: [
        {
          text: "El Usuario podrá registrarse en la Plataforma y contratar los Servicios como Terapeuta y/o como Consumidor, los cuales se regirán por lo siguiente:",
        },
        {
          text: "5.1. 	Si el Usuario se da de alta como Consumidor podrá acceder a los siguientes Servicios:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="a">{children}</ol>,
            bullets: [
              {
                label: "Directorio de terapeutas",
                body: "Se le proporcionará al Consumidor acceso a un directorio en el cual podrá realizar búsquedas de los perfiles de un gran número de Terapeutas.",
              },
              {
                label: "Blogs",
                body: "El Consumidor podrá acceder a los Blogs de los Terapeutas, en los cuales estos últimos publicarán artículos propios y el Consumidor podrá hacer reflexiones y preguntas respecto a los mismos.",
              },
              {
                label: "Almacenar información",
                body: "El Consumidor podrá guardar en su cuenta toda la información y documentos que le resulten útil almacenar en formato digital y podrá compartirlos con los Terapeutas que el Consumidor seleccione. Esta información se encuentra protegida a través de diferentes capas de encriptación con el fin de evitar el acceso no autorizado.",
              },
              {
                label: "Opiniones",
                body: "El Consumidor podrá escribir opiniones sobre sus experiencias con los Terapeutas con los que ha consultado. También podrá acceder y leer opiniones sobre los Terapeutas escritas por otras personas.",
              },
              {
                label: "Concertar una cita con un Terapeuta",
                body: "El Consumidor podrá reservar una cita a través de los Medios Digitales con cualquier Terapeuta que tenga disponibilidad. La información proporcionada en los Medios Digitales sobre los Terapeutas ha sido obtenida por la Compañía de registros y bases de datos públicos de acceso general, o ha sido enviada o confirmada por los propios Terapeutas. El Consumidor reconoce que la Compañía solicita a los Terapeutas que proporcionen información, precisa, completa y veraz y que dispongan de las licencias necesarias para prestar los Servicios Terapéuticos que ofrecen. No obstante lo anterior, la Compañía es un mero intermediario que facilita las interacciones entre los Consumidores y los Terapeutas. Por lo cual, la Compañía no representa ni garantiza las cualificaciones, la experiencia, la elegibilidad, la calidad del trabajo, las horas de trabajo, la información sobre precios o costos, la cobertura de seguro o cualquier otra información de los Terapeutas.",
              },
            ],
          },
        },
        {
          text: "Por lo anterior, en ningún caso la Compañía será responsable ante el Consumidor ni ante ninguna otra persona por cualquier decisión tomada o acción emprendida por el Consumidor basándose en la información antes mencionada. La Compañía no respalda ni recomienda en modo alguno a ningún Terapeuta, procedimiento, opinión u otra información que aparezca o a la que se pueda acceder a través de los Medios Digitales. Asimismo, el Consumidor será el único responsable de los honorarios, costos u otras obligaciones en las que incurra con un Terapeuta. Si después de haber reservado una cita y por cualquier motivo el Consumidor no se presenta a la cita y/o cancela la cita, el Consumidor será responsable de cualquier cargo y/o penalización que el Terapeuta pueda imponer.",
        },
        {
          text: "De igual forma, Mapsy declina toda responsabilidad en caso de cancelación por parte del Terapeuta tras la concertación de una cita por parte del Consumidor a través de los Medios Digitales.",
        },
        {
          text: "Al reservar una cita con un Terapeuta, el Consumidor recibirá un correo electrónico por parte de la Compañía en el cual vendrá el número telefónico y la dirección del lugar físico del Terapeuta en el cual se prestarán los Servicios Terapéuticos. Posteriormente el Terapeuta tendrá contacto directo con el Consumidor y este último podrá tener acceso a la ubicación exacta del espacio físico en el cual el Terapeuta prestará los Servicios Terapéuticos. De igual forma, se compartirá con el Terapeuta el número telefónico del Consumidor.",
        },
        {
          text: "5.2. 	Si se da de alta como Terapeuta las condiciones y los Servicios a los que tendrá acceso serán los siguientes:",
          sublist: {
            ParentComponent: ({ children }) => <ol type="a">{children}</ol>,
            bullets: [
              {
                label: "Bloqueo de espacios",
                body: "El Terapeuta podrá apartar el horario en el cual un Consumidor agendó una cita para contratar los Servicios Terapéuticos.",
              },
              {
                label: "Blogs",
                body: "El Terapeuta podrá publicar artículos propios, en los cuales los Consumidores podrán realizar preguntas y reflexiones respecto a los mismos.",
              },
              {
                body: "Cualquier Terapeuta que tenga un perfil dentro de los Medios Digitales deberá  contar con las cualificaciones de Terapeuta requeridas. Los Terapeutas no deberán proporcionar información falsa, inexacta o engañosa. La Compañía se reserva el derecho a verificar la identidad y las cualificaciones del Terapeuta y a suspender o cancelar los perfiles de los Terapeutas si la Compañía sospecha razonablemente que el Terapeuta no tiene las cualificaciones requeridas o ha proporcionado información falsa, inexacta o engañosa.",
              },
              {
                body: "El Terapeuta podrá darse de alta bajo los siguientes perfiles: 1.- Perfil Free., 2.- Perfil Premium. 3.- Perfil Pro. Se hace del conocimiento de los Terapeutas que actualmente solo se encuentra en disponibilidad la alta bajo el Perfil Free, sin embargo la Compañía podrá activar el Perfil Premium y el Perfil Pro en cualquier momento y los presentes Términos serán modificados para desglosar las características de dichos perfiles.",
              },
              {
                body: "Si el Terapeuta ha optado por darse de alta bajo el Perfil Free, la Compañía le ofrecerá las siguientes funcionalidades: i) la capacidad de publicar información básica sobre su persona, junto con la posibilidad de publicar una foto; ii) Recibir solicitudes de citas de los Consumidores; iii) Recibir notificaciones por correo electrónico; iv) Acceso a una agenda de citas dentro de la aplicación; v) Selección de 5 áreas de atención; vi) la capacidad de modificar o rectificar de forma independiente la información dentro del Perfil Free. El Terapeuta reconoce que para el correcto funcionamiento del Perfil Free, se requiere que publique la foto en su perfil. De igual manera, se hace del conocimiento del Terapeuta que al momento de bloquear una cita con un determinado Consumidor, Mapsy le enviará al Consumidor un correo electrónico con los datos de localización del lugar físico en el cual se prestarán los Servicios Terapéuticos, así como el número telefónico que el Terapeuta haya registrado en los Medios Digitales para la creación de su perfil.",
              },
            ],
          },
        },
      ],
    },
    {
      subtitle: "Requisitos para los perfiles de los terapeutas",
      paragraphs: [
        {
          text: "Para crear cualquiera de los perfiles disponibles para los Terapeutas, Mapsy requiere de cierta información básica sobre los Terapeutas. Esta información puede incluir el título, número de licencia profesional, el nombre, los apellidos, la dirección de la consulta y el tipo de servicio que prestan los Terapeutas.",
        },
        {
          text: "La información que los Terapeutas brindan sobre su formación y las cualificaciones deben ser exactas y estar actualizadas.",
        },
        {
          text: "Mapsy no garantiza una posición específica en las clasificaciones de los motores de búsqueda; cada Terapeuta puede tener solamente un perfil, por lo cual los perfiles duplicados no están permitidos y serán eliminados.",
        },
        {
          text: "Los campos del perfil tienen requisitos específicos y la información debe coincidir con la finalidad prevista en cada campo. La Compañía se reserva el derecho a rechazar o modificar la información que no sea relevante para un campo determinado.",
        },
        {
          text: "Los perfiles verificados son: (i) perfiles creados por los Terapeutas; y (ii) perfiles creados por la Compañía y cuya información es confirmada o modificada por un Terapeuta, después de que este haya confirmado su perfil.",
        },
        {
          text: "Los Terapeutas pueden modificar su perfil en cualquier momento, siempre y cuando se hayan registrado en los Medios Digitales y hayan creado una cuenta de perfil.",
        },
        {
          text: "Al publicar una foto en su cuenta de perfil, le concede a la Compañía una licencia no exclusiva para utilizar dicha foto con el fin de prestar los Servicios y publicarla en los Medios Digitales. La eliminación de su cuenta de perfil no revoca automáticamente este derecho.",
        },
        {
          text: "Al publicar respuestas a las preguntas, consultas u opiniones de los Consumidores en nuestros Medios Digitales, el Terapeuta concede a Mapsy una licencia mundial, no exclusiva, libre de royalties y transferible (con derecho a sublicenciar) para utilizar, reproducir, distribuir, crear trabajos derivados de, mostrar y ejecutar dicho contenido en relación con la prestación de los Servicios y la actividad de Mapsy, incluyendo, sin limitación con el fin de promover y redistribuir la totalidad o parte del servicio (y sus obras derivadas) en cualquier formato y por medio de cualquier canal de comunicación. Respecto a las respuestas a las preguntas, la licencia no es revocable.",
        },
        {
          text: "De igual forma, el Terapeuta reconoce que la Compañía compartirá el número telefónico y dirección del espacio físico en dónde se prestarán los Servicios Terapéuticos con los Consumidores que hayan agendado una cita con el Terapeuta en cuestión.",
        },
      ],
    },
    {
      subtitle: "Centro de atención y soporte",
      paragraphs: [
        {
          text: "Para realizar solicitudes, quejas o reportes relacionados con el Servicio, el Usuario deberá comunicarse al Centro de Atención y Soporte de la Compañía, dentro del plazo máximo de tres (3) días hábiles posteriores al acontecimiento que desee reportar o cuya aclaración solicite, salvo que en otra sección de este documento se ordene el reporte inmediato de una incidencia, siendo causal de incumplimiento de este instrumento la falta de reporte.",
        },
        {
          text: "Centro de vacaciones y soporte",
          sublist: {
            ParentComponent: ({ children }) => <ul>{children}</ul>,
            bullets: [
              {
                body: "Para aclaraciones sobre cobros y cualquier otro tema administrativo: por correo electrónico a support@mapsy.com.mx.",
              },
            ],
          },
        },
      ],
    },
    {
      subtitle: "Privacidad",
      paragraphs: [
        {
          text: "Al suscribir o aceptar los presentes Términos, el Usuario declara que ha leído y ha aceptado expresamente las políticas de privacidad de la Compañía, aplicables en México.",
        },
        {
          text: "Las cuales se encuentran disponibles en: https://mapsy.com.mx/terms-and-conditions",
        },
        {
          text: "En ese orden de ideas, la Compañía proporciona información clara y completa sobre la utilización y funcionamiento de las Cookies alojadas en su Web a través de la Política de Privacidad de Mapsy.",
        },
      ],
    },
    {
      subtitle: "Derechos de propiedad intelectual e industrial",
      paragraphs: [
        {
          text: "El Usuario reconoce y acepta que todos los derechos de propiedad intelectual e industrial sobre los Medios Digitales y sobre todos los contenidos y elementos insertos en estos (incluyendo sin limitar marcas, logotipos, figuras, nombres comerciales, textos, imágenes, gráficos, diseños, sonidos, bases de datos, software, diagramas de flujo, presentaciones, y elementos de audio y de video) pertenecen a la Compañía (la “Propiedad Intelectual”).",
        },
        {
          text: "Se encuentra estrictamente prohibida cualquier reproducción, distribución, transmisión, copia, alteración, explotación, publicación, difusión o disposición de la Propiedad Intelectual, sin el consentimiento previo y por escrito de un representante legal de la Compañía.",
        },
        {
          text: "Cualquier uso o explotación de la Propiedad Intelectual, que no sea autorizado por un representante legal de la Compañía previamente y por escrito, será motivo suficiente para cancelar la Cuenta de Usuario, sin perjuicio de las sanciones administrativas, civiles y penales a las que sea acreedor el infractor.",
        },
      ],
    },
    {
      subtitle: "Causas de fuerza mayor",
      paragraphs: [
        {
          text: "La Compañía no será responsable frente al Usuario, conforme al presente instrumento, en caso de que no pueda utilizarse la Plataforma o no se puedan prestar los Servicios por causas de fuerza mayor, incluyendo, pero sin estar limitado a, incendios, inundaciones, huracanes, tormentas, ciclones, terremotos, sismos, movimientos telúricos, huelgas, guerras, insurrecciones, motines, rebeliones, clausuras, ataques cibernéticos de hackers, casos fortuitos u otras causas de naturaleza similar o distinta.",
        },
      ],
    },
    {
      subtitle: "Exoneración de responsabilidad",
      paragraphs: [
        {
          text: "Los Usuarios no podrán derivar responsabilidad alguna a Mapsy por las siguientes cuestiones:",
          sublist: {
            ParentComponent: ({ children }) => <ol>{children}</ol>,
            bullets: [
              {
                body: "Por fallas de cualquier tipo en el funcionamiento los Medios Digitales;",
              },
              {
                body: "El uso de la Plataforma en dispositivos móviles que no cumplan los requerimientos técnicos;",
              },
              {
                body: "El uso de los Medios Digitales por un Usuario que no tenga la capacidad legal necesaria; y",
              },
              {
                body: "Por fallas de cualquier tipo en la pasarela de pagos, servicio que es brindado por un tercero ajeno a Mapsy.",
              },
            ],
          },
        },
      ],
    },
    {
      subtitle: "Cesión",
      paragraphs: [
        {
          text: "El Usuario no podrá ceder sus derechos y obligaciones asumidos al suscribir el presente Contrato, sin el consentimiento previo y por escrito de un representante legal de la Compañía. Por su parte, la Compañía podrá ceder los presentes Términos a cualquier tercero que le suceda en el ejercicio de su negocio o en que asuma la titularidad de la Plataforma o que se encuentre dentro de su mismo grupo empresarial.",
        },
      ],
    },
    {
      subtitle: "Notificaciones",
      paragraphs: [
        {
          text: "La Compañía podrá realizar las notificaciones oportunas a través de una publicación general difundida en los Medios Digitales o a través de un aviso dirigido a la dirección de correo electrónico facilitada por el Usuario al registrar su Cuenta de Usuario.",
        },
      ],
    },
    {
      subtitle: "Modificaciones",
      paragraphs: [
        {
          text: "Los presentes Términos podrán ser modificados, adicionados o reformados por la Compañía en cualquier momento. El Usuario reconoce expresamente el derecho de la Compañía de modificar el presente Contrato, con el único requisito de que dichas modificaciones sean notificadas en términos de este Contrato al propio Usuario.  Salvo que la Compañía exprese lo contrario, cualesquier modificaciones al presente Contrato entrarán en vigor después de 5 (cinco) días naturales de la notificación que la Compañía hará llegar al Usuario en términos de la cláusula 13 de los presentes Términos.",
        },
        {
          text: "Se entenderá que el Usuario acepta las modificaciones efectuadas a los presentes Términos si éste solicita o disfruta de los Servicios en fecha posterior a que tales modificaciones entren en vigor. Si alguna parte o cláusula de estos Términos es o fuese declarada nula o sin efecto, se entenderá por no puesta, y las restantes cláusulas o partes conservarán su validez.",
        },
      ],
    },
    {
      subtitle: "Interpretación y subsistencia",
      paragraphs: [
        {
          text: "En caso de que cualquier término, condición, sección o disposición prevista en este Contrato sea considerada o pudiera considerarse nulo, inválido, ilegal, prohibido o inexigible en cualquier jurisdicción, dicho término, condición, sección o disposición deberá separarse del presente Contrato, sin que lo anterior implique la nulidad o la invalidez de los términos o condiciones restantes.  La Compañía deberá sustituir el término, condición, sección o disposición correspondiente, de conformidad con la normatividad aplicable.",
        },
      ],
    },
    {
      subtitle: "Legislación aplicable y jurisdicción competente",
      paragraphs: [
        {
          text: "Las Partes en este acto convienen de manera terminante que el presente Contrato será regido e interpretado de acuerdo con las leyes mercantiles de México y supletoriamente, de conformidad con el Código Civil Federal, por lo que las Partes renuncian, de manera expresa e irrevocable, a cualquier otra legislación que pudiera ser aplicable en virtud de sus domicilios presentes o futuros o por cualquier otra razón o concepto.",
        },
        {
          text: "Para cualquier controversia relacionada con la validez, la interpretación y el cumplimiento del presente Contrato, las Partes expresa e irrevocablemente acuerdan y consienten en someterse exclusivamente a la jurisdicción alternativa de los juzgados y tribunales competentes sitos en la Ciudad de México y en este acto se expresa e incondicionalmente renuncian, en los términos más amplios permitidos por la ley, a cualquier otro fuero que pudiera corresponderles por razón de sus domicilios actuales o futuros o por cualquier otra razón.",
        },
      ],
    },
  ],
  lastModification: 1720558970000,
};

export const LegalDocuments: React.FC<Props> = ({ legalDocumentType }) => {
  const currentDocument: LegalDocument = useMemo(
    () =>
      ({
        [LegalDocumentsTypes.PRIVACY_POLICY]: PRIVACY_POLICY,
        [LegalDocumentsTypes.TERMS_AND_CONDITIONS]: TERMS_AND_CONDITIONS,
      })[legalDocumentType],
    [legalDocumentType]
  );
  const boxRef = useRef<HTMLDivElement | null>(null);

  const executeScroll = useCallback(
    () =>
      boxRef.current &&
      window.scrollTo({
        behavior: "smooth",
        top: boxRef.current.offsetTop - 40,
      }),
    [boxRef]
  );

  useEffect(() => {
    executeScroll();
  }, [legalDocumentType]);

  return (
    <Box sx={{ py: 3 }} ref={boxRef}>
      <LegalDocumentFactory legalDocument={currentDocument} />
    </Box>
  );
};
