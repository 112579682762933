import { ReactNode } from "react";
import { Box, Container } from "@mui/material";
import logo from "../assets/img/logo_without_label.png";
import COLORS from "constants/colors";
import { CustomLink } from "components/atoms/Link";

/**
 * Responsive layout with mapsy logo.
 * @returns A responsive ReactNode wrapper with the custom white background color, a subcontainer flex centering the children components and with the Mapsy logo changing position depending on the width viewport.
 */
interface Props {
  children: ReactNode;
}

export const LayoutWithLogo: React.FC<Props> = ({ children }) => {
  return (
    <Container sx={{ backgroundColor: COLORS.WHITE, maxHeight: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: { md: "center" },
          flexDirection: {
            xs: "column",
          },
          gap: 1,
          mt: { md: "2rem", xs: "1rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: { md: "left", xs: "center" },
            ml: { md: "1rem" },
          }}
        >
          <CustomLink to="/">
            <img src={logo} alt="mapsy-logo" width={130} />
          </CustomLink>
        </Box>
        <Box sx={{ mt: { md: "-4rem" } }}>{children}</Box>
      </Box>
    </Container>
  );
};
