import {
  ChangeEvent,
  useCallback,
  useState,
} from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { SchoolGrade } from "@mapsy/shared";
import { FormInput, ProjectedProps } from "interfaces";
import _ from "lodash";
import { defaultGrade } from "constants/defaultUserValues";

export const GradesInput: React.FC<ProjectedProps> = ({
  handleChange,
  entity,
}) => {
  const { SchoolGradesForm } = useGetStartedForms();
  const [grades, setGrades] = useState<Partial<SchoolGrade>[]>(
    entity?.length ? entity : [defaultGrade]
  );
  const [focusedElement, setFocusedElement] = useState("");
  const debouncedChange = useCallback(_.debounce(handleChange, 250), []);
  const propertyChanged = useCallback(
    (
      index: number,
      propertyName: string,
      value: any,
      c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setFocusedElement(c.target.name);
      setGrades((_exp: Partial<SchoolGrade>[]) => {
        const newExp = [..._exp];
        newExp[index] = {
          ...newExp[index],
          [propertyName]: value,
        };

        debouncedChange("schoolGrades", newExp);
        return newExp;
      });
    },
    []
  );

  const handleAddRemove = useCallback((index: number) => {
    setGrades((prev: Partial<SchoolGrade>[]) => {
      const newEntry: Partial<SchoolGrade> = {
        ...defaultGrade,
      };

      let result;
      if (index === 0) {
        result = [...prev, newEntry];
      } else {
        result = prev.slice(); // Create a shallow copy of the array
        result.splice(index, 1); // Remove the element at index i
      }

      debouncedChange("schoolGrades", result);
      return result;
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          color: COLORS.BLUE_1,
          textAlign: "center",
        }}
      >
        Grados academicos
      </Typography>
      {grades.map((g, i) => (
        <Box
          key={`school-grade-${i}`}
          sx={{
            position: "relative",
            display: "flex",
            flexBasis: { xs: "100%", sm: "100%" },
            gap: 4,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {SchoolGradesForm.map((props: FormInput) => (
            <InputField
              key={`school-grade-${i}-${props.propertyName}-${_.get(g, props.propertyName, "")}`}
              backgroundMode="transparent"
              value={_.get(g, props.propertyName, "")}
              autoFocus={focusedElement === `schoolGrades.${i}.${props.propertyName}`}
              name={`schoolGrades.${i}.${props.propertyName}`}
              handleChange={(...props) => propertyChanged(i, ...props)}
              {...props}
              label=""
            />
          ))}

          <IconButton
            key={`icon-button-${i}`}
            disableRipple
            aria-label="add"
            size="large"
            sx={{
              position: "absolute",
              right: "-6%",
              p: 0,
              top: "20%",
              backgroundColor: i === 0 ? COLORS.BLUE_1 : COLORS.GREY,
              color: "white",
            }}
            onClick={() => handleAddRemove(i)}
          >
            {i === 0 ? <AddIcon /> : <RemoveIcon />}
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};
