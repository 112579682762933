import _ from "lodash";
import { useRef, useCallback, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { Location } from "@mapsy/shared";
import { Form } from "interfaces";
import COLORS from "constants/colors";
import { InputField } from "components/atoms/InputField";
import { useValidation } from "providers/FormProvider";

interface Props {
  index: number;
  location: Location;
  locationForm: {
    inputs: Form;
    onBasicPropertyChange: (
      propertyName: string,
      value: any,
      context: any
    ) => void;
  };
  onAddLocation: () => void;
  onRemoveLocation: (locationIx: number) => void;
}
export const LocationSection: React.FC<Props> = ({
  location,
  index,
  locationForm,
  onAddLocation,
  onRemoveLocation,
}) => {
  const { inputs, onBasicPropertyChange } = locationForm;
  const { validateEntity } = useValidation();

  const gridRef = useRef<HTMLDivElement | null>(null);

  const executeScroll = useCallback(
    () =>
      gridRef.current &&
      window.scrollTo({
        behavior: "smooth",
        top: gridRef.current.offsetTop - 40,
      }),
    [gridRef]
  );

  const handleChange = useCallback(
    (propertyName: string, value: any, context?: any) => {
      onBasicPropertyChange(propertyName, value, context);
    },
    []
  );

  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <Grid item xs={12} md={12} ref={gridRef}>
      <Grid container sx={{ justifyContent: "center", rowGap: 4 }}>
        <Grid
          item
          sx={{
            py: 1,
            borderBottom: `solid 3px ${COLORS.BLUE_1}`,
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 1,
          }}
          xs={12}
          md={10}
        >
          <Typography sx={{ fontSize: "1.5rem" }}>
            Consultorio {index + 1}
          </Typography>
          {index === 0 ? (
            <IconButton
              disableRipple
              aria-label="add"
              size="medium"
              sx={{
                backgroundColor: COLORS.BLUE_1,
                color: "white",
                ":hover": {
                  backgroundColor: COLORS.BLUE_2,
                  color: COLORS.BLUE_1,
                },
              }}
              onClick={() => onAddLocation()}
            >
              <AddIcon />
            </IconButton>
          ) : (
            <IconButton
              disableRipple
              aria-label="remove"
              size="medium"
              sx={{
                backgroundColor: COLORS.BLUE_1,
                color: "white",
                ":hover": {
                  backgroundColor: COLORS.BLUE_2,
                  color: COLORS.BLUE_1,
                },
              }}
              onClick={() => onRemoveLocation(index)}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </Grid>

        {inputs.map(({ gridSize, propertyName, label, ...rest }, i) => (
          <Grid
            item
            xs={12}
            md={10}
            sx={{ px: 1, my: 2 }}
            key={`row-input-${index}-${propertyName}-${i}`}
            {...gridSize}
          >
            <Typography
              sx={{
                color: COLORS.BLUE_1,
                textAlign: "left",
                fontSize: "0.8rem",
              }}
              variant="subtitle2"
            >
              {label}
            </Typography>
            <InputField
              backgroundMode="transparent"
              propertyName={propertyName}
              name={`locations.${index}.${propertyName}`}
              value={_.get(
                location as unknown as Record<string, string | number>,
                propertyName,
                ""
              )}
              handleChange={handleChange}
              {...rest}
              label=""
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
