import React, { ChangeEvent, useState } from "react";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { GetStartedProps } from "interfaces";
import { UserForm } from "../form/UserForm";
import { defaultGrade } from "constants/defaultUserValues";

export const Education: React.FC<GetStartedProps> = ({
  userInfo,
  changePage,
  showBackButton = false,
}) => {
  const { EducationForm } = useGetStartedForms();
  const [entity, setEntity] = useState({
    schoolGrades: userInfo.schoolGrades?.length
      ? userInfo.schoolGrades
      : [defaultGrade],
    professionalId: userInfo.professionalId || "",
    theory: userInfo.theory || "",
  });

  const propertyChanged = (
    propertyName: string,
    value: any,
    c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEntity((_entity) => ({
      ..._entity,
      [propertyName]: value,
    }));
  };

  return (
    <UserForm
      handleSubmit={() => changePage(entity, false)}
      handleBack={() => changePage(entity, true)}
      inputs={EducationForm}
      entity={entity}
      isLoading={false}
      onPropertyChanged={propertyChanged}
      showBackButton={showBackButton}
    />
  );
};
