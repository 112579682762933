import React, { ChangeEvent, useState } from "react";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { GetStartedProps } from "interfaces";
import { UserForm } from "../form/UserForm";
import { defaultProfessionalExperience } from "constants/defaultUserValues";

export const Experience: React.FC<GetStartedProps> = ({
  userInfo,
  changePage,
  showBackButton,
}) => {
  const { ExperienceForm } = useGetStartedForms();
  const [entity, setEntity] = useState({
    professionalExperience: userInfo.professionalExperience?.length
      ? userInfo.professionalExperience
      : [defaultProfessionalExperience],
    topicsId: userInfo.topicsId || [],
  });

  const propertyChanged = (
    propertyName: string,
    value: any,
    c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let _value = value;

    setEntity((_entity) => ({
      ..._entity,
      [propertyName]: _value,
    }));
  };

  return (
    <UserForm
      handleSubmit={() => changePage(entity, false)}
      handleBack={() => changePage(entity, true)}
      inputs={ExperienceForm}
      entity={entity}
      isLoading={false}
      onPropertyChanged={propertyChanged}
      showBackButton={showBackButton}
    />
  );
};
