export enum Parent {
  DELAYED_DEVELOPMENT,
  LEARNING_DISABILITIES,
  SCHIZOPHRENIA,
  PSYCHOSIS,
  BIPOLAR_DISORDER,
  DEPRESSION,
  ANXIETY,
  OBSESSIVE_COMPULSIVE,
  EATING_DISORDER,
  SLEEP_DISORDER,
  SEXUALITY_PROBLEMS,
  ADDICTIONS,
  SPORTS_PERFORMANCE,
  SOCIAL_SKILLS,
  BEHAVIORAL_PROBLEMS,
  VIOLENCE,
  ACADEMIC_PERFORMANCE,
  ADMINISTRATIVE_ISSUES,
  WORK_PROBLEMS,
  WELL_BEING,
  STRESS,
  ELIMINATION_DISORDER,
  NEUROCOGNITIVE_DISORDER,
  THERAPEUTIC_ADHERENCE,
  MOURNING,
  INVALID
}
