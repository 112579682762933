import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { ProfessionalExperience } from "@mapsy/shared";
import { FormInput, ProjectedProps } from "interfaces";
import _ from "lodash";

const defaultEntry = {
  institution: "",
  startYear: 2010,
  endYear: 2010,
  position: "",
};

export const ProfessionalExperienceInput: React.FC<ProjectedProps> = ({
  handleChange,
  entity,
}) => {
  const { ProfessionalExperienceForm } = useGetStartedForms();
  const [exp, setExp] = useState<Partial<ProfessionalExperience>[]>(
    entity?.length ? entity : [{ ...defaultEntry }]
  );
  const [focusedElement, setFocusedElement] = useState("");
  const debouncedChange = useCallback(_.debounce(handleChange, 250), []);
  const propertyChanged = useCallback(
    (
      index: number,
      propertyName: string,
      value: any,
      c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setFocusedElement(c.target.name);
      setExp((_exp: Partial<ProfessionalExperience>[]) => {
        const newExp = [..._exp];
        newExp[index] = {
          ..._.omit(newExp[index], "_id"),
          [propertyName]: value,
        };

        debouncedChange("professionalExperience", newExp);
        return newExp;
      });
    },
    []
  );

  const handleAddRemove = useCallback((index: number) => {
    setExp((prev: Partial<ProfessionalExperience>[]) => {
      const newEntry: Partial<ProfessionalExperience> = {
        ...{ ...defaultEntry },
      };

      let result;
      if (index === 0) {
        result = [...prev, newEntry];
      } else {
        result = prev.slice(); // Create a shallow copy of the array
        result.splice(index, 1); // Remove the element at index i
      }

      debouncedChange("professionalExperience", result);
      return result;
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {exp.map((exp: Partial<ProfessionalExperience>, i) => (
        <React.Fragment key={`professional-experience-${i}`}>
          <Typography
            variant="h6"
            sx={{
              color: COLORS.BLUE_1,
              textAlign: "left",
            }}
          >
            Experiencia {i + 1}
          </Typography>
          <Box
            sx={{
              position: "relative",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridTemplateRows: "1fr 1fr",
              gap: 2,
            }}
          >
            {ProfessionalExperienceForm.map((props: FormInput, j: number) => (
              <InputField
                key={`professional-experience-${i}-${props.propertyName}-${_.get(exp, props.propertyName, "")}`}
                backgroundMode="transparent"
                value={_.get(exp, props.propertyName, "")}
                autoFocus={
                  focusedElement ===
                  `professionalExperience.${i}.${props.propertyName}`
                }
                handleChange={(...props) => propertyChanged(i, ...props)}
                name={`professionalExperience.${i}.${props.propertyName}`}
                {...props}
                label=""
              />
            ))}

            <IconButton
              key={`icon-button-${i}`}
              disableRipple
              aria-label="add"
              size="large"
              sx={{
                position: "absolute",
                right: "-6%",
                p: 0,
                top: "20%",
                backgroundColor: i === 0 ? COLORS.BLUE_1 : COLORS.GREY,
                color: "white",
              }}
              onClick={() => handleAddRemove(i)}
            >
              {i === 0 ? <AddIcon /> : <RemoveIcon />}
            </IconButton>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};
