import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";

import { UserType } from "@mapsy/shared";

import { useAppDispatch } from "hooks";
import { useAuth } from "hooks/useAuthAPI";
import { useQuery } from "hooks/useQuery";
import { setTherapist } from "features/therapist/therapist.slice";
import {
  deleteSession,
  LOCAL_TOKEN_NAME,
  selectSessionState,
} from "features/session/session.slice";
import { Navbar } from "components/organisms/Navbar";

export const TherapistSessionProvider = () => {
  const nav = useNavigate();
  const query = useQuery();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { getLogout } = useAuth();
  const { getUserInfo, isLoading: isTherapistLoading } = useAuth();
  const { isLoggedIn, profileInfo } = useSelector(selectSessionState);

  useEffect(() => {
    const isTokenSaved = localStorage.getItem(LOCAL_TOKEN_NAME);
    const isLoggedWrongUserType =
      isLoggedIn && profileInfo && profileInfo?.type !== UserType.Therapist;

    if (!isTokenSaved || isLoggedWrongUserType) {
      nav(
        `/users/signin?type=therapist&redirectTo=${pathname}?${query.toParamsString()}`
      );
      dispatch(deleteSession());
      getLogout();
    }
  }, [isLoggedIn, profileInfo]);

  const fetchUserInfo = useCallback(
    async (id: string, userType: UserType) => {
      const data = await getUserInfo(id, userType);
      dispatch(setTherapist(data));
    },
    [profileInfo]
  );

  useEffect(() => {
    if (!profileInfo?.id || !profileInfo.type) {
      return;
    }
    fetchUserInfo(profileInfo.id, profileInfo.type);
  }, [profileInfo]);

  return (
    <Box sx={{ width: "100%" }}>
      <Navbar />
      {isTherapistLoading ? <LinearProgress /> : <Outlet />}
    </Box>
  );
};
