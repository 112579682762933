import React, { useMemo, useCallback } from "react";
import { Box, Grid } from "@mui/material";

import { Modality, OrderEnum, InputType, LoadingEnum } from "@mapsy/shared";
import { Form, FormInput } from "interfaces";
import { InputField } from "../atoms/InputField";
import { useModalities } from "hooks/catalogs/useModalities";
import { usePlaces } from "hooks/catalogs/usePlaces";
import { selectReasonState } from "features/reason/reasonSice";
import { useAppSelector } from "hooks";

interface Props {
  modalityFilter: Modality | "Todas";
  setModalityFilter: (arg: Modality | "Todas") => void;
  setLocationCommunity: (arg: string) => void;
  locationCommunity?: string;
  priceOrder: OrderEnum;
  setPriceOrder: (value: React.SetStateAction<OrderEnum>) => void;
}
export const TherapistsFilters: React.FC<Props> = ({
  setModalityFilter,
  setLocationCommunity,
  modalityFilter,
  locationCommunity,
  priceOrder,
  setPriceOrder,
}) => {
  const { therapistsLoading } = useAppSelector(selectReasonState);
  const { modalitiesList, modalitiesLoading } = useModalities();
  const { placesList, placesLoading } = usePlaces();
  const isLoading = useMemo(
    () =>
      modalitiesLoading === LoadingEnum.pending ||
      placesLoading === LoadingEnum.pending ||
      therapistsLoading === LoadingEnum.pending,
    [modalitiesLoading, placesLoading, therapistsLoading]
  );

  const handleChangePriceOrder = useCallback(() => {
    setPriceOrder((order) => {
      return order === OrderEnum.ASC ? OrderEnum.DESC : OrderEnum.ASC;
    });
  }, [setPriceOrder]);

  const handleSelectModality = useCallback(
    (_pName: string, value: any, _ctx: any) => {
      setModalityFilter(value as Modality | "Todas");
    },
    [setModalityFilter]
  );

  const handleSelectCity = useCallback(
    (_pName: string, value: any, _ctx: any) => {
      setLocationCommunity(value);
    },
    [setLocationCommunity]
  );

  const inputs: Form = useMemo(
    () => [
      {
        propertyName: "price",
        label: "Precio ",
        inputType: InputType.Select,
        value: priceOrder,
        required: false,
        autoFocus: false,
        handleChange: handleChangePriceOrder,
        menuItems: [
          {
            label: "Mayor a menor",
            value: OrderEnum.DESC,
          },
          {
            label: "Menor a mayor",
            value: OrderEnum.ASC,
          },
        ],
        gridSize: {
          md: 3,
          sm: 4,
          xs: 12,
        },
      },
      {
        propertyName: "modality",
        label: "Modalidad de consulta",
        inputType: InputType.Select,
        value: modalityFilter,
        required: false,
        autoFocus: false,
        handleChange: handleSelectModality,
        menuItems: [{ label: "Todas", value: "Todas" }, ...modalitiesList],
        gridSize: {
          md: 3,
          sm: 4,
          xs: 12,
        },
      },
      {
        propertyName: "city",
        label: "Municipio/Alcaldía",
        inputType: InputType.Autocomplete,
        placeholder: "Municipio/Alcaldía",
        value: locationCommunity,
        required: false,
        autoFocus: false,
        handleChange: handleSelectCity,
        options: placesList,
        gridSize: {
          md: 3,
          sm: 4,
          xs: 12,
        },
      },
    ],
    [
      priceOrder,
      modalityFilter,
      locationCommunity,
      modalitiesList,
      placesList,
      handleSelectCity,
      handleChangePriceOrder,
      handleSelectModality,
    ]
  );

  return (
    <Box>
      <Grid container sx={{ justifyContent: "right", width: "100%" }}>
        {inputs.map(({ gridSize, sx = {}, ...rest }: FormInput, i: number) => (
          <Grid
            {...gridSize}
            item
            key={`input-filter-${rest.propertyName}-${i}`}
            p={1}
          >
            <InputField
              {...rest}
              disabled={isLoading}
              sx={{
                ...sx,
                ".MuiInputBase-input": {
                  p: "1rem",
                  "&.MuiAutocomplete-input": {
                    p: "12px !important"
                  },
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
