import { Action } from "@mapsy/shared";
import COMPONENTS from "constants/componentNames";
import { useAnalytics } from "hooks/useAnalytics";
import { ReactNode, useCallback } from "react";
import { Link, LinkProps } from "react-router-dom";

interface Props extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  children: ReactNode;
  to: string;
  underline?: boolean;
  hoverEffect?: boolean;
  style?: React.CSSProperties | undefined;
}

export const CustomLink: React.FC<Props> = ({
  children,
  to,
  underline = false,
  hoverEffect = true,
  style,
  ...restProps
}) => {
  const { createAnalytic } = useAnalytics();

  const handleClick = useCallback(() => {
    createAnalytic({
      action: Action.CLICK,
      componentName: COMPONENTS.CUSTOM_LINK,
      data: {
        to,
        children,
      },
    });
  }, [children, to]);

  return (
    <Link
      onClick={handleClick}
      to={to}
      className={hoverEffect ? "text-hover" : undefined}
      style={{
        textDecoration: underline ? "underline" : "none",
        ...style,
      }}
      {...restProps}
    >
      {children}
    </Link>
  );
};
