import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import { Navbar } from "components/organisms/Navbar";
import { useAppDispatch } from "hooks";
import { Footer } from "components/organisms/Footer";

export const PublicLayout = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Navbar />
      <Outlet />
      <Footer />
    </Box>
  );
};
