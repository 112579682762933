declare global {
  interface Window {
    env: any;
  }
}
type EnvType = {
  REACT_APP_LOGGER: "all" | "info" | "warn" | "error";
  REACT_APP_MAX_LIMIT_THERAPISTS: number;
  REACT_APP_SOCKET: string;
};

export const env: EnvType = { ...process.env, ...window["env"] };
