import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import { LoadingEnum, UserType } from "@mapsy/shared";

import { useAppDispatch } from "hooks";
import { useAuth } from "hooks/useAuthAPI";
import { useQuery } from "hooks/useQuery";
import {
  deleteSession,
  LOCAL_TOKEN_NAME,
  selectSessionState,
} from "features/session/session.slice";
import { Navbar } from "components/organisms/Navbar";

export const PatientSessionProvider = () => {
  const nav = useNavigate();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { getLogout } = useAuth();
  const { isLoggedIn, profileInfo } = useSelector(selectSessionState);

  useEffect(() => {
    const isTokenSaved = localStorage.getItem(LOCAL_TOKEN_NAME);
    const isLoggedWrongUserType =
      isLoggedIn && profileInfo && profileInfo?.type !== UserType.Patient;

    if (!isTokenSaved || isLoggedWrongUserType) {
      nav(
        `/users/signin?type=patient&redirectTo=${pathname}?${query.toParamsString()}`
      );
      dispatch(deleteSession());
      getLogout();
    }
  }, [isLoggedIn, profileInfo]);

  return (
    <Box sx={{ width: "100%" }}>
      <Navbar />
      <Outlet />
    </Box>
  );
};
