import _ from "lodash";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import COLORS from "constants/colors";
import COMPONENTS from "constants/componentNames";
import endpointGenerator from "classes/endpointGenerator";
import fb from "assets/svg/fb.svg";
import gmail from "assets/svg/gmail-logo.svg";
import ig from "assets/svg/ig.svg";

import { CustomButton } from "components/atoms/Button";
import { CustomLink } from "components/atoms/Link";
import { Form, FormInput } from "interfaces";
import { InputField } from "components/atoms/InputField";
import { Action, InputType, StylesEnum } from "@mapsy/shared";
import { useAxios } from "hooks/useAxios";
import { ValidationProvider } from "providers/FormProvider";
import { useAnalytics } from "hooks/useAnalytics";

interface ContactFormProps {
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
}

const defaultValues: ContactFormProps = {
  name: "",
  email: "",
  phoneNumber: "",
  message: "",
};

export const Contact = () => {
  const [values, setValues] = useState<ContactFormProps>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const { postData, errorMsg } = useAxios();
  const { createAnalytic } = useAnalytics();
  const [hasSucceeded, setHasSucceeded] = useState(false);

  const handleChange = useCallback(
    (
      propertyName: string,
      value: any,
      c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValues((_values) => ({ ..._values, [propertyName]: value }));
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    const endpoint = endpointGenerator.EmailAPI.suggestion({
      email: values.email,
      name: values.name,
      phoneNumber: values.phoneNumber,
    });
    const response = await postData(endpoint, { message: values.message });
    setIsLoading(false);

    createAnalytic({
      action: Action.SUBMIT,
      componentName: COMPONENTS.CONTACT,
      data: { values, errorMsg },
    });

    if (response) {
      setHasSucceeded(true);
      return true;
    }
  }, [values, errorMsg]);

  const inputs: Form = useMemo(
    () => [
      {
        propertyName: "name",
        label: "Nombre",
        inputType: InputType.Text,
        textFieldProps: {
          size: "medium",
        },
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 60,
        },
      },
      {
        propertyName: "email",
        label: "Correo electrónico",
        inputType: InputType.Text,
        textFieldProps: {
          size: "medium",
        },
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 40,
        },
      },
      {
        propertyName: "phoneNumber",
        label: "Número telefónico",
        inputType: InputType.Text,
        textFieldProps: {
          size: "medium",
        },
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 10,
          maxLength: 10,
        },
      },
      {
        propertyName: "message",
        label: "Déjanos tu mensaje",
        inputType: InputType.Textarea,
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 10,
          maxLength: 300,
        },
        textareaProps: {
          style: {
            borderRadius: "10px",
            color: "#000",
          },
        },
      },
    ],
    []
  );

  return (
    <Container maxWidth="lg" component="section">
      <Box sx={{ my: 12 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              borderBottom: `solid 3px ${COLORS.BLUE_1}`,
              my: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{ textAlign: { xs: "center", sm: "left" }, mb: 2 }}
            >
              Contacta con nosotros
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box maxWidth="md">
        <ValidationProvider handleSubmit={handleSubmit}>
          <Grid container sx={{ rowGap: 2 }}>
            {inputs.map(({ gridSize, ...rest }: FormInput, i: number) => (
              <Grid {...gridSize} item key={`input-${rest.propertyName}-${i}`}>
                <InputField
                  backgroundMode="no-border"
                  {...rest}
                  value={_.get(values, rest.propertyName, "")}
                  handleChange={handleChange}
                />
              </Grid>
            ))}
            {errorMsg && (
              <Grid item xs={12}>
                <Typography>{errorMsg}</Typography>
              </Grid>
            )}
            {hasSucceeded && (
              <Grid item xs={12}>
                <Typography>
                  Hemos recibido tu mensaje. En breve, nos comunicaremos contigo
                  lo antes posible.
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                my: 4,
              }}
            >
              <CustomButton
                customStyle={StylesEnum.primary}
                children={"Enviar"}
                disableRipple={true}
                isLoading={isLoading}
                disabled={!values.email || !values.message}
                hasSucceeded={hasSucceeded}
                sx={{
                  padding: "10px 12px",
                  borderRadius: "10px",
                }}
                type="submit"
              />
            </Grid>
          </Grid>
        </ValidationProvider>
      </Box>
      <Box sx={{ mt: { md: 8, xs: 4 } }}>
        <Grid container sx={{ justifyContent: "center", rowGap: 6 }}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{ textAlign: { xs: "center", sm: "left" }, mb: 2 }}
            >
              O contáctanos por nuestras redes sociales
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CustomLink
              to="https://www.facebook.com/profile.php?id=61550819325192&mibextid=ZbWKwL"
              target="_blank"
            >
              <img src={fb} alt="facebook" width={80} />
            </CustomLink>
            <Typography>Facebook</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CustomLink
              to="https://www.instagram.com/mapsy.mx?igsh=ZmsxMjlmY3o0cXg3"
              target="_blank"
            >
              <img src={ig} alt="instagram" width={80} />
            </CustomLink>
            <Typography>Instagram</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CustomLink
              to="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Necesito+ayuda+con+Mapsy&to=support@mapsy.com.mx&body=Hola,+mi+nombre+es+"
              target="_blank"
            >
              <img src={gmail} alt="tiktok" width={80} />
            </CustomLink>
            <Typography>Gmail</Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
