import {
  Box,
  BoxProps,
  Grid,
  List,
  ListItem,
  Typography,
  styled,
} from "@mui/material";
import Comments from "./TherapistRatings";
import COLORS from "constants/colors";
import { Therapist } from "@mapsy/shared";

interface Props {
  title: string;
  items: string[];
}

interface TherapistInfoProps {
  therapist: Therapist;
}

const StyledList = styled(List)(({ theme }) => ({
  "&.MuiList-root": {
    listStyleType: "disc",
    listStylePosition: "inside",
  },
  "& .MuiListItem-root": {
    paddingLeft: 0,
    display: "list-item",
  },
}));

const ListBox: React.FC<Props & BoxProps> = ({ title, items, ...rest }) => {
  const list = !items?.length ? (
    <>No hay informacion disponible</>
  ) : (
    <StyledList>
      {items.map((item, idx) => (
        <ListItem key={`${title}-${idx}`}>{item}</ListItem>
      ))}
    </StyledList>
  );
  return (
    <Box {...rest}>
      <Typography variant="subtitle2" sx={{ color: COLORS.BLUE_1 }}>
        {title}:
      </Typography>
      {list}
    </Box>
  );
};

export const TherapistInfo: React.FC<TherapistInfoProps> = ({
  therapist: { schoolGrades, professionalExperience, _id: therapistId },
}) => {
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        padding: 3,
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <ListBox
        title="Formación"
        items={schoolGrades.map(
          ({ level, institution, major }) =>
            `${level} en ${major} -  ${institution}`
        )}
      />

      <ListBox
        sx={{ mt: 4 }}
        title="Experiencia Profesional"
        items={professionalExperience?.map(
          ({ institution, position }) => `${position} - ${institution}`
        )}
      />

      <Comments therapistId={therapistId} />
    </Grid>
  );
};
