import { APINames } from "@mapsy/shared";
import BaseAPI from "./BaseEndpointAPI";

class PatientsAPI extends BaseAPI {
  constructor() {
    super(APINames.PATIENT);
  }

  changePasswordById(id: string) {
    return `${this.baseURL}/change-password/${id}`;
  }
}

const api = new PatientsAPI();

export default api;
