import { APINames } from "@mapsy/shared";
import BaseAPI from "./BaseEndpointAPI";

class AIAPI extends BaseAPI {
  constructor() {
    super(APINames.IA);
  }

  get assessPrompt() {
    return `${this.baseURL}/assess_prompt`;
  }
}

const iaAPI = new AIAPI();
export default iaAPI;
