import React from "react";

interface Props {
  index: number;
  selected: boolean;
  onClick: (score: number) => void;
  onHover: (score: number) => void;
}

export const BigStar: React.FC<Props> = ({
  selected,
  onClick,
  onHover,
  index,
}) => {
  return (
    <svg
      className={`star ${selected ? "star-selected" : ""}`}
      width="46"
      height="44"
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick(index + 1)}
      onMouseEnter={() => onHover(index + 1)}
    >
      <path
        d="M24.0185 1.81952L29.1518 12.7609C29.6437 13.8094 30.6389 14.5324 31.788 14.6763L43.7802 16.1772C44.7259 16.2956 45.1052 17.4628 44.4096 18.1145L35.59 26.3776C34.7449 27.1694 34.3647 28.3393 34.5831 29.4767L36.8613 41.3457C37.041 42.2818 36.0481 43.0032 35.2134 42.543L24.6293 36.7086C23.6151 36.1494 22.3849 36.1494 21.3707 36.7086L10.7866 42.543C9.9519 43.0032 8.959 42.2818 9.13867 41.3457L11.4169 29.4767C11.6353 28.3393 11.2551 27.1694 10.41 26.3776L1.59039 18.1145C0.894829 17.4628 1.27408 16.2956 2.21984 16.1772L14.212 14.6763C15.3611 14.5324 16.3563 13.8094 16.8482 12.7609L21.9815 1.81952C22.3864 0.956633 23.6136 0.956635 24.0185 1.81952Z"
        stroke="#FFC700"
        strokeWidth="2.25"
      />
    </svg>
  );
};
