import { Action } from "@mapsy/shared";
import { Box, Container, Typography } from "@mui/material";
import COLORS from "constants/colors";
import COMPONENTS from "constants/componentNames";
import { useAnalytics } from "hooks/useAnalytics";
import { LegalDocument, Sublist } from "interfaces/legaldocuments.interface";
import moment from "moment";
import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  legalDocument: LegalDocument;
}

const renderSublist = (sublist: Sublist) => (
  <sublist.ParentComponent>
    {sublist.bullets.map(({ label, body, sublist: subSublist }, k) => (
      <li
        key={`sublist-${label}-${k}`}
        style={{
          fontWeight: 500,
          fontSize: "1.4rem",
          marginTop: "1rem",
        }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: "1.4rem" }}>
          {label && `${label} :`}
          <Typography
            component={"span"}
            sx={{
              fontSize: "1.3rem",
              lineHeight: "2.6rem",
              my: 3,
            }}
          >
            {body}
          </Typography>
        </Typography>
        {subSublist && renderSublist(subSublist)}
      </li>
    ))}
  </sublist.ParentComponent>
);

export const LegalDocumentFactory: React.FC<Props> = ({ legalDocument }) => {
  const nav = useNavigate();
  const { createAnalytic } = useAnalytics();
  const { title, sections, lastModification } = legalDocument;

  const scrollToElementId = useCallback(
    (id: string) => {
      document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
      createAnalytic({
        action: Action.CLICK,
        componentName: COMPONENTS.LEGAL_DOCUMENT_FACTORY,
        data: {
          scrollTo: id,
        },
      });
    },
    [nav]
  );

  return (
    <Container maxWidth={"md"}>
      <Box
        sx={{
          width: "100%",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          backgroundColor: COLORS.WHITE,
          textAlign: "center",
          p: { md: 2, xs: 1 },
          my: { md: 2, xs: 1 },
        }}
      >
        <Typography
          sx={{ color: COLORS.BLUE_1, fontSize: "1.8rem", fontWeight: 500 }}
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{ color: COLORS.BLUE_1, fontSize: "1.3rem", fontWeight: 500 }}
        >
          Índice:
        </Typography>
      </Box>
      <Box
        sx={{
          my: { md: 2, xs: 1 },
        }}
      >
        <ol>
          {sections.map(({ subtitle }) =>
            subtitle ? (
              <li
                key={`ul-index-${subtitle}`}
                style={{ color: COLORS.BLUE_1, fontSize: "1.3rem" }}
              >
                <Typography
                  sx={{
                    color: COLORS.BLUE_1,
                    fontSize: "1.3rem",
                    cursor: "pointer",
                    textDecoration: "underline",
                    width: "min-content",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() =>
                    scrollToElementId(subtitle.replace(/\s/g, "%20"))
                  }
                >
                  {subtitle}
                </Typography>
              </li>
            ) : (
              <></>
            )
          )}
        </ol>
      </Box>
      <Box
        sx={{
          my: { md: 4, xs: 2 },
        }}
      >
        {sections.map(({ paragraphs, subtitle }, i) => (
          <Box key={`section-${i}-${subtitle}`}>
            {subtitle && (
              <Typography
                id={subtitle.replace(/\s/g, "%20")}
                sx={{
                  color: COLORS.BLUE_1,
                  fontSize: "1.6rem",
                  fontWeight: 600,
                  paddingLeft: 1,
                  my: 1,
                  userSelect: "none",
                }}
              >
                {i + 1}. {subtitle}
              </Typography>
            )}
            {paragraphs.map(({ text, sublist }, j) => (
              <Box key={`paragraphs-${j}`}>
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    lineHeight: "2.6rem",
                    my: 3,
                    userSelect: "none",
                  }}
                >
                  {text}
                </Typography>
                {sublist && renderSublist(sublist)}
              </Box>
            ))}
          </Box>
        ))}
        <Typography sx={{ fontWeight: 600, my: 3 }}>
          Última modificación:{" "}
          {moment(lastModification).format("DD [de] MMMM [del] YYYY")}
        </Typography>
      </Box>
    </Container>
  );
};
