import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  ErrorsObj,
  InputType,
  MailServiceResponse,
  StylesEnum,
  UserType,
} from "@mapsy/shared";
import { Container, Grid, Typography } from "@mui/material";
import endpointGenerator from "classes/endpointGenerator";
import { CustomButton } from "components/atoms/Button";
import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { useAxios } from "hooks/useAxios";
import { useQuery } from "hooks/useQuery";
import { Form, FormInput } from "interfaces";
import { useNavigate } from "react-router-dom";
import { areInputsValid } from "utils/areInputsValid";
import { SelectUserType } from "components/molecules/SelectUserType";
import { AlertModal } from "components/atoms/AlertModal";
import { AlertType } from "enums/alert.enum";
import { hashUserType } from "./SignInAndUp";

export const ForgotPassword = () => {
  const query = useQuery();
  const nav = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<ErrorsObj>();
  const { postData, isLoading, errorMsg } = useAxios();
  const [succeddedSent, setSucceddedSent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userType, setUserType] = useState<UserType | null>(null);
  const [userTypeModal, setSelectUserType] = useState(true);

  useEffect(() => {
    if (userType !== null) {
      setSelectUserType(false);
      return;
    }

    if (!query.has("type")) {
      setSelectUserType(true);
      return;
    }

    const type = query.get("type");

    if (!type || !hashUserType[type]) {
      setSelectUserType(true);
      return;
    }

    setUserType(hashUserType[type]);
  }, [query, userType]);

  const handleChange = useCallback((propertyName: string, value: string) => {
    setEmail(value);
  }, []);

  const inputs: Form = useMemo(
    () => [
      {
        propertyName: "email",
        label: "Correo electrónico",
        placeholder: "ejemplo@email.com",
        inputType: InputType.Text,
        required: true,
        textFieldProps: {
          type: "email",
        },
        value: email,
        handleChange,
        validation: {
          isRequired: true,
          minLength: 5,
          maxLength: 50,
        },
      },
    ],
    [email]
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      if (!userType) {
        setSelectUserType(true);
        return;
      }

      if (!areInputsValid({ inputs, setErrors, values: { email } })) {
        return;
      }
      const endpoint = endpointGenerator.AuthAPI.resetPassword(email, userType);
      const { response }: MailServiceResponse = await postData(endpoint);

      if (/OK/g.test(response)) {
        setSucceddedSent(true);
        setOpenModal(true);
      }
    },
    [userType, inputs, setErrors, email]
  );

  if (userTypeModal) {
    return <SelectUserType setUserType={setUserType} isWelcome={false} />;
  }

  return (
    <Container
      sx={{ display: "flex", justifyContent: "center", py: 5 }}
      maxWidth="lg"
    >
      <form action="" method="post" onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "2rem", mb: 1 }}>
              ¿Olvidaste tu contraseña?
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              borderBottom: `solid 3px ${COLORS.BLUE_1}`,
              my: 2,
            }}
            md={6}
            xs={12}
          ></Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "1.5rem", mb: 2 }}>
              Ingresa la dirección de correo electrónica vinculada con tu
              cuenta.
            </Typography>
            <Typography>
              Recibirás un correo para restaurar tu contraseña
            </Typography>
          </Grid>
          {inputs.map(({ propertyName, ...rest }: FormInput, i: number) => (
            <Grid
              item
              key={`input-${propertyName}-${i}`}
              xs={12}
              md={12}
              sx={{ my: 2 }}
            >
              <Grid container>
                <Grid item xs={12} md={6}>
                  <InputField
                    backgroundMode="transparent"
                    propertyName={propertyName}
                    {...rest}
                    label=""
                    helperText={errors && errors[propertyName]}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          {succeddedSent && (
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "1.5rem" }}>
                Revisa tu correo electrónico. Puedes cerrar esta página.
              </Typography>
            </Grid>
          )}
          {errorMsg && (
            <Grid item xs={12} md={12}>
              <Typography>{errorMsg}</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              justifyContent: "end",
              alignItems: "center",
              display: "flex",
              flexDirection: "row-reverse",
              gap: 3,
              my: 3,
            }}
          >
            <CustomButton
              customStyle={StylesEnum.primary}
              children={"Cambiar contraseña"}
              disableRipple={true}
              disabled={succeddedSent || Boolean(errorMsg)}
              isLoading={isLoading}
              sx={{
                borderRadius: "14px",
              }}
              type="submit"
            />
          </Grid>
        </Grid>
      </form>
      <AlertModal
        title="Email enviado"
        body="Enviamos un correo a tu correo registrado."
        type={AlertType.Success}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
};
