import _ from "lodash";
import { Box, Grid, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Patient, Therapist, UserType } from "@mapsy/shared";

import pic from "assets/img/user-pic.webp";
import { Form } from "interfaces";
import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { capitalizeName } from "utils/capitalize";
import { Link } from "react-router-dom";
import { ChangeProfilePicModal } from "components/atoms/ChangeProfilePicModal";
import { useCallback, useEffect, useState } from "react";

type Props = {
  title?: string;
  inputs: Form;
  onPropertyChanged: (propertyName: string, value: any, context: any) => void;
  includeProfilePic?: boolean;
  includePasswordChange?: boolean;
  separatorBorderAfterInputs?: boolean;
} & (
  | {
      userType: UserType.Patient;
      userData: Patient;
    }
  | {
      userType: UserType.Therapist;
      userData: Therapist;
    }
);

export const ProfileSection: React.FC<Props> = ({
  title,
  inputs,
  userData,
  onPropertyChanged,
  includeProfilePic = false,
  includePasswordChange = false,
  userType,
  separatorBorderAfterInputs,
}) => {
  const imageUrlByAccountStatus = useCallback(
    (defaultPic: any) =>
      userData.accountStatus.hasProfilePic
        ? `/api/file/profile_pic_${userData._id}`
        : defaultPic,
    [userData.accountStatus.hasProfilePic, userData._id]
  );
  const [changeProfilePic, setChangeProfilePic] = useState(false);
  const [imgUrl, setImgUrl] = useState(imageUrlByAccountStatus(pic));
  const [imgSrc, setImgSrc] = useState(`${imgUrl}?${new Date().getTime()}`);

  useEffect(() => {
    setImgSrc(`${imgUrl}?${new Date().getTime()}`);
  }, [setImgSrc, changeProfilePic]);

  return (
    <>
      {includeProfilePic && (
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              sx={{
                textAlign: "center",
                position: "relative",
                "&:hover > img": {
                  backgroundColor: "#B0BEC5",
                  opacity: 0.1,
                },
                "&:hover > .edit-icon-container": {
                  display: "flex",
                  cursor: "pointer",
                },
                "& > img": {
                  xs: { maxWidth: "160px", width: "auto" },
                  md: { maxWidth: "190px", width: "100%" },
                },
              }}
            >
              <img
                src={imgSrc}
                className="profile-image"
                alt={`Pic of ${userData._id}`}
              />
              <Box
                className="edit-icon-container"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "none",
                  alignItems: "center",
                  justifyContent: "center", // Optional, to add a semi-transparent background
                  borderRadius: 10,
                }}
                onClick={(e) => setChangeProfilePic(true)}
              >
                <EditIcon
                  className="edit-icon"
                  sx={{
                    color: COLORS.BLUE_1, // Adjust the color as needed
                    fontSize: "2rem", // Adjust the size as needed
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {userData.accountStatus.registrationComplete ? (
                <Typography sx={{ fontSize: "2rem" }}>
                  {capitalizeName([
                    userData.firstName,
                    userData.middleName,
                    userData.lastName,
                  ])}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "1.5rem" }}>
                  Una vez que{" "}
                  <Link to={"/get-started"}> completes tus datos</Link>, tu
                  nombre aparecerá aquí.
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sx={{
                borderBottom: `solid 3px ${COLORS.BLUE_1}`,
                my: 2,
              }}
              md={6}
              xs={12}
            ></Grid>
          </Grid>
        </Grid>
      )}

      {title && (
        <Grid
          item
          sx={{
            py: 1,
          }}
          xs={12}
        >
          <Typography sx={{ fontSize: "2rem", mb: 1 }}>{title}</Typography>
        </Grid>
      )}

      {inputs.map(({ gridSize = { sm: 12 }, propertyName, ...rest }, i) => (
        <Grid
          xs={12}
          id={`row-input-container-${propertyName}-${i}`}
          item
          key={`row-input-container-${propertyName}-${i}`}
          sx={{ px: 1, my: 1.5 }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontSize: "1.3rem" }}>{rest.label}:</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <InputField
                backgroundMode="transparent"
                propertyName={propertyName}
                value={_.get(
                  userData as unknown as Record<string, string | number>,
                  propertyName,
                  ""
                )}
                handleChange={onPropertyChanged}
                {...rest}
                label=""
                helperText=""
              />
            </Grid>
          </Grid>
        </Grid>
      ))}

      {includePasswordChange && (
        <Grid item xs={12} sx={{ my: 2, px: 1 }}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontSize: "1.3rem" }}>Contraseña:</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>
                <Link
                  to={`/${{ [UserType.Patient]: "patient", [UserType.Therapist]: "therapist" }[userType]}/change-password`}
                >
                  Cambiar contraseña
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {separatorBorderAfterInputs && (
        <Grid
          item
          sx={{
            borderBottom: `solid 3px ${COLORS.BLUE_1}`,
            my: 2,
          }}
          md={6}
          xs={12}
        ></Grid>
      )}

      <ChangeProfilePicModal
        open={changeProfilePic}
        onClose={(url: string | null) => {
          if (url) {
            setImgUrl(url);
          }
          setChangeProfilePic(false);
        }}
      />
    </>
  );
};
