export const capitalizeWord = (str: string) =>
  str.toLowerCase().replace(/^\w|\s\w/g, (l) => l.toUpperCase());

export const capitalizeName = (
  str?: string | Array<string | undefined>
): string => {
  if (!str || !str.length) {
    return "";
  }

  if (Array.isArray(str)) {
    return (
      capitalizeName(str[0]) +
      (str[1] ? " " : "") +
      capitalizeName(str.slice(1))
    );
  }

  return capitalizeWord(str);
};
