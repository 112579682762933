import React from "react";
import { IconsProps } from "interfaces";

export const CloudUpArrowIcon: React.FC<IconsProps> = ({
  width = 82,
  height = 82,
  fillColor = "#02587B",
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width={width} height={height} fill="none"/><path d="M96,208H72A56,56,0,1,1,85.92,97.74" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/><path d="M80,128a80,80,0,1,1,144,48" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/><polyline points="120 160 152 128 184 160" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/><line x1="152" y1="208" x2="152" y2="128" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/></svg>
  );
};
