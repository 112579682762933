import { ErrorsObj } from '@mapsy/shared';

export function isObject(value: any): value is Record<string, any> {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

export function isEmpty(value: any){
  return  value === undefined ||
          value === null ||
          (typeof value === "object" && Object.keys(value).length === 0) ||
          (typeof value === "string" && value.trim().length === 0)
}

export function mergeErrObjects(A: ErrorsObj, B: ErrorsObj) {
  const result: ErrorsObj = {};

  // Add keys that are present in both A and B
  for (const key in B) {
    if (A.hasOwnProperty(key)) {
      result[key] = A[key];
    }
  }

  // Add all keys from B, overwriting any existing keys
  for (const key in B) {
    result[key] = B[key];
  }

  return result;
}