import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import {
  Appointment,
  AppointmentStatus,
  AppointmentStatusSpanish,
  GetAllPagedResult,
  ModalitySpanish,
  OrderEnum,
  ProvidedServiceTypesSpanish,
  StylesEnum,
} from "@mapsy/shared";

import defaultUserPic from "assets/img/user-pic.webp";
import COLORS from "constants/colors";
import endpointGenerator from "classes/endpointGenerator";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";
import { Link, useNavigate } from "react-router-dom";
import { CustomButton } from "components/atoms/Button";

const MAX_APPOINTMENTS_PER_PAGE = 10;

const MyAppointmentsPatient = () => {
  const nav = useNavigate();
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const { getData, errorMsg, isLoading } = useAxios();
  const [appointmentsByPage, setAppointmentsByPage] = useState<
    Record<number, Appointment[]>
  >({});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(page);
  const [emptyAppointmentsList, setEmptyAppointmentsList] = useState(false);

  const handleClickShowReasonPage = useCallback(() => {
    nav("/reason");
  }, []);

  const fetchAppointments = useCallback(
    async (patientId: string, token: string) => {
      const endpoint = endpointGenerator.AppointmentAPI.byPatient(
        {
          orderBy: "date",
          order: OrderEnum.DESC,
          page,
          limit: MAX_APPOINTMENTS_PER_PAGE,
        },
        patientId
      );

      const data: GetAllPagedResult<Appointment> = await getData(
        endpoint,
        setToken(token)
      );

      if (!data?.results) {
        return;
      }

      setAppointmentsByPage((_appointments) => ({
        ..._appointments,
        [page]: data.results,
      }));

      setMaxPage(data.totalPages);
    },
    [page]
  );

  useEffect(() => {
    if (appointmentsByPage[1] && !appointmentsByPage[1].length) {
      setEmptyAppointmentsList(true);
    }
  }, [appointmentsByPage]);

  useEffect(() => {
    const patientId = profileInfo?.id;

    if (!patientId || !token) {
      return;
    }

    fetchAppointments(patientId, token);
  }, [page, profileInfo, token]);

  const handleChangePage = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (page >= maxPage) {
      return;
    }

    setPage(page + 1);
  }, [maxPage, page, isLoading]);

  return (
    <>
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Grid container sx={{ flexDirection: "column", gap: 3 }}>
            <Grid
              item
              md={8}
              xs={12}
              sx={{
                borderBottom: `solid 3px ${COLORS.BLUE_1}`,
                my: 2,
              }}
            >
              <Typography sx={{ fontSize: "2rem", mb: 1 }}>
                Mis citas
              </Typography>
            </Grid>
            {errorMsg && (
              <Grid xs={12}>
                <Typography
                  sx={{ fontSize: " 1.5rem", mb: 1, color: COLORS.TEXT_RED }}
                >
                  {errorMsg}
                </Typography>
              </Grid>
            )}
            {emptyAppointmentsList ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    padding: { xs: "20px 10px", md: "40px" },
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    border: `1px solid ${COLORS.BLUE_1}`,
                    boxShadow:
                      "0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)",
                    borderRadius: "24px",
                    gap: { md: 4, xs: 2 },
                    textAlign: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "1.4rem" }}>
                    Aún no has agendado una cita
                  </Typography>
                  <Typography>
                    Te invitamos a contarnos tu motivo de consulta y nuestra
                    Inteligencia Artificial lo analizará para sugerirte los
                    terapeutas más adecuados.
                  </Typography>
                  <CustomButton
                    customStyle={StylesEnum.primary}
                    children="Ingresar mi motivo de consulta"
                    onClick={handleClickShowReasonPage}
                  />
                </Box>
              </Grid>
            ) : (
              Object.values(appointmentsByPage)
                ?.flat()
                .map((appointment) => {
                  const {
                    _id,
                    therapist,
                    date,
                    providedService,
                    locationId,
                    appointmentStatus,
                    therapistId,
                    patientId,
                  } = appointment;
                  const location = therapist?.locations.find(
                    ({ _id }) => _id === locationId
                  );
                  const imageUrlByAccountStatus = therapist?.accountStatus
                    ?.hasProfilePic
                    ? `/api/file/profile_pic_${therapistId}`
                    : `${defaultUserPic}?${new Date().getTime()}`;

                  return (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        borderRadius: "10px",
                        border: `1px solid ${COLORS.BLUE_1}`,
                        flexWrap: "wrap",
                        minHeight: "170px",
                        p: 2,
                      }}
                      key={`card-appointment-${_id}`}
                    >
                      <Grid container>
                        <Grid
                          item
                          md={2}
                          xs={12}
                          sx={{
                            textAlign: "center",
                            "& > img": {
                              xs: { maxHeight: "160px", width: "auto" },
                              md: { maxWidth: "190px", width: "100%" },
                            },
                          }}
                        >
                          <img
                            className="profile-image"
                            src={imageUrlByAccountStatus}
                            alt={`Pic of ${therapistId}`}
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                          sx={{
                            px: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          {therapist?.firstName && therapist.lastName ? (
                            <Typography
                              sx={{ fontSize: "1.1rem", fontWeight: 600 }}
                            >
                              {therapist.firstName} {therapist.lastName}
                            </Typography>
                          ) : (
                            <Typography>Terapeuta no encontrado</Typography>
                          )}
                          {providedService && (
                            <>
                              <Typography>
                                {
                                  ProvidedServiceTypesSpanish[
                                    providedService.serviceType
                                  ]
                                }
                              </Typography>
                              <Typography>
                                {location?.modality !== undefined
                                  ? ModalitySpanish[location.modality]
                                  : "Sin modalidad"}{" "}
                                - ${providedService.price}{" "}
                                {providedService.currency}
                              </Typography>
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={12}
                          sx={{
                            px: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <Typography>
                            {moment(date).format("DD [de] MMMM [del] YYYY")}
                          </Typography>
                          <Typography>
                            {moment(date).format("HH:mm a")}
                          </Typography>
                          <Typography>
                            {appointmentStatus !== undefined
                              ? `Cita ${AppointmentStatusSpanish[
                                  appointmentStatus
                                ].toLocaleLowerCase()}`
                              : "Status no disponible"}
                          </Typography>
                        </Grid>
                        {appointmentStatus === AppointmentStatus.Done &&
                          patientId &&
                          therapistId &&
                          therapist && (
                            <>
                              <Grid item xs={12} md={8}></Grid>
                              <Grid item xs={12} md={4}>
                                <Link
                                  to={`/patient/review?patientId=${patientId}&therapistId=${therapistId}`}
                                >
                                  <Typography
                                    sx={{ fontWeight: 500, pl: { md: 2 } }}
                                  >
                                    Agregar opinión
                                  </Typography>
                                </Link>
                              </Grid>
                            </>
                          )}
                      </Grid>
                    </Grid>
                  );
                })
            )}
            {isLoading && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress />
              </Grid>
            )}
            {page < maxPage && (
              <Grid
                item
                xs={12}
                sx={{
                  my: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  onClick={handleChangePage}
                  sx={{
                    my: 1,
                    textAlign: "center",
                    color: COLORS.BLUE_1,
                    cursor: "pointer",
                    ":hover": {
                      color: COLORS.BLUE_2,
                    },
                  }}
                >
                  Mostrar más
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default MyAppointmentsPatient;
