import { LoadingEnum, OrderEnum } from "@mapsy/shared";
import {
  getTheoriesList,
  selectCatalogState,
} from "features/catalogs/catalogs.slice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";

export const useTheories = () => {
  const dispatch = useAppDispatch();
  const {
    theories: { list: theoriesList, loading: theoriesLoading },
  } = useAppSelector(selectCatalogState);

  useEffect(() => {
    if (theoriesLoading !== LoadingEnum.idle) {
      return;
    }

    dispatch(getTheoriesList({ order: OrderEnum.DESC, orderBy: "value" }));
  }, [theoriesLoading]);

  return {
    theoriesList,
    theoriesLoading,
  };
};
