import momentTz from "moment-timezone";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";

import defaultUserPic from "assets/img/user-pic.webp";
import {
  Action,
  FindAvailableApppointmentsResult,
  HoursByDayHash,
  LoadingEnum,
  ParsedSelectedPreconfirmationTime,
  SelectedPreconfirmationTime,
  Therapist,
} from "@mapsy/shared";
import COLORS from "constants/colors";
import { selectReasonState } from "features/reason/reasonSice";
import { useAppSelector } from "hooks";
import { capitalizeName } from "utils/capitalize";
import { AvailableTimeTable } from "./AvailableTimeTable";
import { TherapistInfo } from "./TherapistInfo";
import { TherapistLocationTab } from "./TherapistLocationTab";
import { Stars } from "components/atoms/Stars";
import { useTheories } from "hooks/catalogs/useTheories";
import endpointGenerator from "classes/endpointGenerator";
import { PreConfirmationModal } from "components/organisms/PreConfirmationModal";
import moment, { Moment } from "moment";
import { theme } from "theme";
import { useAxios } from "hooks/useAxios";
import COMPONENTS from "constants/componentNames";
import { selectSessionState } from "features/session/session.slice";
import { useAnalytics } from "hooks/useAnalytics";

export const LIMIT_MAPPED_DAYS = 5;
export const LIMIT_MAPPED_DAYS_FOR_MOBILES = 3;
export const MIN_HOUR_OF_DAY_TO_LOOK_FOR_APPOINTMENTS = 6;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Props {
  therapist: Therapist;
  isLast?: boolean;
  isSingleView?: boolean;
  fetchNextPage?: () => void;
}
export const TherapistCard: React.FC<Props> = ({
  therapist,
  isLast = false,
  fetchNextPage,
  isSingleView = false,
}) => {
  const {
    _id,
    firstName,
    middleName,
    lastName,
    professionalId,
    stars,
    topicsId,
    locations,
    accountStatus,
  } = therapist;
  const imageUrlByAccountStatus = useMemo(
    () =>
      accountStatus.hasProfilePic
        ? `/api/file/profile_pic_${_id}`
        : `${defaultUserPic}?${new Date().getTime()}`,
    [accountStatus, _id]
  );
  const nav = useNavigate();
  const { createAnalytic } = useAnalytics();
  const { profileInfo } = useAppSelector(selectSessionState);
  const { topicsHash } = useAppSelector(selectReasonState);
  const { theoriesList, theoriesLoading } = useTheories();
  const { getData, isLoading } = useAxios();
  const observerTarget = useRef(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedPreconfirmationTime, setSelectedPreconfirmationTime] =
    useState<SelectedPreconfirmationTime>();
  const borderProperties = useMemo(() => {
    if (isSingleView) {
      return {};
    }
    return {
      border: 1,
      borderColor: COLORS.BLUE_1,
      borderRadius: "10px",
    };
  }, [isSingleView]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const daysToShow = useMemo(
    () => (isMobile ? LIMIT_MAPPED_DAYS_FOR_MOBILES : LIMIT_MAPPED_DAYS),
    [isMobile]
  );

  const preSetHoursByDayAndLocation = useMemo(() => {
    const _hoursByDayAndLocationHash: Record<string, HoursByDayHash> = {};
    locations.forEach(({ _id }) => {
      _hoursByDayAndLocationHash[_id] = {};
    });
    return _hoursByDayAndLocationHash;
  }, [locations]);

  const [hoursByDayAndLocationHash, setHoursByDayAndLocationHash] = useState<
    Record<string, HoursByDayHash>
  >(preSetHoursByDayAndLocation);

  const [datePagination, setDatePagination] = useState<{
    startTime: Moment;
    operation: "add" | "subtract";
  }>({
    startTime: moment().add(24, "hours"),
    operation: "add",
  });

  const arrayOfNextDays: Moment[] = useMemo(
    () =>
      Array.from({ length: daysToShow }).map((_, i) => {
        let nextDay = moment(datePagination.startTime)
          .add(i, "days")
          .set("hour", MIN_HOUR_OF_DAY_TO_LOOK_FOR_APPOINTMENTS)
          .set("minutes", 0)
          .set("second", 0)
          .set("milliseconds", 0);

        const isTomorrow = nextDay.diff(moment(), "hour") < 24;

        if (isTomorrow) {
          nextDay = moment().add(24, "hours");
        }

        return nextDay;
      }),
    [datePagination, daysToShow]
  );

  const handleChangeDays = useCallback(
    (operation: "add" | "subtract") => {
      let startTime = moment(datePagination.startTime)[operation](
        LIMIT_MAPPED_DAYS,
        "days"
      );

      setDatePagination({
        startTime,
        operation,
      });
    },
    [datePagination]
  );

  const fetchAvailableTimesByLocation = useCallback(
    async (locationId: string) => {
      arrayOfNextDays.forEach(async (momentDay, index) => {
        const day = momentDay.valueOf();

        if (hoursByDayAndLocationHash[locationId]?.[day]) {
          return;
        }

        const endpoint = endpointGenerator.AppointmentAPI.availableTimeByDay({
          day,
          therapistId: therapist._id,
          locationId,
          timezone: momentTz.tz.guess(true),
        });
        const { availableTimes }: FindAvailableApppointmentsResult =
          await getData(endpoint);

        setHoursByDayAndLocationHash((_hoursByDay) => ({
          ..._hoursByDay,
          [locationId]: { ..._hoursByDay[locationId], [day]: availableTimes },
        }));
      });
    },
    [arrayOfNextDays, hoursByDayAndLocationHash]
  );

  useEffect(() => {
    if (!locations[currentTab]._id) {
      return;
    }
    fetchAvailableTimesByLocation(locations[currentTab]._id);
  }, [arrayOfNextDays, currentTab, locations]);

  const handleChangeTab = useCallback((_: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    const currentTarget = observerTarget.current;
    if (!currentTarget || !isLast) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage?.();
        }
      },
      { threshold: 1 }
    );

    observer.observe(currentTarget);
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerTarget, isLast, fetchNextPage]);

  const handleClosePreconfirmationModal = useCallback(
    (preconfirmation: ParsedSelectedPreconfirmationTime | undefined) => {
      setIsConfirmationModalOpen(false);
      setSelectedPreconfirmationTime(undefined);

      if (!preconfirmation?.hour || !preconfirmation.service) {
        return;
      }

      const patientId = profileInfo?.id;

      const { hour, service } = preconfirmation;
      const location = locations[currentTab];

      const state = {
        therapist,
        location,
        hour,
        service,
      };

      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.THERAPIST_CARD,
        data: {
          therapistId: therapist._id,
          locationId: location._id,
          hour,
          service,
        },
      });

      if (!patientId) {
        nav("/users/signin?type=patient&redirectTo=/appointment/confirmation", {
          state,
        });
        return;
      }

      nav(`/appointment/confirmation`, {
        state,
      });
    },
    [locations, currentTab, therapist, currentTab, profileInfo?.id]
  );

  useEffect(() => {
    if (!selectedPreconfirmationTime) {
      return;
    }
    setIsConfirmationModalOpen(true);
  }, [selectedPreconfirmationTime]);

  return (
    <>
      {selectedPreconfirmationTime && (
        <PreConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={handleClosePreconfirmationModal}
          location={locations[currentTab]}
          selectedTime={selectedPreconfirmationTime}
          therapist={therapist}
          arrayOfNextDays={arrayOfNextDays}
          hoursByDayHash={hoursByDayAndLocationHash[locations[currentTab]._id]}
        />
      )}
      <Card
        sx={{
          boxShadow: "none",
          userSelect: "none",
          backgroundColor: "transparent",
          ...borderProperties,
        }}
        ref={observerTarget}
        className="therapist-card"
      >
        <Grid container>
          {/* therapist info */}
          <Grid
            item
            sx={{
              borderRight: { sm: 1 },
              borderBottom: { sm: 0, xs: 1 },
              borderColor: COLORS.BLUE_1,
              borderRightColor: COLORS.BLUE_1,
              padding: 3,
            }}
            sm={6}
            xs={12}
          >
            <Grid container sx={{ rowGap: "1.5rem" }}>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  textAlign: "center",
                  "& > img": {
                    xs: { maxHeight: "160px", width: "auto" },
                    md: { maxWidth: "190px", width: "100%" },
                  },
                }}
              >
                <img
                  src={imageUrlByAccountStatus}
                  className="profile-image"
                  alt={`Pic of ${firstName} ${lastName}`}
                />
              </Grid>
              <Grid
                item
                md={9}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  px: { md: 2, xs: 1 },
                  rowGap: { md: 1, xs: 0.5 },
                }}
              >
                <Typography sx={{ fontSize: "1.5rem" }}>
                  {capitalizeName([firstName, middleName, lastName])}
                </Typography>
                {/* TODO: Add theory as a prop for therapists */}
                {theoriesLoading === LoadingEnum.pending ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ fontSize: "1.2rem" }}>
                    {theoriesList.find(
                      ({ value }) => value === therapist.theory?.toString()
                    )?.label || "Teoría no disponible"}
                  </Typography>
                )}
                <Typography sx={{ fontSize: "1.2rem" }}>
                  Cédula: {professionalId}
                </Typography>
                {stars ? (
                  <Box sx={{ width: 120 }}>
                    <Stars stars={stars} />
                  </Box>
                ) : (
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    Sin valoraciones
                  </Typography>
                )}

                <Typography
                  sx={{
                    fontSize: "1rem",
                  }}
                >
                  <Box
                    component={"span"}
                    sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
                  >
                    Áreas de atención:{" "}
                  </Box>
                  <span>
                    {topicsId
                      .map(
                        (id, i) =>
                          topicsHash[id]?.longNames.es_name ||
                          topicsHash[id]?.name ||
                          "No name"
                      )
                      .join(", ")}
                  </span>
                </Typography>
                {!isSingleView && (
                  <Typography>
                    <Link
                      preventScrollReset={true}
                      to={`/therapists/${_id}`}
                      style={{
                        fontWeight: 600,
                        color: COLORS.BLUE_1,
                        fontSize: "1rem",
                      }}
                    >
                      Ver más
                    </Link>
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Box
              sx={{
                my: 3,
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  py: 0,
                }}
              >
                <Tabs
                  value={currentTab}
                  onChange={handleChangeTab}
                  aria-label="locations tabs"
                >
                  {locations.map((location, i) => (
                    <Tab
                      key={`location-tab-${i}-${location._id}`}
                      label={`${location.community}, ${location.city}`}
                      {...a11yProps(i)}
                      sx={{ fontSize: "0.9rem", textTransform: "inherit" }}
                    />
                  ))}
                </Tabs>
              </Box>
              {locations.map((location, i) => (
                <TherapistLocationTab
                  key={`therapist-location-tab-${i}-${location._id}`}
                  value={currentTab}
                  location={location}
                  index={i}
                  {...a11yProps(i)}
                />
              ))}
            </Box>
          </Grid>
          {/* calendar */}
          <Grid
            item
            sx={{
              padding: "1rem",
            }}
            sm={6}
            xs={12}
          >
            <Box
              sx={{
                maxHeight: isSingleView ? 650 : 450,
                overflowY: "auto",
              }}
              className="transparent-scroll"
            >
              <AvailableTimeTable
                location={locations[currentTab]}
                therapist={therapist}
                hoursByDayHash={
                  hoursByDayAndLocationHash[locations[currentTab]._id]
                }
                arrayOfNextDays={arrayOfNextDays}
                setSelectedPreconfirmationTime={setSelectedPreconfirmationTime}
                onChangeDays={handleChangeDays}
                isLoading={isLoading}
              />
            </Box>
          </Grid>
        </Grid>
        {isSingleView && <TherapistInfo therapist={therapist} />}
      </Card>
    </>
  );
};
