import { Action } from "@mapsy/shared";
import COMPONENTS from "constants/componentNames";
import {
  selectSessionState,
  setSourceInfo,
} from "features/session/session.slice";
import { useAppDispatch } from "hooks";
import { useAnalytics } from "hooks/useAnalytics";
import { useAxios } from "hooks/useAxios";
import { IPResponse, IPLocationResponse } from "interfaces/analytics.interface";
import moment from "moment";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isMobileDevice } from "utils/isMobile";

interface VisitedPath {
  pathname: string;
  visitTime?: number;
  duration?: number;
}

interface Props {
  children: ReactNode;
}

export const AnalyticsVisitProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { getData, isLoading } = useAxios();
  const { createAnalytic } = useAnalytics();
  const { sourceInfo } = useSelector(selectSessionState);
  const [visitedPath, setVisitedPath] = useState<VisitedPath>({
    pathname,
    visitTime: moment().valueOf(),
  });

  const fetchSourceInfo = useCallback(async () => {
    const ipResponse: IPResponse = await getData(
      "https://api.ipify.org?format=json"
    );
    const locationResponse: IPLocationResponse = await getData(
      `https://ipapi.co/${ipResponse.ip}/json/`
    );
    const { country, country_code, city, timezone, asn, org, region } =
      locationResponse;

    dispatch(
      setSourceInfo({
        ...ipResponse,
        country,
        country_code,
        city,
        timezone,
        asn,
        org,
        region,
        isMobile: isMobileDevice(),
      })
    );
  }, []);

  useEffect(() => {
    if (sourceInfo?.ip || isLoading) {
      return;
    }
    fetchSourceInfo();
  }, [sourceInfo]);

  useEffect(() => {
    if (pathname !== visitedPath.pathname) {
      const now = moment().valueOf();
      const duration = now - (visitedPath.visitTime || 0);
      setVisitedPath({
        pathname,
        visitTime: now,
        duration,
      });
    }

    createAnalytic({
      action: Action.VISIT,
      componentName: COMPONENTS.ANALYTICS_VISIT_PROVIDER,
      data: visitedPath,
    });
  }, [pathname]);

  return <>{children}</>;
};
