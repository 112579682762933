import { configureStore } from "@reduxjs/toolkit";
import reasonReducer from "features/reason/reasonSice";
import catalogsReducer from "features/catalogs/catalogs.slice";
import sessionReducer from "features/session/session.slice";
import therapistReducer from "features/therapist/therapist.slice";

const store = configureStore({
  reducer: {
    reasonSlice: reasonReducer,
    catalogsSlice: catalogsReducer,
    sessionSlice: sessionReducer,
    therapistSlice: therapistReducer
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
