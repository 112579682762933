// List of common timezones and their Spanish translations
const timezoneTranslations = {
  "Africa/Abidjan": "África/Abiyán",
  "Africa/Accra": "África/Acra",
  "Africa/Addis_Ababa": "África/Addis Abeba",
  "Africa/Algiers": "África/Argel",
  "Africa/Asmara": "África/Asmara",
  "Africa/Bamako": "África/Bamako",
  "Africa/Bangui": "África/Bangui",
  "Africa/Banjul": "África/Banjul",
  "Africa/Bissau": "África/Bisáu",
  "Africa/Blantyre": "África/Blantyre",
  "Africa/Brazzaville": "África/Brazzaville",
  "Africa/Bujumbura": "África/Bujumbura",
  "Africa/Cairo": "África/El Cairo",
  "Africa/Casablanca": "África/Casablanca",
  "Africa/Ceuta": "África/Ceuta",
  "Africa/Conakry": "África/Conakry",
  "Africa/Dakar": "África/Dakar",
  "Africa/Dar_es_Salaam": "África/Dar es Salaam",
  "Africa/Djibouti": "África/Yibuti",
  "Africa/Douala": "África/Duala",
  "Africa/El_Aaiun": "África/El Aaiún",
  "Africa/Freetown": "África/Freetown",
  "Africa/Gaborone": "África/Gaborone",
  "Africa/Harare": "África/Harare",
  "Africa/Johannesburg": "África/Johannesburgo",
  "Africa/Juba": "África/Yuba",
  "Africa/Kampala": "África/Kampala",
  "Africa/Khartoum": "África/Kartum",
  "Africa/Kigali": "África/Kigali",
  "Africa/Kinshasa": "África/Kinshasa",
  "Africa/Lagos": "África/Lagos",
  "Africa/Libreville": "África/Libreville",
  "Africa/Lome": "África/Lomé",
  "Africa/Luanda": "África/Luanda",
  "Africa/Lubumbashi": "África/Lubumbashi",
  "Africa/Lusaka": "África/Lusaka",
  "Africa/Malabo": "África/Malabo",
  "Africa/Maputo": "África/Maputo",
  "Africa/Maseru": "África/Maseru",
  "Africa/Mbabane": "África/Mbabane",
  "Africa/Mogadishu": "África/Mogadiscio",
  "Africa/Monrovia": "África/Monrovia",
  "Africa/Nairobi": "África/Nairobi",
  "Africa/Ndjamena": "África/Yamena",
  "Africa/Niamey": "África/Niamey",
  "Africa/Nouakchott": "África/Nuakchot",
  "Africa/Ouagadougou": "África/Uagadugú",
  "Africa/Porto-Novo": "África/Porto-Novo",
  "Africa/Sao_Tome": "África/Santo Tomé",
  "Africa/Tripoli": "África/Trípoli",
  "Africa/Tunis": "África/Túnez",
  "Africa/Windhoek": "África/Windhoek",
  "America/Adak": "América/Adak",
  "America/Anchorage": "América/Anchorage",
  "America/Anguilla": "América/Anguila",
  "America/Antigua": "América/Antigua",
  "America/Araguaina": "América/Araguaina",
  "America/Argentina/Buenos_Aires": "América/Argentina/Buenos Aires",
  "America/Argentina/Catamarca": "América/Argentina/Catamarca",
  "America/Argentina/Cordoba": "América/Argentina/Córdoba",
  "America/Argentina/Jujuy": "América/Argentina/Jujuy",
  "America/Argentina/La_Rioja": "América/Argentina/La Rioja",
  "America/Argentina/Mendoza": "América/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos": "América/Argentina/Río Gallegos",
  "America/Argentina/Salta": "América/Argentina/Salta",
  "America/Argentina/San_Juan": "América/Argentina/San Juan",
  "America/Argentina/San_Luis": "América/Argentina/San Luis",
  "America/Argentina/Tucuman": "América/Argentina/Tucumán",
  "America/Argentina/Ushuaia": "América/Argentina/Ushuaia",
  "America/Aruba": "América/Aruba",
  "America/Asuncion": "América/Asunción",
  "America/Atikokan": "América/Atikokan",
  "America/Bahia": "América/Bahia",
  "America/Bahia_Banderas": "América/Bahía de Banderas",
  "America/Barbados": "América/Barbados",
  "America/Belem": "América/Belém",
  "America/Belize": "América/Belice",
  "America/Blanc-Sablon": "América/Blanc-Sablon",
  "America/Boa_Vista": "América/Boa Vista",
  "America/Bogota": "América/Bogotá",
  "America/Boise": "América/Boise",
  "America/Cambridge_Bay": "América/Cambridge Bay",
  "America/Campo_Grande": "América/Campo Grande",
  "America/Cancun": "América/Cancún",
  "America/Caracas": "América/Caracas",
  "America/Cayenne": "América/Cayena",
  "America/Cayman": "América/Caimán",
  "America/Chicago": "América/Chicago",
  "America/Chihuahua": "América/Chihuahua",
  "America/Costa_Rica": "América/Costa Rica",
  "America/Creston": "América/Creston",
  "America/Cuiaba": "América/Cuiabá",
  "America/Curacao": "América/Curaçao",
  "America/Danmarkshavn": "América/Danmarkshavn",
  "America/Dawson": "América/Dawson",
  "America/Dawson_Creek": "América/Dawson Creek",
  "America/Denver": "América/Denver",
  "America/Detroit": "América/Detroit",
  "America/Dominica": "América/Dominica",
  "America/Edmonton": "América/Edmonton",
  "America/Eirunepe": "América/Eirunepé",
  "America/El_Salvador": "América/El Salvador",
  "America/Fort_Nelson": "América/Fort Nelson",
  "America/Fortaleza": "América/Fortaleza",
  "America/Glace_Bay": "América/Glace Bay",
  "America/Godthab": "América/Godthab",
  "America/Goose_Bay": "América/Goose Bay",
  "America/Grand_Turk": "América/Gran Turca",
  "America/Grenada": "América/Grenada",
  "America/Guadeloupe": "América/Guadalupe",
  "America/Guatemala": "América/Guatemala",
  "America/Guayaquil": "América/Guayaquil",
  "America/Guyana": "América/Guyana",
  "America/Halifax": "América/Halifax",
  "America/Havana": "América/La Habana",
  "America/Hermosillo": "América/Hermosillo",
  "America/Indiana/Indianapolis": "América/Indiana/Indianápolis",
  "America/Indiana/Knox": "América/Indiana/Knox",
  "America/Indiana/Marengo": "América/Indiana/Marengo",
  "America/Indiana/Petersburg": "América/Indiana/Petersburg",
  "America/Indiana/Tell_City": "América/Indiana/Tell City",
  "America/Indiana/Vevay": "América/Indiana/Vevay",
  "America/Indiana/Vincennes": "América/Indiana/Vincennes",
  "America/Indiana/Winamac": "América/Indiana/Winamac",
  "America/Inuvik": "América/Inuvik",
  "America/Iqaluit": "América/Iqaluit",
  "America/Jamaica": "América/Jamaica",
  "America/Juneau": "América/Juneau",
  "America/Kentucky/Louisville": "América/Kentucky/Louisville",
  "America/Kentucky/Monticello": "América/Kentucky/Monticello",
  "America/Kralendijk": "América/Kralendijk",
  "America/La_Paz": "América/La Paz",
  "America/Lima": "América/Lima",
  "America/Los_Angeles": "América/Los Ángeles",
  "America/Lower_Princes": "América/Lower Princes",
  "America/Maceio": "América/Maceió",
  "America/Managua": "América/Managua",
  "America/Manaus": "América/Manaos",
  "America/Marigot": "América/Marigot",
  "America/Martinique": "América/Martinica",
  "America/Matamoros": "América/Matamoros",
  "America/Mazatlan": "América/Mazatlán",
  "America/Menominee": "América/Menominee",
  "America/Merida": "América/Mérida",
  "America/Metlakatla": "América/Metlakatla",
  "America/Mexico_City": "América/Ciudad de México",
  "America/Miquelon": "América/Miquelon",
  "America/Moncton": "América/Moncton",
  "America/Monterrey": "América/Monterrey",
  "America/Montevideo": "América/Montevideo",
  "America/Montserrat": "América/Montserrat",
  "America/Nassau": "América/Nassau",
  "America/New_York": "América/Nueva York",
  "America/Nipigon": "América/Nipigon",
  "America/Nome": "América/Nome",
  "America/Noronha": "América/Noronha",
  "America/North_Dakota/Beulah": "América/North Dakota/Beulah",
  "America/North_Dakota/Center": "América/North Dakota/Center",
  "America/North_Dakota/New_Salem": "América/North Dakota/New Salem",
  "America/Nuuk": "América/Nuuk",
  "America/Ojinaga": "América/Ojinaga",
  "America/Panama": "América/Panamá",
  "America/Pangnirtung": "América/Pangnirtung",
  "America/Paramaribo": "América/Paramaribo",
  "America/Phoenix": "América/Fénix",
  "America/Port-au-Prince": "América/Puerto Príncipe",
  "America/Port_of_Spain": "América/Port of Spain",
  "America/Porto_Velho": "América/Porto Velho",
  "America/Puerto_Rico": "América/Puerto Rico",
  "America/Punta_Arenas": "América/Punta Arenas",
  "America/Rainy_River": "América/Rainy River",
  "America/Rankin_Inlet": "América/Rankin Inlet",
  "America/Recife": "América/Recife",
  "America/Regina": "América/Regina",
  "America/Resolute": "América/Resolute",
  "America/Rio_Branco": "América/Rio Branco",
  "America/Santarem": "América/Santarém",
  "America/Santiago": "América/Santiago",
  "America/Santo_Domingo": "América/Santo Domingo",
  "America/Sao_Paulo": "América/São Paulo",
  "America/Scoresbysund": "América/Scoresbysund",
  "America/Sitka": "América/Sitka",
  "America/St_Barthelemy": "América/San Bartolomé",
  "America/St_Johns": "América/San Juan",
  "America/St_Kitts": "América/San Cristóbal",
  "America/St_Lucia": "América/Santa Lucía",
  "America/St_Thomas": "América/Santo Tomás",
  "America/St_Vincent": "América/San Vicente",
  "America/Swift_Current": "América/Swift Current",
  "America/Tegucigalpa": "América/Tegucigalpa",
  "America/Thule": "América/Thule",
  "America/Thunder_Bay": "América/Thunder Bay",
  "America/Tijuana": "América/Tijuana",
  "America/Toronto": "América/Toronto",
  "America/Tortola": "América/Tórtola",
  "America/Vancouver": "América/Vancouver",
  "America/Whitehorse": "América/Whitehorse",
  "America/Winnipeg": "América/Winnipeg",
  "America/Yakutat": "América/Yakutat",
  "America/Yellowknife": "América/Yellowknife",
  "Antarctica/Casey": "Antártida/Casey",
  "Antarctica/Davis": "Antártida/Davis",
  "Antarctica/DumontDUrville": "Antártida/DumontDUrville",
  "Antarctica/Macquarie": "Antártida/Macquarie",
  "Antarctica/Mawson": "Antártida/Mawson",
  "Antarctica/McMurdo": "Antártida/McMurdo",
  "Antarctica/Palmer": "Antártida/Palmer",
  "Antarctica/Rothera": "Antártida/Rothera",
  "Antarctica/Syowa": "Antártida/Syowa",
  "Antarctica/Troll": "Antártida/Troll",
  "Antarctica/Vostok": "Antártida/Vostok",
  "Arctic/Longyearbyen": "Ártico/Longyearbyen",
  "Asia/Aden": "Asia/Aden",
  "Asia/Almaty": "Asia/Almaty",
  "Asia/Amman": "Asia/Amman",
  "Asia/Anadyr": "Asia/Anadyr",
  "Asia/Aqtau": "Asia/Aqtau",
  "Asia/Aqtobe": "Asia/Aqtobe",
  "Asia/Ashgabat": "Asia/Ashgabat",
  "Asia/Atyrau": "Asia/Atyrau",
  "Asia/Baghdad": "Asia/Bagdad",
  "Asia/Bahrain": "Asia/Bahrain",
  "Asia/Baku": "Asia/Baku",
  "Asia/Bangkok": "Asia/Bangkok",
  "Asia/Barnaul": "Asia/Barnaul",
  "Asia/Beirut": "Asia/Beirut",
  "Asia/Bishkek": "Asia/Bishkek",
  "Asia/Brunei": "Asia/Brunei",
  "Asia/Chita": "Asia/Chita",
  "Asia/Choibalsan": "Asia/Choibalsan",
  "Asia/Colombo": "Asia/Colombo",
  "Asia/Damascus": "Asia/Damascus",
  "Asia/Dhaka": "Asia/Dhaka",
  "Asia/Dili": "Asia/Dili",
  "Asia/Dubai": "Asia/Dubai",
  "Asia/Dushanbe": "Asia/Dushanbe",
  "Asia/Famagusta": "Asia/Famagusta",
  "Asia/Gaza": "Asia/Gaza",
  "Asia/Hebron": "Asia/Hebron",
  "Asia/Ho_Chi_Minh": "Asia/Ho Chi Minh",
  "Asia/Hong_Kong": "Asia/Hong Kong",
  "Asia/Hovd": "Asia/Hovd",
  "Asia/Irkutsk": "Asia/Irkutsk",
  "Asia/Jakarta": "Asia/Jakarta",
  "Asia/Jayapura": "Asia/Jayapura",
  "Asia/Jerusalem": "Asia/Jerusalem",
  "Asia/Kabul": "Asia/Kabul",
  "Asia/Kamchatka": "Asia/Kamchatka",
  "Asia/Karachi": "Asia/Karachi",
  "Asia/Kathmandu": "Asia/Kathmandu",
  "Asia/Khandyga": "Asia/Khandyga",
  "Asia/Kolkata": "Asia/Kolkata",
  "Asia/Krasnoyarsk": "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur": "Asia/Kuala Lumpur",
  "Asia/Kuching": "Asia/Kuching",
  "Asia/Kuwait": "Asia/Kuwait",
  "Asia/Macau": "Asia/Macau",
  "Asia/Magadan": "Asia/Magadan",
  "Asia/Makassar": "Asia/Makassar",
  "Asia/Manila": "Asia/Manila",
  "Asia/Muscat": "Asia/Muscat",
  "Asia/Nicosia": "Asia/Nicosia",
  "Asia/Novokuznetsk": "Asia/Novokuznetsk",
  "Asia/Novosibirsk": "Asia/Novosibirsk",
  "Asia/Omsk": "Asia/Omsk",
  "Asia/Oral": "Asia/Oral",
  "Asia/Phnom_Penh": "Asia/Phnom Penh",
  "Asia/Pontianak": "Asia/Pontianak",
  "Asia/Pyongyang": "Asia/Pyongyang",
  "Asia/Qatar": "Asia/Qatar",
  "Asia/Qostanay": "Asia/Qostanay",
  "Asia/Qyzylorda": "Asia/Qyzylorda",
  "Asia/Riyadh": "Asia/Riyadh",
  "Asia/Sakhalin": "Asia/Sakhalin",
  "Asia/Samarkand": "Asia/Samarkand",
  "Asia/Seoul": "Asia/Seoul",
  "Asia/Shanghai": "Asia/Shanghai",
  "Asia/Singapore": "Asia/Singapore",
  "Asia/Srednekolymsk": "Asia/Srednekolymsk",
  "Asia/Taipei": "Asia/Taipei",
  "Asia/Tashkent": "Asia/Tashkent",
  "Asia/Tbilisi": "Asia/Tbilisi",
  "Asia/Tehran": "Asia/Tehran",
  "Asia/Thimphu": "Asia/Thimphu",
  "Asia/Tokyo": "Asia/Tokyo",
  "Asia/Tomsk": "Asia/Tomsk",
  "Asia/Ulaanbaatar": "Asia/Ulaanbaatar",
  "Asia/Urumqi": "Asia/Urumqi",
  "Asia/Ust-Nera": "Asia/Ust-Nera",
  "Asia/Vientiane": "Asia/Vientiane",
  "Asia/Vladivostok": "Asia/Vladivostok",
  "Asia/Yakutsk": "Asia/Yakutsk",
  "Asia/Yangon": "Asia/Yangon",
  "Asia/Yekaterinburg": "Asia/Yekaterinburg",
  "Asia/Yerevan": "Asia/Yerevan",
  "Atlantic/Azores": "Atlántico/Azores",
  "Atlantic/Bermuda": "Atlántico/Bermudas",
  "Atlantic/Canary": "Atlántico/Canarias",
  "Atlantic/Cape_Verde": "Atlántico/Cabo Verde",
  "Atlantic/Faroe": "Atlántico/Faroe",
  "Atlantic/Madeira": "Atlántico/Madeira",
  "Atlantic/Reykjavik": "Atlántico/Reykjavik",
  "Atlantic/South_Georgia": "Atlántico/Georgia del Sur",
  "Atlantic/Stanley": "Atlántico/Stanley",
  "Australia/Adelaide": "Australia/Adelaida",
  "Australia/Brisbane": "Australia/Brisbane",
  "Australia/Broken_Hill": "Australia/Broken Hill",
  "Australia/Currie": "Australia/Currie",
  "Australia/Darwin": "Australia/Darwin",
  "Australia/Eucla": "Australia/Eucla",
  "Australia/Hobart": "Australia/Hobart",
  "Australia/Lindeman": "Australia/Lindeman",
  "Australia/Lord_Howe": "Australia/Lord Howe",
  "Australia/Melbourne": "Australia/Melbourne",
  "Australia/Perth": "Australia/Perth",
  "Australia/Sydney": "Australia/Sydney",
  "Europe/Amsterdam": "Europa/Ámsterdam",
  "Europe/Andorra": "Europa/Andorra",
  "Europe/Astrakhan": "Europa/Astracán",
  "Europe/Athens": "Europa/Atenas",
  "Europe/Belgrade": "Europa/Belgrado",
  "Europe/Berlin": "Europa/Berlín",
  "Europe/Bratislava": "Europa/Bratislava",
  "Europe/Brussels": "Europa/Bruselas",
  "Europe/Bucharest": "Europa/Bucarest",
  "Europe/Budapest": "Europa/Budapest",
  "Europe/Busingen": "Europa/Busingen",
  "Europe/Chisinau": "Europa/Chisinau",
  "Europe/Copenhagen": "Europa/Copenhague",
  "Europe/Dublin": "Europa/Dublín",
  "Europe/Gibraltar": "Europa/Gibraltar",
  "Europe/Guernsey": "Europa/Guernsey",
  "Europe/Helsinki": "Europa/Helsinki",
  "Europe/Isle_of_Man": "Europa/Isla de Man",
  "Europe/Istanbul": "Europa/Estambul",
  "Europe/Jersey": "Europa/Jersey",
  "Europe/Kaliningrad": "Europa/Kaliningrado",
  "Europe/Kiev": "Europa/Kiev",
  "Europe/Kirov": "Europa/Kirov",
  "Europe/Lisbon": "Europa/Lisboa",
  "Europe/Ljubljana": "Europa/Liubliana",
  "Europe/London": "Europa/Londres",
  "Europe/Luxembourg": "Europa/Luxemburgo",
  "Europe/Madrid": "Europa/Madrid",
  "Europe/Malta": "Europa/Malta",
  "Europe/Mariehamn": "Europa/Mariehamn",
  "Europe/Minsk": "Europa/Minsk",
  "Europe/Monaco": "Europa/Mónaco",
  "Europe/Moscow": "Europa/Moscú",
  "Europe/Oslo": "Europa/Oslo",
  "Europe/Paris": "Europa/París",
  "Europe/Podgorica": "Europa/Podgorica",
  "Europe/Prague": "Europa/Praga",
  "Europe/Riga": "Europa/Riga",
  "Europe/Rome": "Europa/Roma",
  "Europe/Samara": "Europa/Samara",
  "Europe/San_Marino": "Europa/San Marino",
  "Europe/Sarajevo": "Europa/Sarajevo",
  "Europe/Saratov": "Europa/Saratov",
  "Europe/Simferopol": "Europa/Simferopol",
  "Europe/Skopje": "Europa/Skopje",
  "Europe/Sofia": "Europa/Sofía",
  "Europe/Stockholm": "Europa/Estocolmo",
  "Europe/Tallinn": "Europa/Tallin",
  "Europe/Tirane": "Europa/Tirana",
  "Europe/Ulyanovsk": "Europa/Ulyanovsk",
  "Europe/Uzhgorod": "Europa/Uzhgorod",
  "Europe/Vaduz": "Europa/Vaduz",
  "Europe/Vatican": "Europa/Vaticano",
  "Europe/Vienna": "Europa/Viena",
  "Europe/Vilnius": "Europa/Vilna",
  "Europe/Volgograd": "Europa/Volgogrado",
  "Europe/Warsaw": "Europa/Varsovia",
  "Europe/Zagreb": "Europa/Zagreb",
  "Europe/Zaporozhye": "Europa/Zaporozhye",
  "Europe/Zurich": "Europa/Zurich",
  "Indian/Antananarivo": "Océano Índico/Antananarivo",
  "Indian/Chagos": "Océano Índico/Chagos",
  "Indian/Christmas": "Océano Índico/Navidad",
  "Indian/Cocos": "Océano Índico/Cocos",
  "Indian/Comoro": "Océano Índico/Comoros",
  "Indian/Kerguelen": "Océano Índico/Kerguelen",
  "Indian/Mahe": "Océano Índico/Mahe",
  "Indian/Maldives": "Océano Índico/Maldivas",
  "Indian/Mauritius": "Océano Índico/Mauricio",
  "Indian/Mayotte": "Océano Índico/Mayotte",
  "Indian/Reunion": "Océano Índico/Reunión",
  "Pacific/Apia": "Océano Pacífico/Apia",
  "Pacific/Auckland": "Océano Pacífico/Auckland",
  "Pacific/Bougainville": "Océano Pacífico/Bougainville",
  "Pacific/Chatham": "Océano Pacífico/Chatham",
  "Pacific/Chuuk": "Océano Pacífico/Chuuk",
  "Pacific/Easter": "Océano Pacífico/Pascua",
  "Pacific/Efate": "Océano Pacífico/Efate",
  "Pacific/Enderbury": "Océano Pacífico/Enderbury",
  "Pacific/Fakaofo": "Océano Pacífico/Fakaofo",
  "Pacific/Fiji": "Océano Pacífico/Fiji",
  "Pacific/Funafuti": "Océano Pacífico/Funafuti",
  "Pacific/Galapagos": "Océano Pacífico/Galápagos",
  "Pacific/Gambier": "Océano Pacífico/Gambier",
  "Pacific/Guadalcanal": "Océano Pacífico/Guadalcanal",
  "Pacific/Guam": "Océano Pacífico/Guam",
  "Pacific/Honolulu": "Océano Pacífico/Honolulu",
  "Pacific/Kiritimati": "Océano Pacífico/Kiritimati",
  "Pacific/Kosrae": "Océano Pacífico/Kosrae",
  "Pacific/Kwajalein": "Océano Pacífico/Kwajalein",
  "Pacific/Majuro": "Océano Pacífico/Majuro",
  "Pacific/Marquesas": "Océano Pacífico/Marquesas",
  "Pacific/Midway": "Océano Pacífico/Midway",
  "Pacific/Nauru": "Océano Pacífico/Nauru",
  "Pacific/Niue": "Océano Pacífico/Niue",
  "Pacific/Norfolk": "Océano Pacífico/Norfolk",
  "Pacific/Noumea": "Océano Pacífico/Noumea",
  "Pacific/Pago_Pago": "Océano Pacífico/Pago Pago",
  "Pacific/Palau": "Océano Pacífico/Palau",
  "Pacific/Pitcairn": "Océano Pacífico/Pitcairn",
  "Pacific/Pohnpei": "Océano Pacífico/Pohnpei",
  "Pacific/Port_Moresby": "Océano Pacífico/Port Moresby",
  "Pacific/Rarotonga": "Océano Pacífico/Rarotonga",
  "Pacific/Saipan": "Océano Pacífico/Saipan",
  "Pacific/Tahiti": "Océano Pacífico/Tahiti",
  "Pacific/Tarawa": "Océano Pacífico/Tarawa",
  "Pacific/Tongatapu": "Océano Pacífico/Tongatapu",
  "Pacific/Wake": "Océano Pacífico/Wake",
  "Pacific/Wallis": "Océano Pacífico/Wallis",
};

export default timezoneTranslations;
